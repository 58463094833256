@mixin tachyons_border_radius($suffix:'') {
  @each $size, $value in $border-radius {
    .br#{$size}#{$suffix} {
      border-radius: #{$value};
    }
  }

  .br-ui#{$suffix} {
    border-radius: $border-radius-ui;
  }
  .br-ui-charcoal#{$suffix} {
    border-radius: $border-radius-ui-charcoal;
  }

  .br10#{$suffix} { border-radius: 10px; }
  .br-100#{$suffix} { border-radius: 100%; }
  .br-pill#{$suffix} {    border-radius: 9999px; }

  .br--bottom#{$suffix} {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
  }
  .br--top#{$suffix} {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
  }
  .br--right#{$suffix} {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
  }
  .br--left#{$suffix} {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
  }
}

// Create standard classes
// ------------------------------------------

@include tachyons_border_radius();


// Create MQ extensions
// ------------------------------------------

@each $bp, $mq in $bp-widths-min {
  @media (min-width: #{$mq}) {
    @include tachyons_border_radius($suffix: -#{$bp});
  }
}

