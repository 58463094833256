/*

  layout/l-container.scss

  Our container system.

  Media Query Extensions: Yes

*/


// Container setup
// ------------------------------------------

@mixin l_container($suffix:'') {

  // The new standard for the site container
  // TODO: Migrate previous numbers containers to tach MW
  .l-container#{$suffix} {
    max-width: $container-width;
    margin-left: auto;
    margin-right: auto;
  }

  .l-container-1#{$suffix} {
    max-width: map-get($layout-scale-rem, 10);
    margin-left: auto;
    margin-right: auto;
  }

  .l-container-2#{$suffix} {
    max-width: map-get($layout-scale-rem, 11);
    margin-left: auto;
    margin-right: auto;
  }

  .l-container-3#{$suffix} {
    max-width: map-get($layout-scale-rem, 12);
    margin-left: auto;
    margin-right: auto;
  }

  .l-container-4#{$suffix} {
    max-width: map-get($layout-scale-rem, 13);
    margin-left: auto;
    margin-right: auto;
  }

  .l-container-5#{$suffix} {
    max-width: map-get($layout-scale-rem, 14);
    margin-left: auto;
    margin-right: auto;
  }

  .l-container-6#{$suffix} {
    max-width: 2100px;
    margin-left: auto;
    margin-right: auto;
  }
}


// Create standard classes
// ------------------------------------------

@include l_container();


// Create MQ extensions
// ------------------------------------------

@each $bp, $mq in $bp-widths-min {
  @media (min-width: #{$mq}) {
    @include l_container(-#{$bp});
  }
}

