@mixin tachyons_shadows($suffix:'') {
  .shadow-0#{$suffix} {
    box-shadow: none;
  }

  .shadow-floating-ui-light#{$suffix} {
    box-shadow: $shadow-floating-ui-light;
  }

  .shadow-floating-ui-dark#{$suffix} {
    box-shadow: $shadow-floating-ui-dark;
  }

  .shadow-fr#{$suffix} {
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 0px, rgba(0, 0, 0, 0.05) 0px 2px 6px, rgba(0, 0, 0, 0.05) 0px 10px 20px;
  }

  .shadow-fr-hover#{$suffix} {
    transition: box-shadow 0.2s linear;
  }

  .shadow-fr-hover#{$suffix}:hover {
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 0px, rgba(0, 0, 0, 0.05) 0px 2px 6px, rgba(0, 0, 0, 0.05) 0px 10px 20px;
  }

  .shadow-atlassian#{$suffix} {
    box-shadow: 0px 10px 14px 0px rgba(23,43,77,0.2);
  }

  .shadow-atlassian-extreme#{$suffix} {
    box-shadow: 0 15px 23px 0 rgb(23 43 77 / 35%);
  }

  .shadow-flat#{$suffix} {
    box-shadow: 20px 20px 0 0 rgba(0, 0, 0, 0.05);
  }

  @each $pos, $depth in $shadow-depth {
    .shadow-#{$pos}#{$suffix} {
      box-shadow: #{$depth};
    }
  }
}


// Create standard classes
// ------------------------------------------

@include tachyons_shadows();



// Create MQ extensions
// ------------------------------------------

@each $bp, $mq in $bp-widths-min {
    @media (min-width: #{$mq}) {
      @include tachyons_shadows(-#{$bp});
    }
}