@import "./c-copy--mixins.scss"; // When nesting a mixin within a mixin, need to import in the same file Pete/Paolo Jan 2020
@import "./c-lead--mixins.scss";

@mixin c_list() {
  @include c_copy;

  margin-left: 1rem;

  &:not(:last-child) {
    margin-bottom: 1.5em;
  }

  li {
    position: relative;

    &:not(:last-child) {
      margin-bottom: 0.5em;
    }

    &::before {
      position: absolute;

      right: calc(100% - 1em);

      color: map-get($color-ui, "primary");
    }

    > ul,
    ol {
      margin-top: 1em;
      margin-bottom: 1em;
    }
  }
}

// Style variants
// ------------------------------------------

@mixin c_list__unordered() {
  > li {
    padding-left: 1.3em;

    &::before {
      content: "";

      border-radius: 50%;

      width: 6px;
      height: 6px;

      background-color: currentColor;

      top: 0.65em;
    }

    > ul {
      > li::before {
        background-color: transparent;
        border: map-get($border-width, 2) solid currentColor;
      }
    }
  }
}

@mixin c_list__nav_unordered() {

  &:not(:last-child) {
    margin-bottom: 1.5em;
  }

  li {
    position: relative;
    padding-left: 0.4em;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      border-radius: 50%;
      width: 6px;
      height: 6px;
      background-color: currentColor;
      color: #8AC539;
      top: 0.65em;
    }

    a {
      color: map-get($color-grey, 5);
      border-bottom: 0;
      border-color: #c6c7cc;
    }
  }
}

@mixin c_list__ordered() {
  counter-reset: counterInc;

  > li {
    padding-left: 1.4em;

    &::before {
      content: counter(counterInc) ".";
      counter-increment: counterInc;

      top: 0;

      font-weight: map-get($font-weight, 600);
    }
  }
}

@mixin c_list__legal_ordered() {
  > li {
    &::before {
      content: counters(counterInc, ".") ".";

      position: relative;

      margin-right: 0.2em;
    }

    > ol {
      > li:not(:last-child) {
        margin-bottom: 1.1em;
      }

      margin-bottom: 2em;
    }
  }
}

// Dark contrast mode
// ------------------------------------------

@mixin c_list__dark() {
  @include c_copy__dark();
}

// Light contrast mode
// ------------------------------------------

@mixin c_list__light() {
  @include c_copy__light();
}
