
// Disable (for testing etc)
// ------------------------------------------

.t-disable-children * {
  transition: none !important;
}

.t-finish-children * {
  transition-duration: 0s !important;
  transition-delay: 0s !important;
}

.a-disable-children * {
  animation: none !important;
}

