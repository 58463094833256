.c-video-embed-container {
  position: relative;
  padding-bottom: 56.25%;
  width: 100%;
  max-width: 100%;
}

.c-video-embed-container iframe,
.c-video-embed-container object,
.c-video-embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
  overflow: hidden;
  z-index: 1;
}