/*

  mixins/styletext-highlighting.scss

  Convenience mixin for styling text highlight effects

*/


@mixin style_text_highlighting() {

  &::selection {
    @content;
  }

  &::-moz-selection {
    @content;
  }

}