/*

  components/form/c-input.scss

  A basic form input

*/

.c-input {
  @include base_form_styles();
  @include touch_optimize();

  // font-size: inherit;

  // Remove "clear input" cross in IE11
  &::-ms-clear {
    display: none;
    width: 0;
    height: 0;
  }

  &[disabled] {
    @include disabled_input_style();
  }

  &:focus {
    @include field_focus_style;
  }
}

.c-input.c-next {
  padding: 20px;
}


.c-input-button-wrap {
  display: flex;
  width: 100%;
  gap: 1rem;

  @media (min-width:56.25em) {
    gap: 1rem;
  }
  
  // @media (min-width:37.5em) {
  //   gap: 3rem;
  // }

  input {
    flex: 1;
    width: auto;
  }
  .c-btn {
    display: flex;
    padding: 0.9em 1em;

    svg {
      margin-left: 6px;
    }
  }

}

.s-validation-error .c-input-button-wrap .c-validation-msg {
  margin-top: -0.5em;
}


// Disable border radius & right border
// ------------------------------------------

@mixin c-input-border-radius-disable($suffix:'') {
  // Disable right radius - for combined input and button
  .c-input--no-right-radius#{$suffix} {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
}

@mixin c-input-border-right-disable($suffix:'') {
  // Disable right border - for combined input and button
  .c-input--border-right-none#{$suffix} {
    border-right: none !important;
  }
}

// Create standard classes
// ------------------------------------------
@include c-input-border-radius-disable();
@include c-input-border-right-disable();

// Create MQ extensions
// ------------------------------------------
@each $bp, $mq in $bp-widths-min {
    @media (min-width: #{$mq}) {
      @include c-input-border-radius-disable(-#{$bp});
      @include c-input-border-right-disable(-#{$bp});
    }
}

