/*

  components/media/logos/c-company-logo.scss
  
  Company logo

*/

.c-company-logo {
  display: block;

  img,
  svg {
    display: block;
  }
}

.c-company-logo__reserve {
  position: relative;

  svg,
  img {
    position: absolute;

    top: 0;
    left: 0;
  }
}


// Mono variants
// ------------------------------------------

.c-company-logo--mono-light {
  @include fill_svg_elements(map-get($color-grey, 5));
}

.c-company-logo--mono-dark {
  @include fill_svg_elements(map-get($color-grey, 3));
}