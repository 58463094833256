// Base element
// ------------------------------------------

@mixin c_rule() {
  border-bottom-width: map-get($border-width, 1);
  border-style: solid;
  border-color: map-get($color-grey, 4);

  &:not(:last-child) {
    margin-bottom: 4em;
  }

  &:not(:first-child) {
    margin-top: 4em;
  }
}


// Dark variant
// ------------------------------------------

@mixin c_rule__dark() {
  border-color: map-get($color-grey, 7);
}


// Light variant
// ------------------------------------------

@mixin c_rule__light() {
  border-color: map-get($color-grey, 4);
}


