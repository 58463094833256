.c-table {
  @include c_table();

  td,
  th {
    @extend .c-copy--1;
  }
}

.c-table--white {
  @include c_table__white();
}

.c-table--border {
  @include c_table__border();
}

.c-table-wrapper--constrained-height {
  @include c_table_wrapper__constrained_height();
}

.c-table-wrapper {
  @extend .shadow-1;
}

// Size variants
// ------------------------------------------

.c-table--1 {
  @extend .c-copy--1;
}

.c-table--2 {
  @extend .c-copy--2;
}

.c-table--3 {
  @extend .c-copy--3;
}

.c-table--article {
  @extend .c-copy--article;
}