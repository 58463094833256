/*

  components/form/c-toggle.scss

  The toggle component encompasses both checkboxs and radios (They share almost identical visual properties)

*/


// Containing wrapper
// ------------------------------------------

.c-toggle {
  @include touch_optimize();
  @extend .f2;
  @extend .f3-xxl;

  position: relative;
  display: block;

  line-height: 1;

  padding-top: 1.5em;
  
  .c-toggle__faux-cover {
    top: 1.5em;
  }

  &:not(:last-child) {
    padding: 1.5em 0;

  }


  // Icon
  .c-icon {
    transition:
      opacity .3s linear,
      transform .5s $easing-bounce;

    font-size: 1rem;

    opacity: 0;

    transform: rotate(90deg) scale(0);
  }
}


// Positioning wrapper
// ------------------------------------------

.c-toggle__pos {
  display: table; // Flex with an input descendant -> buggy, inconsistent widths
}

.c-toggle__native,
.c-toggle__label {
  user-select: none;
  display: table-cell;
  vertical-align: middle;
}


// c-toggle: the control element itself
// ------------------------------------------
// - has a fixed size to allow for native & faux-cover

$toggle-size: prem(24px);


.c-toggle__native {
  width: $toggle-size;
  height: $toggle-size;

  appearance: none;

  opacity: 0;

  cursor: pointer;

  &[disabled] {
    // Don't show pointer when disabled
    cursor: default;
  }
}


// Faux cover element

.c-toggle__faux-cover {
  transition:
    background-color .2s linear,
    border-color .2s,
    transform .3s $easing-bounce;

  color: $color-white;

  position: absolute;

  top: 0;
  left: 0;

  pointer-events: none;

  width: $toggle-size;
  height: $toggle-size;

  background-color: $color-white;

  border-width: map-get($border-width, 1);
  border-style: solid;

  padding: prem(2px);
}


// States
.c-toggle__native {
  &:not(:checked) ~ .c-toggle__faux-cover {
    background-color: $color-white;
    border-color: map-get($color-grey, 4);
  }

  &:checked ~ .c-toggle__faux-cover {
    .c-icon {
      transform: rotate(0) scale(1);
      opacity: 1;
    }
  }

  &:active ~ .c-toggle__faux-cover {
    transform: scale(.75);
  }
}

// Light
.c-toggle__native--light  {
  &:checked ~ .c-toggle__label,
  &:active ~ .c-toggle__label {
    color: map-get($color-grey, 9);
  }

  &:hover:not(:checked):not(:disabled) ~ .c-toggle__label {
    color: map-get($color-grey, 7);
  }

  &:hover:not(:checked):not(:disabled) ~ .c-toggle__faux-cover {
    border-color: map-get($color-grey, 5);
  }

  &[disabled] ~ .c-toggle__label {
    opacity: 0.5;
    color: map-get($color-grey, 4);
    pointer-events: none;

    // Don't show pointer when disabled
    cursor: default;
  }

  &[disabled] ~ .c-toggle__faux-cover {
    @include disabled_input_style;

    .c-icon svg {
      fill: map-get($color-grey, 4);
    }
  }
}

// Dark
.c-toggle__native--dark  {
  &:checked ~ .c-toggle__label,
  &:active ~ .c-toggle__label {
    color: map-get($color-grey, 1);
  }

  &:hover:not(:checked):not(:disabled) ~ .c-toggle__label {
    color: map-get($color-grey, 4);
  }

  &:hover:not(:checked):not(:disabled) ~ .c-toggle__faux-cover {
    border-color: map-get($color-grey, 3);
  }

  &[disabled] ~ .c-toggle__label {
    opacity: 0.5;
    color: map-get($color-grey, 5);
    pointer-events: none;

    // Don't show pointer when disabled
    cursor: default;
  }

  &[disabled] ~ .c-toggle__faux-cover {
    @include disabled_input_style;

    border-color: map-get($color-grey, 6);
    background-color: map-get($color-grey, 7);

    .c-icon svg {
      fill: map-get($color-grey, 5);
    }
  }
}


// Label
// ------------------------------------------

.c-toggle__label {
  @extend .f2;
  @extend .f3-xxl; // matched to c-label

  position: relative;

  transition: color .2s linear;

  line-height: map-get($line-height, "regular");
  color: map-get($color-grey, 6);

  font-weight: 600;

  padding-left: 1em;

  cursor: pointer;
}


// Variant - Checkbox
// ------------------------------------------

.c-toggle--checkbox {
  .c-toggle__faux-cover {
    border-radius: $border-radius-ui;
  }
}

.c-toggle--radio {
  .c-toggle__faux-cover {
    border-radius: 50%;
  }
}

