/*

  components/form/c-form-multistep.scss

  A multi-step form component

*/

.c-form-multistep {
  margin-top: -0.5rem;

  fieldset {
    border: none;

    .c-heading {
      margin: 0;
    }

    .next-button {
      width: 100%;
    }
  } 


  fieldset[data-step="1"] .next-button {
    width: auto;
  }

  .c-form-set-head {
    margin-bottom: 1.3rem;
    gap: 0.5rem;
  }

  .c-form-set[data-step="1"] {
    .c-input-button-wrap {
      .c-btn,
      .c-cards__item__btn {
        padding: 0.9em 1em;
      }

      .c-validation-msg {
        @media (min-width: 75em) {
          display: none;
        }
      }

      & + .c-validation-msg {
        display: none;
        @media (min-width: 75em) {
          display: block;
        }
      }
    }
  }

  .c-form-progress {
    position: relative;
    height: 6px;
    .c-form-progress-bar-wrapper {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      height: 100%;
      background-color: map-get($color-grey, 3);
    }

    .c-form-progress-bar {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      height: 100%;
      width: 0%;
    }
  }

  .c-toggle {
    padding: 0.5em 0;
    
    &.focused .c-toggle__pos {
      border: 2px solid map-get($color-grey, 4);
    }

    &:last-child {
      padding-bottom: 0;
    }

    &__faux-cover {
      background: none;
      border: none;
    }

    &__pos {
      cursor: pointer;
      position: relative;
      background-color: map-get($color-grey, 3);
      padding: 0.8em 1.3em;
      border-radius: 50px;
      border: 2px solid map-get($color-grey, 3);

      &:hover {
        border: 2px solid map-get($color-grey, 4);
      }
    }

    &__label {
      top: 0;
      padding: 0;
      font-weight: 400;
    }

    &.c-toggle--checked {
      .c-toggle__pos {
        border: 2px solid map-get($color-grey, 6);
      }
    }

    .c-toggle__native {
      background: none;
      border: none;

      &:not(:checked) {
        width: 0;
        height: 0;
        overflow: hidden;
      }

      & ~ .c-toggle__faux-cover {
        background: none;
        border: none;
      }
    }

    .c-toggle__native ~ .c-toggle__faux-cover {
      top: 0.8em;
      left: 1.3em;
    }

    .c-icon svg {
      fill: map-get($color-grey, 9);
    }

  }

  // .s-validation-error {
  //   .c-toggle__native:not(:disabled):not(:checked)  ~ .c-toggle__faux-cover {
  //     background: none;
  //     border: none;
  //   }

  //   .c-toggle__pos {
  //     border-color: map-get($color-ui, "error");
  //     background-color: lighten(map-get($color-ui, "error"), 34%);
  //   }
  // }

  .s-validation-error .c-toggle__native ~ .c-toggle__faux-cover,
  .s-validation-error .c-toggle__native:not(:disabled):not(:checked) ~ .c-toggle__faux-cover {
    background: none;
    border: none;
  }
}
