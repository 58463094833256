// Note: this is a direct port from Bootstrap 4

$code-font-size: map-get($type-scale-rem, 4);
$code-color: map-get($color-grey, 6);
$x-pad: map-get($layout-scale-rem, 5);
$y-pad: map-get($layout-scale-rem, 5);

// Inline code
code {
  font-size: $code-font-size;
  color: $code-color;
  word-break: break-word;

  // Streamline the style when inside anchors to avoid broken underline and more
  a > & {
    color: inherit;
  }
}

// User input typically entered via keyboard
kbd {
  padding: $y-pad $x-pad;
  font-size: $code-font-size;
  color: map-get($color-ui, "primary");
  background-color: map-get($color-grey, 3);

  kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 400;
  }
}

// Blocks of code
pre {
  @extend .br-ui-charcoal;
  @extend .shadow-1;
  border: 1px solid map-get($color-grey, 3);
  padding: $y-pad $x-pad;

  display: block;
  font-size: $code-font-size;
  color: map-get($color-grey, 5);

  // Account for some code outputs that place code tags in pre tags
  code {
    font-size: inherit;
    color: inherit;
    word-break: normal;
  }
}

// Enable scrollable blocks of code
.pre-scrollable {
  max-height: 347px;
  overflow: scroll;
}

.c-inline-code {
  @extend .br-ui-charcoal;
  @extend .shadow-1;

  font-family: monospace,monospace;
  font-size: inherit;
  font-weight: 300;
  line-height: 1;

  color: map-get($color-grey, 6);

  border: 1px solid map-get($color-grey, 3);
  padding: map-get($layout-scale-rem, 1) map-get($layout-scale-rem, 2);
}

.c-inline-code--dark {
  color: $color-white;
  background-color: map-get($color-grey, 8);
  border-color: map-get($color-grey, 7);
}



