@mixin tachyons_fill_greys_and_white($suffix:'') {
  .color-fill-white#{$suffix} { fill: $color-white }
  @each $pos, $color in $color-grey {
    .color-fill-grey-#{$pos}#{$suffix} { fill: #{$color}; }
  }
}

@mixin tachyons_fill_colors($suffix:'') {
  @each $label, $color in $color-logo {
    .color-fill-logo-#{$label}#{$suffix} { fill: #{$color}; }
  }
  @each $label, $color in $color-app {
    .color-fill-app-#{$label}#{$suffix} { fill: #{$color}; }
  }
  @each $label, $color in $color-hexicon {
    .color-fill-hexicon-#{$label}#{$suffix} { fill: #{$color}; }
  }
  @each $label, $color in $color-ui {
    .color-fill-ui-#{$label}#{$suffix} { fill: #{$color}; }
  }
  @each $label, $color in $color-buzz {
    .color-fill-buzz-#{$label}#{$suffix} { fill: #{$color}; }
  }
  @each $label, $color in $color-product {
    .color-fill-product-#{$label}#{$suffix} { fill: #{$color}; }
  }
}


// Create standard classes
// ------------------------------------------

@include tachyons_fill_greys_and_white();
@include tachyons_fill_colors();



// Create MQ extensions
// ------------------------------------------

@each $bp, $mq in $bp-widths-min {
  @media (min-width: #{$mq}) {
    @include tachyons_fill_greys_and_white(-#{$bp});
  }
}

