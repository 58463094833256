/*

  c-blocktone.scss

  Consistent block shades and borders

*/


// Light tones
// ------------------------------------------

.c-blocktone-light-lightest {
  background-color: $color-white;
}

.c-blocktone-light-medium {
  background-color: map-get($color-grey, 1);
}

.c-blocktone-light-darkest {
  background-color: map-get($color-grey, 2);
}


// Dark tones
// ------------------------------------------

.c-blocktone-dark-lightest {
  background-color: map-get($color-grey, 8);
}

.c-blocktone-dark-medium {
  background-color: map-get($color-grey, 9);
}

.c-blocktone-dark-darkest {
  background-color: map-get($color-grey, 10);
}


// Blocktone borders
// ------------------------------------------

.c-blocktone-border {
  border-top: map-get($border-width, 1) solid map-get($color-grey, 3);
}

.color-border-blocktone {
  // border-color: map-get($color-grey, 3);
  border-color: rgba(0,0,0,0.07);

  .c-mode-dark & {
    // border-color: map-get($color-grey, 7);
    border-color: rgba(255,255,255,0.06);
  }
}


// Strip next border in block sequence
// ------------------------------------------

.c-blocktone-clear-next-border {
  + .c-blocktone-border {
    border-top: 0;
  }
}


// Gradient tones (from top to bottom)
// ------------------------------------------
.c-blocktone-light-lightest-to-light-medium {
  background-image: linear-gradient($color-white 50%, map-get($color-grey, 1));
}
.c-blocktone-light-lightest-to-light-darkest {
  background-image: linear-gradient($color-white 50%, map-get($color-grey, 2));
}
.c-blocktone-light-medium-to-light-lightest {
  background-image: linear-gradient(map-get($color-grey, 1), $color-white);
}
.c-blocktone-light-medium-to-light-darkest {
  background-image: linear-gradient(map-get($color-grey, 1), map-get($color-grey, 2));
}
.c-blocktone-light-darkest-to-light-lightest {
  background-image: linear-gradient(map-get($color-grey, 2), $color-white, $color-white );
}
.c-blocktone-light-darkest-to-light-medium {
  background-image: linear-gradient(map-get($color-grey, 2), map-get($color-grey, 1), map-get($color-grey, 1));
}



// Blocktone Diagonals
// ------------------------------------------

.c-blocktone-diagonal {
  overflow: hidden;

  &::after {
    content: '';
    display: block;

    position: absolute;
    left: -10vw;
    width: 200vw;

    $height: map-get($layout-scale-rem, 10);
    height: $height;
    bottom: -($height);

    box-shadow: 0px 0px 20px rgba(0,0,0,0.08);
  }
}

.c-blocktone-diagonal--allow-overflow {
  overflow: visible;
}

.c-blocktone-diagonal--bottom-right::after {
  transform: rotate(-5deg);
  transform-origin: top left;
}

.c-blocktone-diagonal--bottom-left::after {
  transform: rotate(5deg);
  transform-origin: top right;
}

.c-blocktone-diagonal--light-lightest::after {
  background-color: $color-white;
}

.c-blocktone-diagonal--light-medium::after {
  background-color: map-get($color-grey, 1);
}

.c-blocktone-diagonal--light-darkest::after {
  background-color: map-get($color-grey, 2);
}


