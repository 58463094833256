
// Standard transition for page load
// ------------------------------------------

.t-page-element-default--load,
.t-page-element-default {
  transform: translate3d(0,100px,0);
  opacity: 0;

  will-change: transform, opacity;

  transition:
    opacity 0.3s linear,
    transform 1.3s $easing-fastInSlowOut;
}

.t-page-element-default--load.t-page-element-default--load--active,
.t-page-element-default.t-page-element-default--active,
.t-page-element-load-wrap .t-page-element-default--load,
.t-page-element-default-wrap .t-page-element-default {
  transform: translate3d(0,0,0);
  opacity: 1;
}

// html.is-chrome-ios .t-page-element-default--load,
html.no-js .t-page-element-default--load,
html.no-js .t-page-element-default {
  transition: none !important;
  transform: translate3d(0,0,0);
  opacity: 1;
}





// Standard animation on scroll
// ------------------------------------------

@keyframes aPageElementDefaultScroll {
  from {
    transform: translate3d(0,400px,0);
    opacity: 0;
  }
  to {
    transform: translate3d(0,0,0);
    opacity: 1;
  }
}

.a-page-element-default--scroll {
  transform: translate3d(0,400px,0);
  opacity: 0;

  will-change: transform, opacity;

  animation-fill-mode: both;
  animation-duration: 1.2s;
  animation-timing-function: $easing-fastInSlowOut;
}

.a-page-element-default--scroll.a-page-element-default--scroll--active,
.t-active-wrap .a-page-element-default--scroll,
.a-page-element-scroll-wrap .a-page-element-default--scroll {
  animation-name: aPageElementDefaultScroll;
}

// html.is-chrome-ios .a-page-element-default--scroll,
html.no-js .a-page-element-default--scroll{
  transform: translate3d(0,0,0);
  opacity: 1;
  animation: none !important;
}


// Delays
// ------------------------------------------

.a-delay-1 {animation-delay: 0.1s}
.a-delay-2 {animation-delay: 0.2s}
.a-delay-3 {animation-delay: 0.3s}
.a-delay-4 {animation-delay: 0.4s}
.a-delay-5 {animation-delay: 0.5s}
.a-delay-6 {animation-delay: 0.6s}
.a-delay-7 {animation-delay: 0.7s}
.a-delay-8 {animation-delay: 0.8s}

.t-delay-1 {transition-delay: 0.1s}
.t-delay-2 {transition-delay: 0.2s}
.t-delay-3 {transition-delay: 0.3s}
.t-delay-4 {transition-delay: 0.4s}
.t-delay-5 {transition-delay: 0.5s}
.t-delay-6 {transition-delay: 0.6s}
.t-delay-7 {transition-delay: 0.7s}
.t-delay-8 {transition-delay: 0.8s}





