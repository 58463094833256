/*

  components/btns/c-btn-pair.scss

*/

// Set Button Width
// ------------------------------------------------------

.c-btn-pair .c-btn {
  width: 100%;
}