@mixin tachyons_negative_margins($suffix:'') {

  // .na1#{$suffix} { margin: -#{map-get($layout-scale-rem, 1)}; }
  // .na2#{$suffix} { margin: -#{map-get($layout-scale-rem, 2)}; }
  .na3#{$suffix} { margin: -#{map-get($layout-scale-rem, 3)}; }
  // .na4#{$suffix} { margin: -#{map-get($layout-scale-rem, 5)}; }
  // .na5#{$suffix} { margin: -#{map-get($layout-scale-rem, 7)}; }
  // .na6#{$suffix} { margin: -#{map-get($layout-scale-rem, 8)}; }
  // .na7#{$suffix} { margin: -#{map-get($layout-scale-rem, 9)}; }

  // .nl1#{$suffix} { margin-left: -#{map-get($layout-scale-rem, 1)}; }
  .nl2#{$suffix} { margin-left: -#{map-get($layout-scale-rem, 2)}; }
  .nl3#{$suffix} { margin-left: -#{map-get($layout-scale-rem, 3)}; }
  .nl4#{$suffix} { margin-left: -#{map-get($layout-scale-rem, 5)}; }
  .nl5#{$suffix} { margin-left: -#{map-get($layout-scale-rem, 7)}; }
  .nl6#{$suffix} { margin-left: -#{map-get($layout-scale-rem, 8)}; }
  .nl7#{$suffix} { margin-left: -#{map-get($layout-scale-rem, 9)}; }

  // .nr1#{$suffix} { margin-right: -#{map-get($layout-scale-rem, 1)}; }
  .nr2#{$suffix} { margin-right: -#{map-get($layout-scale-rem, 2)}; }
  .nr3#{$suffix} { margin-right: -#{map-get($layout-scale-rem, 3)}; }
  .nr4#{$suffix} { margin-right: -#{map-get($layout-scale-rem, 5)}; }
  .nr5#{$suffix} { margin-right: -#{map-get($layout-scale-rem, 7)}; }
  .nr6#{$suffix} { margin-right: -#{map-get($layout-scale-rem, 8)}; }
  .nr7#{$suffix} { margin-right: -#{map-get($layout-scale-rem, 9)}; }

  // .nb1#{$suffix} { margin-bottom: -#{map-get($layout-scale-rem, 1)}; }
  .nb2#{$suffix} { margin-bottom: -#{map-get($layout-scale-rem, 2)}; }
  .nb3#{$suffix} { margin-bottom: -#{map-get($layout-scale-rem, 3)}; }
  .nb4#{$suffix} { margin-bottom: -#{map-get($layout-scale-rem, 5)}; }
  .nb5#{$suffix} { margin-bottom: -#{map-get($layout-scale-rem, 7)}; }
  .nb6#{$suffix} { margin-bottom: -#{map-get($layout-scale-rem, 8)}; }
  .nb7#{$suffix} { margin-bottom: -#{map-get($layout-scale-rem, 9)}; }
  // .nb8#{$suffix} { margin-bottom: -#{map-get($layout-scale-rem, 10)}; }

  // .nt1#{$suffix} { margin-top: -#{map-get($layout-scale-rem, 1)}; }
  // .nt2#{$suffix} { margin-top: -#{map-get($layout-scale-rem, 2)}; }
  // .nt3#{$suffix} { margin-top: -#{map-get($layout-scale-rem, 3)}; }
  .nt4#{$suffix} { margin-top: -#{map-get($layout-scale-rem, 5)}; }
  .nt5#{$suffix} { margin-top: -#{map-get($layout-scale-rem, 7)}; }
  .nt6#{$suffix} { margin-top: -#{map-get($layout-scale-rem, 8)}; }
  .nt7#{$suffix} { margin-top: -#{map-get($layout-scale-rem, 9)}; }

}



// Create standard classes
// ------------------------------------------

@include tachyons_negative_margins();



// Create MQ extensions
// ------------------------------------------

@each $bp, $mq in $bp-widths-min {
    @media (min-width: #{$mq}) {
      @include tachyons_negative_margins(-#{$bp});
    }
}

