@import './c-heading--mixins.scss';
@import './c-copy--mixins.scss';

@mixin c_table {

  line-height: map-get($line-height, "tight");

  border-collapse: collapse;

  width: 100%;
  text-align: left;

  max-width: 100%;

  td {
    @include c_copy();

    border-top: map-get($border-width, 1) solid map-get($color-grey, 3);
  }

  th {
    @include c_heading();

    font-weight: map-get($font-weight, 600);
    background-color: map-get($color-grey, 1);
  }

  td,
  th {
    padding: .95em 1.3em;

    text-align: left;

    &:not(:last-of-type) {
      border-right: map-get($border-width, 1) dotted map-get($color-grey, 3);
    }
  }
}

@mixin c_table__white() {
  td,
  th {
    background-color: $color-white;
  }
}

@mixin c_table__border() {
  border-radius: map-get($border-radius, 1);
  border: map-get($border-width, 1) solid map-get($color-grey, 3);
}

@mixin c_table_wrapper__constrained_height() {
  overflow-y: scroll; /* has to be scroll, not auto */
  -webkit-overflow-scrolling: touch;

  max-height: 80vh;
  border: map-get($border-width, 1) solid map-get($color-grey, 3);
  border-radius: $border-radius-ui;

  .c-table--border {
    border: none;
  }
}

