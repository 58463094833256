@mixin tachyons_borders_basic($suffix:'') {
  .ba#{$suffix} { border-style: solid; border-width: map-get($border-width, 1); }
  .bt#{$suffix} { border-top-style: solid; border-top-width: map-get($border-width, 1); }
  .br#{$suffix} { border-right-style: solid; border-right-width: map-get($border-width, 1); }
  .bb#{$suffix} { border-bottom-style: solid; border-bottom-width: map-get($border-width, 1); }
  .bl#{$suffix} { border-left-style: solid; border-left-width: map-get($border-width, 1); }
}

@mixin tachyons_borders_advanced($suffix:'') {
  .bn#{$suffix} { border-style: none; border-width: 0; }
  .bn_t#{$suffix} { border-top: 0px solid transparent; }
  .bn_r#{$suffix} { border-right: 0px solid transparent; }
  .bn_l#{$suffix} { border-left: 0px solid transparent; }
  .bn_b#{$suffix} { border-bottom: 0px solid transparent; }
}

