.c-list {
  @include c_list();
}

.c-list--unordered {
  @include c_list__unordered();
}

.c-list--nav-unordered {
  @include c_list__nav_unordered();
}

.c-list--ordered {
  @include c_list__ordered();
}

.c-list--legal-ordered {
  @include c_list__legal_ordered();
}

.c-list--dark {
  @include c_list__dark();
}

.c-list--light {
  @include c_list__light();
}




// Responsive size variants
// ------------------------------------------

.c-list--1 {
  @extend .c-copy--1;
}

.c-list--2 {
  @extend .c-copy--2;
}

.c-list--3 {
  @extend .c-copy--3;
}

.c-list--article {
  @extend .c-copy--article;
}