
// bounce
// ------------------------------------------
// Eg: https://www.brandwatch.com/pattern-library/?section=brand-guidelines&page=tone-of-voice;

@keyframes bounce {
  from { transform: translate3d(0, -25%, 0);     }
  to   { transform: translate3d(0, 25%, 0); }
}
.a-bounce {
  animation: bounce 0.3s;
  animation-direction: alternate;
  animation-timing-function: cubic-bezier(.5,0.05,1,.5);
  animation-iteration-count: infinite;
  transform: translate3d(0, -25%, 0); 
}


// softBounceIn
// ------------------------------------------
// Eg: https://www.brandwatch.com/audiences/content-marketing/ slide-2 "Trend Detection"

@keyframes softBounceIn {
  from,
  60%,
  to {
    animation-timing-function: cubic-bezier(.24,1.1,.7,1.03);
  }

  0% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }

  60% {
    opacity: 1;
    transform: scale3d(1.02, 1.02, 1.02);
  }

  to {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}
.a-soft-bounce-in--active,
.a-active-wrap .a-soft-bounce-in {
  animation-duration: 0.75s;
  animation-name: softBounceIn;
  animation-fill-mode: forwards;
}


// rotateClockwise
// ------------------------------------------
// Used on loading spinners

@keyframes rotateClockwise {
  0%   {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
.a-rotate-clockwise--active,
.a-active-wrap .a-rotate-clockwise {
  animation-duration: 300ms;
  animation-name: rotateClockwise;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  transform-origin: center center;
}


// hexicon_color_cycle_bg
// ------------------------------------------
// Used for Pitchbox brand tests - animates the bg color
// on an el through all logos/hexicon colors

@keyframes hexicon_color_cycle_bg {
  14% {
    background-color: map-get($color-hexicon, "red");
  }
  27% {
    background-color: map-get($color-hexicon, "orange");
  }
  43% {
    background-color: map-get($color-hexicon, "yellow");
  }
  57% {
    background-color: map-get($color-hexicon, "green");
  }
  71% {
    background-color: map-get($color-hexicon, "blue");
  }
  86% {
    background-color: map-get($color-hexicon, "purple");
  }
  100% {
    background-color: map-get($color-hexicon, "red");
  }
}

.hexicon-bg-color-anim {
  animation-name: hexicon_color_cycle_bg;
  animation-duration: 30s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}


// hexicon_color_cycle_bg_abrupt
// ------------------------------------------
// Same as above but "abrupt"

@keyframes hexicon_color_cycle_bg_abrupt {
  0%     { background-color: map-get($color-hexicon, "green");  }
  13.99% { background-color: map-get($color-hexicon, "green");  }
  14%    { background-color: map-get($color-hexicon, "red");    }
  26.99% { background-color: map-get($color-hexicon, "red");    }
  27%    { background-color: map-get($color-hexicon, "orange"); }
  42.99% { background-color: map-get($color-hexicon, "orange"); }
  43%    { background-color: map-get($color-hexicon, "yellow"); }
  65.99% { background-color: map-get($color-hexicon, "yellow"); }
  57%    { background-color: map-get($color-hexicon, "green");  }
  70.99% { background-color: map-get($color-hexicon, "green");  }
  71%    { background-color: map-get($color-hexicon, "blue");   }
  85.99% { background-color: map-get($color-hexicon, "blue");   }
  86%    { background-color: map-get($color-hexicon, "purple"); }
  99.99% { background-color: map-get($color-hexicon, "purple"); }
}

.hexicon-bg-color-anim-abrupt {
  animation-name: hexicon_color_cycle_bg_abrupt;
  animation-duration: 30s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}


// c-big-dot
// ------------------------------------------
// From Pitchbox brand work 

.c-big-dot {
  transition: transform 1.5s $easing-fastInSlowOut;

  &:hover {
    transform: scale(1.1);
    .c-big-dot__color {
      background-color: map-get($color-hexicon, "red");
    }
  }

  &:active {
    transform: scale(0.8);
  }
}

// Randomly Floating
@keyframes floating {
  0%   { 
    transform: translate(0px, -10px) rotate(10deg); 
    transform-origin: right left;   
  }

  100% { 
    transform: translate(-10px, 10px) rotate(-30deg);
    transform-origin: right left;     
  }
}

// Rotate 20%
@keyframes rotate_20 {
  0%   {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(20deg);
  }
}

.float-anim {
  animation-name: floating;
  animation-duration: 8s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-direction: alternate;
}


.full_rotate-anim { // used in brandwatch academy  
  animation-name: rotateClockwise;
  animation-duration: 100s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  transform-origin: center center;
}