/*

  components/form/c-selectable-input.scss

  Wraps an input with a suggestion dropdown

*/

.c-selectable-input {

}

.c-selectable-input__list {
  @include touch_optimize();

  // Some visual styles tweaked from base_form_styles
  background-color: $color-white;
  padding: 0;
  font-size: map-get($type-scale-rem, 3);
  line-height: map-get($line-height, "regular");

  border-bottom-left-radius: $border-radius-ui;
  border-bottom-right-radius: $border-radius-ui;
  border-style: solid;
  border-width: map-get($border-width, 1);
  border-color: map-get($color-grey, 4);
  border-top-width: 0;

  position: absolute;
  width: 100%;
  z-index: 2;
  overflow: auto;
}
.c-selectable-input:not(.-list-hidden) .c-input {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.c-selectable-input:not(.-list-hidden) .c-select-able {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.c-selectable-input.-list-hidden .c-selectable-input__list {
  display: none;
}


.c-selectable-input__item {
  color: map-get($color-grey, 10);
  padding: 0.4em 1em;
  cursor: pointer;
}
.c-selectable-input__item.-preliminary-selection {
  background-color: map-get($color-grey, 3);
}
.c-selectable-input__item:active {
  background-color: map-get($color-grey, 4);
}

.c-selectable-input__item-address {
  font-size: 0.8em;
  color: map-get($color-grey, 7);
}
