.u-breakpoint-indicator {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999999999999;

  background-color: rgba(0,0,0,0.8);

  font-size: 1rem;
  width: 4rem;
  height: 2rem;

  &::before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    @include c_heading();

    line-height: 1;
    color: white;

    content: "none";

    @media (min-width: bp-width-min('xs')) {
      content: "xs";
    }
    @media (min-width: bp-width-min('sm')) {
      content: "sm";
    }
    @media (min-width: bp-width-min('md')) {
      content: "md";
    }
    @media (min-width: bp-width-min('lg')) {
      content: "lg";
    }
    @media (min-width: bp-width-min('lg-xl')) {
      content: "lg-xl";
    }
    @media (min-width: bp-width-min('xl')) {
      content: "xl";
    }
    @media (min-width: bp-width-min('xxl')) {
      content: "xxl";
    }
  }
}