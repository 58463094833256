@import "./c-content--mixins.scss";
.c-mode-dark,
.c-mode-light .c-mode-dark {
    .c-heading {
        @extend .c-heading--dark;
    }
    .c-copy {
        @extend .c-copy--dark;
    }
    .c-list {
        @extend .c-list--dark;
    }
    .c-blockquote {
        @extend .c-blockquote--dark;
    }
    .c-rule {
        @extend .c-rule--dark;
    }
    .c-content {
        @include c_content__dark;
    }
}

.color-wrap-white.c-mode-light .c-heading {
    color: #ffffff !important;
}

.c-mode-light,
.c-mode-dark .c-mode-light {
    .c-heading {
        @extend .c-heading--light;
    }
    .c-copy {
        @extend .c-copy--light;
    }
    .c-list {
        @extend .c-list--light;
    }
    .c-blockquote {
        @extend .c-blockquote--light;
    }
    .c-rule {
        @extend .c-rule--light;
    }
    .c-content {
        @include c_content__light;
    }
}
