// Create standard classes
// ------------------------------------------

@include tachyons_border_colors();
@include tachyons_border_greys_white_transparent();



// Create MQ extensions
// ------------------------------------------

@each $bp, $mq in $bp-widths-min {
    @media (min-width: #{$mq}) {
      @include tachyons_border_greys_white_transparent(-#{$bp});
    }
}

