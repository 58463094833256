@mixin nubbin() {
  // Pointy thing used on tool-tips etc - this mixin creates two pseudo els
  // they are positioned below with separate modifier mixins
  &::after {
    position: absolute;
    content: "";
    left: 50%;
    margin-left: -1em;
    border-left: 1em solid transparent;
    border-right: 1em solid transparent;
  }

  // Main arrow
  &::after {
    font-size: 0.5rem;
  }
}

@mixin nubbin--below($bg_color: $color-white, $shadow_color: transparentize(map-get($color-grey, 4), 0.95)) {
  &::after {
    border-top: 1em solid $bg_color;
    bottom: -1em;
  }
}

@mixin nubbin--above($bg_color: $color-white, $shadow_color: transparentize(map-get($color-grey, 4), 0.95)) {
  &::after {
    border-bottom: 1em solid $bg_color;
    top: -1em;
  }
}

.nubbin-above {
  @include nubbin();
  @include nubbin--above();
}

.nubbin-above-lg {
  @media (min-width: bp-width-min('lg')) {
    @include nubbin();
    @include nubbin--above();
  }
}

.nubbin-below {
  @include nubbin();
  @include nubbin--below();
}

.nubbin--jumbo {
  &::after {
   transform: scale(1.5);
  }
}

.nubbin--above-dark {
  @include nubbin--above(map-get($color-grey, 8), transparentize(map-get($color-grey, 10), 0.95));
}


