@mixin tachyons_scale($suffix:'') {
  // .scale-01#{$suffix} { transform: scale(0.1); }
  // .scale-02#{$suffix} { transform: scale(0.2); }
  // .scale-03#{$suffix} { transform: scale(0.3); }
  // .scale-04#{$suffix} { transform: scale(0.4); }
  // .scale-05#{$suffix} { transform: scale(0.5); }
  // .scale-06#{$suffix} { transform: scale(0.6); }
  // .scale-07#{$suffix} { transform: scale(0.7); }
  // .scale-08#{$suffix} { transform: scale(0.8); }
  .scale-085#{$suffix} { transform: scale(0.85); }
  .scale-09#{$suffix} { transform: scale(0.9); }
  .scale-10#{$suffix} { transform: scale(1.0); }
  .scale-11#{$suffix} { transform: scale(1.1); }
}


// Create standard classes
// ------------------------------------------

@include tachyons_scale();



// Create MQ extensions
// ------------------------------------------

@each $bp, $mq in $bp-widths-min {
    @media (min-width: #{$mq}) {
      @include tachyons_scale(-#{$bp});
    }
}

