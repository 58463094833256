@mixin tachyons_bg_position($suffix:'') {
  .bg-center#{$suffix} {
    background-position: center center;
  }
  // .bg-top#{$suffix} {
  //   background-position: top center;
  // }
  // .bg-right#{$suffix} {
  //   background-position: center right;
  // }
  // .bg-bottom#{$suffix} {
  //   background-position: bottom center;
  // }
  // .bg-left#{$suffix} {
  //   background-position: center left;
  // }
}


// Create standard classes
// ------------------------------------------

@include tachyons_bg_position();



// Create MQ extensions
// ------------------------------------------

// @each $bp, $mq in $bp-widths-min {
//     @media (min-width: #{$mq}) {
//       @include tachyons_bg_position(-#{$bp});
//     }
// }

