@import './../../mixins/touch-optimize';
@import './../typography/c-heading--mixins.scss';


@mixin square_btn_styles() {
  text-transform: uppercase;
  letter-spacing: map-get($letter-spacing, "loose");
  font-weight: map-get($font-weight, 600);
  line-height: map-get($line-height, "regular");
  font-style: normal;
  border-radius: $border-radius-ui;
}

@mixin rounded_btn_styles() {
  @include c_heading();
  border-radius: $border-radius-ui-charcoal;

  &.c-btn--primary-ui {
    color: $color-white;
    text-shadow: $text-shadow;
  }
}


// Base
// ------------------------------------------

$c-btn-padding: .9em 1.75em;

@mixin c_btn() {
  .c-btn {
    @include touch_optimize();

    display: inline-flex;
    justify-content: center;
    text-align: center;

    vertical-align: text-bottom;

    position: relative;
    overflow: hidden;

    .c-btn__color-mix,
    .c-btn__color {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border-radius: $border-radius-ui;
    }

    &:not(.c-btn--rounded) {
      @include square_btn_styles();
    }

    &.c-btn--rounded {
      @include rounded_btn_styles();
    }

    font-size: prem(13px);

    padding: $c-btn-padding;

    user-select: none;
    cursor: pointer;

    &[disabled] {
      // Disable actions on disabled buttons
      pointer-events: none;
    }
  }


  // Color
  // ------------------------------------------
  // element that applies a color to the button (not tertiary variant)

  .c-btn__color {
    transition: opacity 0.2s linear;
    transition: background-color 0.2s linear;

    .c-btn:focus & {
      opacity: 0.9;
    }
    
    .c-btn:hover & {
        opacity: 0.9;
    }
    .c-btn:active & {
        opacity: 0.75;
    }
  }
  // Icon variants
  // ------------------------------------------

  // Shared
  .c-btn--icon-left,
  .c-btn--icon-right {
    align-items: center;

    .c-icon {
      display: block;
    }
  }

  // Right
  .c-btn--icon-right {
    .c-icon {
      padding-left: .5em;
    }
  }

  // Left
  .c-btn--icon-left {
    flex-direction: row-reverse;

    .c-icon {
      padding-right: .5em;
    }
  }


  // Style variants
  // ------------------------------------------

  .c-btn--primary-ui {
    color: $color-white;
  }

  .c-btn--primary-ui {
    &[disabled] {
      opacity: 0.4;
      color: map-get($color-grey, 2);
    }
  }


  // Secondary contrast variants
  // ------------------------------------------


  // Light
  .c-btn--secondary-light {
    color: map-get($color-grey, 7);

    box-shadow: inset 0 0 0 1px map-get($color-grey, 4);

    &:hover {
      box-shadow: inset 0 0 0 1px map-get($color-grey, 7);
    }

    &[disabled] {
      color: map-get($color-grey, 5);
      opacity: 0.4;
    }
  }

  // Dark
  .c-btn--secondary-dark,
  .c-btn--secondary-dark.c-btn--rounded.c-btn--rounded {
    color: map-get($color-grey, 3);

    box-shadow: inset 0 0 0 1px map-get($color-grey, 6);

    &:hover {
      box-shadow: inset 0 0 0 1px map-get($color-grey, 3);
    }

    &[disabled] {
      color: map-get($color-grey, 5);
      opacity: 0.4;
    }
  }


  // Tertiary contrast variants
  // ------------------------------------------

  // Tertiary underline
  .c-btn__underline {
    position: absolute;

    bottom: 0;
    left: 0;

    width: 100%;

    height: map-get($border-width, 1);
  }

  // Base grey underline
  .c-btn__underline--base {
    opacity: .6;

    z-index: 1;
  }

  // Color overlay of underline
  .c-btn__underline--color {
    animation-timing-function: $easing-fastInSlowOut;
    animation-duration: .75s;
    animation-fill-mode: forwards;
    animation-name: simple-transform-right-out;

    z-index: 2;
  }

  // Common styles
  .c-btn--tertiary-light,
  .c-btn--tertiary-dark {
    position: relative;
    border-radius: 0;
    overflow: hidden;

    padding-top: 0.2em;
    padding-bottom: 0.35em;
    padding-left: 0;
    padding-right: 0;

    &:hover {
      .c-btn__underline--color {
        animation-name: simple-transform-left-in;
      }
    }
  }

  // Light
  .c-btn--tertiary-light {
    color: map-get($color-grey, 7);

    &:hover {
      color: map-get($color-grey, 8);
    }

    .c-btn__underline--base {
      background-color: map-get($color-grey, 4);
    }

    &[disabled] {
      opacity: .3;

      .c-btn__underline--color {
        opacity: 0;
      }
    }
  }

  // Dark
  .c-btn--tertiary-dark {
    color: map-get($color-grey, 3);

    &:hover {
      color: map-get($color-grey, 1);
    }

    .c-btn__underline--base {
      background-color: map-get($color-grey, 6);
    }

    &[disabled] {
      opacity: .3;

      .c-btn__underline--color {
        opacity: 0;
      }
    }
  }



  // State button
  // ------------------------------------------


  // Set initial common props
  .c-btn__text,
  .c-btn__state {
    transition:
      opacity .2s linear,
      transform .5s $easing-bounce;

    opacity: 0;
  }

  // Standing text
  .c-btn__text {
    transform: scale(.5) rotate(0deg);
  }

  // Common state icons
  .c-btn__state {
    position: absolute;

    display: block;

    font-size: 1.8em;

    left: 50%;
    top: 50%;

    width: 1em;
    height: 1em;

    margin-top: -.5em;
    margin-left: -.5em;

    transform: scale(0) rotate(90deg);

    .c-icon {
      display: block;
    }
  }

  // Default standing state
  .c-btn--standing {
    .c-btn__text {
      opacity: 1;
      transform: scale(1) rotate(0deg);
    }
  }

  // Loading indicator
  .c-btn--loading {
    $btn-color: map-get($color-ui, "success");

    .c-btn__color {
      background-color: $btn-color;
      border-color: $btn-color;
    }

    .c-btn__state--loader {
      opacity: 1;
      transform: scale(1) rotate(0deg);

      .c-icon {
        animation-name: spin-clockwise;
        animation-duration: .3s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
      }
    }
  }

  // On error
  .c-btn--error {
    $btn-color: map-get($color-ui, "error");

    .c-btn__color {
      background-color: $btn-color !important;
      border-color: $btn-color !important;
    }

    .c-btn__state--error {
      opacity: 1;
      transform: scale(1) rotate(0deg);
    }
  }

  // Confirmed
  .c-btn--confirmed {
    $btn-color: map-get($color-ui, "success");

    .c-btn__color {
      background-color: $btn-color;
      border-color: $btn-color;
    }

    .c-btn__state--confirmed {
      opacity: 1;
      transform: scale(1) rotate(0deg);
    }
  }

  // Animation
  // ------------------------------------------

  .c-btn--animate-left-right {
    &:not(:disabled):hover {
      .c-icon {
        animation-timing-function: $easing-bounce;
        animation-duration: .5s;
        animation-name: in-out-left-right;
      }
    }
  }

  .c-btn--animate-right-left {
    &:not(:disabled):hover {
      .c-icon {
        animation-timing-function: $easing-bounce;
        animation-duration: .5s;
        animation-name: in-out-right-left;
      }
    }
  }

  .c-btn--animate-top-bottom {
    &:not(:disabled):hover {
      .c-icon {
        animation-timing-function: $easing-bounce;
        animation-duration: .5s;
        animation-name: in-out-top-bottom;
      }
    }
  }
}

// Button sizes
// ------------------------------------------

@mixin c_btn_sizes($suffix:'') {
  // Physical size of button
  .c-btn--1#{$suffix} {
    font-size: prem(12px);
  }

  .c-btn--2#{$suffix} {
    font-size: prem(13px);
  }

  .c-btn--3#{$suffix} {
    font-size: prem(15px);
  }

  .c-btn--4#{$suffix} {
    font-size: prem(18px);
  }
}


// Disable border radius
// ------------------------------------------

@mixin c-btn-border-radius-disable($suffix:'') {
  // Disable left radius - for combined input and button
  .c-btn--no-left-radius#{$suffix},
  .c-btn--no-left-radius#{$suffix} .c-btn__color-mix,
  .c-btn--no-left-radius#{$suffix} .c-btn__color {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
}