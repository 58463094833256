.c-client-logo {
  opacity: .9;

  @media (max-width: bp-width-max(lg)) {
    margin: auto;
  }

  @media (max-width: bp-width-max(sm)) {
    max-width: 6rem;
  }

  @media (min-width: bp-width-min(sm)) and (max-width: bp-width-max(lg)) {
    max-width: 6rem;
  }

  svg {
    max-height: 2rem;

    margin: auto;

    @media (min-width: bp-width-min(sm)){
      max-height: 3rem;
    }
  }
}