/*

  c-blockpad.scss

  Consistent vertical padding for page blocks

*/

// TODO - Iterate on these values inline with baseline
// At time of writing this is an immediate update to address medium to small screens
// Independently of the layout scale issues
@mixin blockpad-regular($dir) {
  padding-#{$dir}: prem(60px);

  @media (min-width: bp-width-min('sm')) {
    padding-#{$dir}: prem(70px);
  }

  @media (min-width: bp-width-min('md')) {
    padding-#{$dir}: prem(85px);
  }

  @media (min-width: bp-width-min('lg')) {
    padding-#{$dir}: prem(90px);
  }

  @media (min-width: bp-width-min('xl')) {
    padding-#{$dir}: prem(100px);
  }

  @media (min-width: bp-width-min('xxl')) {
    padding-#{$dir}: prem(120px);
  }
}

@mixin blockpad-large($dir) {
  padding-#{$dir}: map-get($layout-scale-rem, 8);

  @media (min-width: bp-width-min('xl')) {
    padding-#{$dir}: map-get($layout-scale-rem, 9);
  }
}

@mixin blockpad-small($dir) {
  padding-#{$dir}: map-get($layout-scale-rem, 6);

  @media (min-width: bp-width-min('xl')) {
    padding-#{$dir}: map-get($layout-scale-rem, 7);
  }
}


// Standard classes
// ------------------------------------------

.c-blockpad-top {
  @include blockpad-regular('top');
}

.c-blockpad-bottom {
  @include blockpad-regular('bottom');
}

.c-blockpad {
  @extend .c-blockpad-top;
  @extend .c-blockpad-bottom;
}


// Small Variant
// ------------------------------------------

.c-blockpad-top--small {
  @include blockpad-small('top');
}

.c-blockpad-bottom--small {
  @include blockpad-small('bottom');
}

.c-blockpad-small {
  @extend .c-blockpad-top--small;
  @extend .c-blockpad-bottom--small;
}

// Large Variant
// ------------------------------------------

.c-blockpad-top--large {
  @include blockpad-large('top');
}

.c-blockpad-bottom--large {
  @include blockpad-large('bottom');
}

.c-blockpad-large {
  @extend .c-blockpad-top--large;
  @extend .c-blockpad-bottom--large;
}


// Addditional internal padding adding an offset against abs headers
// ------------------------------------------

.c-blockpad__header-offset {
  padding-top: map-get($layout-scale-rem, 7);

}