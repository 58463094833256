/*

  layout/l-grid.scss

  A flexbox grid, based largely on https://philipwalton.github.io/solved-by-flexbox/demos/grids/
  Extended with responsive classes ala Tachyons.

*/

@use "sass:math";

// Grid setup
// ------------------------------------------

@mixin l_grid($suffix: "") {
  .l-grid#{$suffix} {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    list-style: none;
    flex-basis: 100%;
  }
}

// Grid item setup
// ------------------------------------------

@mixin l_grid_item($suffix: "") {
  // Widths
  .l-grid__item--auto#{$suffix} {
    flex: 1;
    width: auto;
  }
  .l-grid__item--natural#{$suffix} {
    flex: 1 1 auto;
  }
  @for $i from 1 through 12 {
    .l-grid__item--#{$i}#{$suffix} {
      width: math.div(100%, 12) * $i;
    }
  }
}

// Gutter setup
// ------------------------------------------
@mixin generate_grid_gutters($class_no, $layout_val, $suffix) {
  .l-grid--gutters-#{$class_no}#{$suffix} {
    width: auto; // must be auto
    margin-top: -#{map-get($layout-scale-rem, $layout_val)};
    margin-left: -#{map-get($layout-scale-rem, $layout_val)};
    & > .l-grid__item,
    .l-grid__item.l-grid {
      padding-top: #{map-get($layout-scale-rem, $layout_val)};
      padding-left: #{map-get($layout-scale-rem, $layout_val)};
    }
    & > .l-grid.l-grid--gutters-#{$class_no}#{$suffix} {
      margin-left: 0;
      padding-left: 0;
    }
  }
}

@mixin l_grid_gutters($suffix: "") {
  @include generate_grid_gutters(0, 0, $suffix);
  @include generate_grid_gutters(1, 2, $suffix);
  @include generate_grid_gutters(2, 3, $suffix);
  @include generate_grid_gutters(3, 5, $suffix);
  @include generate_grid_gutters(4, 7, $suffix);
  @include generate_grid_gutters(5, 8, $suffix);
}

// Create standard classes
// ------------------------------------------

@include l_grid();
@include l_grid_item();
@include l_grid_gutters();

// Create MQ extensions
// ------------------------------------------

@each $bp, $mq in $bp-widths-min {
  @media (min-width: #{$mq}) {
    @include l_grid(-#{$bp});
    @include l_grid_item(-#{$bp});
    @include l_grid_gutters(-#{$bp});
  }
}
