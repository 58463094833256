.c-oembed-wrapper {
  iframe {
    width: 100%;
    margin: 0 auto;
    max-width: none !important;
  }

  .twitter-tweet {
    margin: 0 auto !important;
  }
}