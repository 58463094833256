// Main shadow generation mixin
// ------------------------------------------

@mixin generate-shadow-edge($depth, $direction) {
  $size: 1rem * $depth;

  content: "";
  position: absolute;
  opacity: .02;

  content: "";
  position: absolute;

  #{$direction}: -$size;

  @if ($direction == 'top' or $direction == 'bottom') {
    width: 100%;
    height: $size;
    left: 0;
  } @else {
    height: 100%;
    top: 0;
    width: $size;
  }

  background: linear-gradient(to #{$direction}, rgba($color-shadow,1) 0%,rgba($color-shadow,0) 50%);
}

// Generate variant for each side
// ------------------------------------------

@mixin generate-shadow-edge-utils($depth) {

  &--t {
    @include generate-shadow-edge($depth, 'top');
  }

  &--b {
    @include generate-shadow-edge($depth, 'bottom');
  }

  // &--l {
  //   @include generate-shadow-edge($depth, 'left');
  // }

  // &--r {
  //   @include generate-shadow-edge($depth, 'right');
  // }
}

// Class creator mixin
// ------------------------------------------

@mixin c_shadow_edge($suffix:'') {
  // .c-shadow-edge-1#{$suffix} {
  //   @include generate-shadow-edge-utils(1);
  // }
  // .c-shadow-edge-2#{$suffix} {
  //   @include generate-shadow-edge-utils(2);
  // }
  // .c-shadow-edge-3#{$suffix} {
  //   @include generate-shadow-edge-utils(3);
  // }
  // .c-shadow-edge-4#{$suffix} {
  //   @include generate-shadow-edge-utils(4);
  // }
  .c-shadow-edge-5#{$suffix} {
    @include generate-shadow-edge-utils(5);
  }
}

// Create standard classes
// ------------------------------------------

@include c_shadow_edge();



// Create MQ extensions
// ------------------------------------------

// @each $bp, $mq in $bp-widths-min {
//     @media (min-width: #{$mq}) {
//       @include c_shadow_edge(-#{$bp});
//     }
// }

