// Import c_copy() mixins
// ------------------------------------------

.c-copy {
  @include c_copy();
}
.c-copy--dark {
  @include c_copy__dark();
}
.c-copy--light {
  @include c_copy__light();
}
.c-copy--inherit {
  @include c_copy__inherit();
}


// Responsive size variants
// ------------------------------------------

.c-copy--1 {
  @extend .f3;
  @extend .f3-sm;
  @extend .f4-lg;
}

.c-copy--2 {
  @extend .f3;
  @extend .f4-sm;
  @extend .f5-lg;
}

.c-copy--3 {
  @extend .f4;
  @extend .f5-sm;
  @extend .f6-xl;
}

.c-copy--article {
  @extend .f4;
  @extend .f5-lg;
}


