.js-smart-ellipsis--has-overflow {
  .js-smart-ellipsis__text-wrapper {
    position: relative;
    overflow: hidden;
  }
}

.js-smart-ellipsis {
  .js-smart-ellipsis__text {
    max-width: none;
    text-overflow: initial;
  }
}

.js-smart-ellipsis--enabled {
  .js-smart-ellipsis__text {
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden
  }
}