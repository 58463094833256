@mixin tachyons_utils() {

  .webkit-ov-touch {
    -webkit-overflow-scrolling: touch;
  }
  .user-select-none {
    user-select: none;
  }
  .outline-none {
    outline: none;
  }
}

@mixin tachyons_utils_responsive($suffix:'') {

  // Pointer Events
  .pe-none#{$suffix} {
    pointer-events: none;
  }
  .pe-all#{$suffix} {
    pointer-events: all;
  }

  // BG Images
  .bg-img#{$suffix} {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  // Visibility
  .visibility-hidden#{$suffix} {
    visibility: hidden;
  }
}


// Create standard classes
// ------------------------------------------

@include tachyons_utils();
@include tachyons_utils_responsive();

// Create MQ extensions
// ------------------------------------------

@each $bp, $mq in $bp-widths-min {
    @media (min-width: #{$mq}) {
      @include tachyons_utils_responsive(-#{$bp});
    }
}

