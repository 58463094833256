/*

  layout/l-enforce-page-height.scss

  Why? - Make sure pages with little content don't look broken
       - Edit: disable when IE as flex is too brittle

*/

html:not(.is-ie) {
  // Attaches to main body to control contents
  // ------------------------------------------

  .l-enforce-page-height {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
  }


  // Wrapper pushes down the footer
  // This should be used on every page
  // ------------------------------------------

  .l-enforce-page-height__wrap {
    position: relative;

    display: flex;
    flex-direction: column;
    flex-grow: 1;

    // Clipped body overflow not always robust enough in all situations
    overflow: hidden;
  }


  // Page blocks
  // ------------------------------------------

  .l-enforce-page-height__block {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  // Center the inter contents of a block if it's expanded
  // ------------------------------------------

  .l-enforce-page-height__block--fill-centered {
    justify-content: center;
  }
}

