/*

  mixins/shadow.scss

*/


// Main shadow generation mixin
@mixin generate-shadow-edge($depth, $direction) {
  $size: 1rem * $depth;

  content: "";
  position: absolute;
  opacity: .04;

  content: "";
  position: absolute;

  #{$direction}: -$size;

  @if ($direction == 'top' or $direction == 'bottom') {
    width: 100%;
    height: $size;
    left: 0;
  } @else {
    height: 100%;
    top: 0;
    width: $size;
  }

  background: linear-gradient(to #{$direction}, rgba($color-shadow,1) 0%,rgba($color-shadow,0) 50%);
}

// Generate variant for each side
@mixin generate-shadow-edge-utils($depth) {

  &--t {
    @include generate-shadow-edge($depth, 'top');
  }

  &--b {
    @include generate-shadow-edge($depth, 'bottom');
  }

  &--l {
    @include generate-shadow-edge($depth, 'left');
  }

  &--r {
    @include generate-shadow-edge($depth, 'right');
  }
}

