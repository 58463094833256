@import "./c-link--mixins.scss";

@mixin c_lead() {
  font-style: normal;
  font-weight: map-get($font-weight, 400);
  letter-spacing: map-get($letter-spacing, "tight");
  line-height: map-get($line-height, "regular");

  color: map-get($color-grey, 10);

  .c-mode-dark & {
    color: map-get($color-grey, 4);
  }

  .c-mode-dark .c-mode-light & {
    color: map-get($color-grey, 10);
  }

  a:not(.c-btn) {
    @include c_link();
  }
}
