@mixin standing_styles() {
  transition:
    transform .3s ease-in-out,
    opacity .2s linear;
}

@mixin active_styles() {
  opacity: 1;
  pointer-events: all;
  transform: translateY(0);
}

@mixin disabled_styles() {
  pointer-events: none;
  opacity: 0;
  transform: translateY(-20px);
}

@mixin u_onHover_reveal($suffix:'') {

  .u-onHover-reveal__target#{$suffix} {
    @include standing_styles();
  }

  .u-onHover-reveal#{$suffix} {
    .u-onHover-reveal__target#{$suffix},
    & + .u-onHover-reveal__target#{$suffix} {
      @include disabled_styles();
    }
  }

  .u-onHover-reveal#{$suffix}:hover {
    .u-onHover-reveal__target#{$suffix},
    & + .u-onHover-reveal__target#{$suffix} {
      @include active_styles();
    }
  }

  .u-onHover-reveal__target#{$suffix}:hover {
    @include active_styles();
  }
}


@mixin u_onActive_reveal($suffix:'') {

  .u-onActive-reveal__target#{$suffix} {
    @include standing_styles();
  }

  .u-onActive-reveal#{$suffix} {
    .u-onActive-reveal__target#{$suffix},
    & + .u-onActive-reveal__target#{$suffix} {
      @include disabled_styles();
    }
  }

  .u-onActive-reveal#{$suffix}--active {
    .u-onActive-reveal__target#{$suffix},
    & + .u-onActive-reveal__target#{$suffix} {
      @include active_styles();
    }
  }
}

.u-onEvent-reveal--force-show,
.u-onEvent-reveal--force-show-wrap .u-onActive-reveal__target,
.u-onEvent-reveal--force-show-wrap .u-onHover-reveal__target {
  opacity: 1 !important;
  pointer-events: all !important;
  transform: translateY(0) !important;
}


// Create standard classes
// ------------------------------------------

@include u_onHover_reveal();
@include u_onActive_reveal();


// Create MQ extensions
// ------------------------------------------

@each $bp, $mq in $bp-widths-min {
  @media (min-width: #{$mq}) {
    @include u_onHover_reveal(-#{$bp});
    @include u_onActive_reveal(-#{$bp});
  }
}

