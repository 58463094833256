@mixin tachyons_cursors() {
  .cursor-pointer { cursor: pointer; }
  .cursor-zoom { cursor: zoom-in; }
  .cursor-default { cursor: default; }

  .cursor-grab {
    cursor: grab;
    &:active {
      cursor: grabbing;
    }
  }
}


// Create standard classes
// ------------------------------------------

@include tachyons_cursors();

