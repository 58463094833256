// Core variables and mixins
@import "lg-theme-default";
@import "lg-video";

// Clearfix
// .group {
//   *zoom: 1;
// }

.group:before,
.group:after {
  display: table;
  content: "";
  line-height: 0;
}

.group:after {
  clear: both;
}

.lg-object {
  box-shadow: map-get($shadow-depth, 2);
  border-radius: $border-radius-ui;

  overflow: hidden;
}

// lightgallery core
.lg-outer {
  transition: opacity 0.15s ease 0s;

  position: fixed;

  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  z-index: 99998;
  opacity: 0;

  &.lg-visible {
    opacity: 1;
  }

  // Set transition speed and timing function
  &.lg-css3 {
    .lg-item {
      &.lg-prev-slide,
      &.lg-next-slide,
      &.lg-current {
        transition-duration: inherit !important;
        transition-timing-function: inherit !important;
      }
    }
  }

  // Remove transition while dragging
  &.lg-css3.lg-dragging {
    .lg-item {
      &.lg-prev-slide,
      &.lg-next-slide,
      &.lg-current {
        transition-duration: 0s !important;
        opacity: 1;
      }
    }
  }

  // Set cursor grab while dragging
  &.lg-grab {
    img.lg-object {
      cursor: grab;
    }
  }

  &.lg-grabbing {
    img.lg-object {
      cursor: grabbing;
    }
  }

  .lg {
    height: 100%;
    width: 100%;
    position: relative;
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    max-height: 100%;
  }

  .lg-inner {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    white-space: nowrap;
  }

  .lg-item {
    display: none !important;
  }
  &.lg-css3 {
    .lg-prev-slide,
    .lg-current,
    .lg-next-slide {
      display: inline-block !important;
    }
  }
  &.lg-css {
    .lg-current {
      display: inline-block !important;
    }
  }

  .lg-item,
  .lg-img-wrap {
    display: inline-block;
    text-align: center;
    position: absolute;
    width: 100%;
    height: 100%;

    &:before {
      content: "";
      display: inline-block;
      height: 50%;
      width: 1px;
      margin-right: -1px;
    }
  }

  .lg-img-wrap {
    position: absolute;
    padding: 0 5px;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }

  .lg-item {
    &.lg-current {
      z-index: 1060;
    }
  }

  .lg-image {
    display: inline-block;
    vertical-align: middle;
    max-width: 100%;
    max-height: 100%;
    width: auto !important;
    height: auto !important;
  }

  &.lg-show-after-load {
    .lg-item {
      .lg-object,
      .lg-video-play {
        transition: opacity 0.15s ease 0s;

        opacity: 0;
      }

      &.lg-complete {
        .lg-object,
        .lg-video-play {
          opacity: 1;
        }
      }
    }
  }

  // Hide title div if empty
  .lg-empty-html {
    display: none;
  }

  &.lg-hide-download {
    #lg-download {
      display: none;
    }
  }
}

.lg-backdrop {
  transition: opacity 0.3s ease;

  position: relative;

  position: fixed;

  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99997;

  background-color: map-get($color-grey, 10);

  opacity: 0;

  &.in {
    opacity: 0.97;
  }
}

// Loading icon
.lg-loader {
  transition:
    opacity 0.3s linear,
    transform 1s $easing-bounce;

  position: absolute;

  top: 50%;
  left: 50%;

  font-size: 3em;

  margin-left: -0.5em;
  margin-top: -0.5em;

  opacity: 0.75;

  z-index: 100;

  .c-icon {
    display: block;

    color: $color-white;

    animation: spin-clockwise 0.3s linear 0s infinite;
  }

  .lg-complete & {
    opacity: 0;
  }
}

// Default slide animations. Should be placed at the bottom of the animation css
.lg-css3 {
  // Remove all transition effects
  &.lg-no-trans {
    .lg-prev-slide,
    .lg-next-slide,
    .lg-current {
      transition: none 0s ease 0s !important;
    }
  }

  &.lg-use-css3 {
    .lg-item {
      backface-visibility: hidden;
    }
  }

  &.lg-use-left {
    .lg-item {
      backface-visibility: hidden;
    }
  }

  // Fade mode
  &.lg-fade {
    .lg-item {
      opacity: 0;

      &.lg-current {
        opacity: 1;
      }

      // transition timing property and duration will be over written from javascript
      &.lg-prev-slide,
      &.lg-next-slide,
      &.lg-current {
        transition: opacity 0.1s ease 0s;
      }
    }
  }

  &.lg-slide {
    &.lg-use-css3 {
      .lg-item {
        opacity: 0;

        &.lg-prev-slide {
          transform: translate3d(-100%, 0, 0);
        }

        &.lg-next-slide {
          transform: translate3d(100%, 0, 0);
        }

        &.lg-current {
          transform: translate3d(0, 0, 0);
          opacity: 1;
        }

        // transition timing property and duration will be over written from javascript
        &.lg-prev-slide,
        &.lg-next-slide,
        &.lg-current {
          transition:
            transform 1s cubic-bezier(0, 0, 0.25, 1) 0s,
            opacity 0.1s ease 0s;
        }
      }
    }

    &.lg-use-left {
      .lg-item {
        opacity: 0;
        position: absolute;
        left: 0;

        &.lg-prev-slide {
          left: -100%;
        }

        &.lg-next-slide {
          left: 100%;
        }

        &.lg-current {
          left: 0;
          opacity: 1;
        }

        // transition timing property and duration will be over written from javascript
        &.lg-prev-slide,
        &.lg-next-slide,
        &.lg-current {
          transition:
            left 1s cubic-bezier(0, 0, 0.25, 1) 0s,
            opacity 0.1s ease 0s;
        }
      }
    }
  }
}
