/*

  base/fonts.scss - apply fonts on load

*/


@import 'globals';

// Apply fonts on load
.wf-active {
  body,
  input,
  textarea,
  optgroup,
  select,
  button {
    font-family: $font-family, 'Helvetica Neue', 'Helvetica', sans-serif;
  }
}

