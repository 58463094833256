// Base element
// ------------------------------------------

.c-link {
  @include c_link();
}

.c-link--dark {
  @include c_link__dark();
}

.c-link--light {
  @include c_link__light();
}