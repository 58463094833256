@mixin tachyons_border_greys_white_transparent($suffix:'') {
  // White, Black & Transparent
  .color-border-white#{$suffix} { border-color: $color-white; }
  .color-border-black#{$suffix} { border-color: $color-black; }
  .color-border-transparent#{$suffix} { border-color: transparent; }

  // Grey
  @each $pos, $color in $color-grey {
    .color-border-grey-#{$pos}#{$suffix} { border-color: #{$color}; }
    
    .color-border-onHover-grey-#{$pos}#{$suffix}:hover {
      &.color-border-onHover-grey-#{$pos}__target,
      .color-border-onHover-grey-#{$pos}__target {
        border-color: #{$color};
      }
    }

    .t-active-wrap .js-color-border.color-border-active-grey-#{$pos}#{$suffix} {
      border-color: #{$color};
    }
  }
}


@mixin tachyons_border_colors($suffix:'') {
  // Logo (Brand) colors
  @each $label, $color in $color-logo {
    .color-border-logo-#{$label}#{$suffix} { border-color: #{$color}; }

    .color-border-onHover-logo-#{$label}#{$suffix}:hover {
      &.color-border-onHover-logo-#{$label}__target,
      .color-border-onHover-logo-#{$label}__target {
        border-color: #{$color};
      }
    }
  }

  // Hexicon colors
  @each $label, $color in $color-hexicon {
    .color-border-hexicon-#{$label}#{$suffix} { border-color: #{$color}; }

    .color-border-onHover-hexicon-#{$label}#{$suffix}:hover {
      &.color-border-onHover-hexicon-#{$label}__target,
      .color-border-onHover-hexicon-#{$label}__target {
        border-color: #{$color};
      }
    }
  }

  // App (Axiom) colors
  @each $label, $color in $color-app {
    .color-border-app-#{$label}#{$suffix} { border-color: #{$color}; }
  }

  // UI colors
  @each $label, $color in $color-ui {
    .color-border-ui-#{$label}#{$suffix} { border-color: #{$color}; }
  }

  // Buzz colors
  @each $label, $color in $color-buzz {
    .color-border-buzz-#{$label}#{$suffix} { border-color: #{$color}; }
  }

  // Product colors
  @each $label, $color in $color-product {
    .color-border-product-#{$label}#{$suffix} { border-color: #{$color}; }
  }

  
}

