@mixin tachyons_text_align($suffix:'') {
  .tl#{$suffix} { text-align: left; }
  .tr#{$suffix} { text-align: right; }
  .tc#{$suffix} { text-align: center; }
}


// Create standard classes
// ------------------------------------------

@include tachyons_text_align();



// Create MQ extensions
// ------------------------------------------

@each $bp, $mq in $bp-widths-min {
    @media (min-width: #{$mq}) {
      @include tachyons_text_align(-#{$bp});
    }
}

