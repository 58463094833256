@mixin tachyons_bg_size($suffix:'') {
  .bg-cover#{$suffix} { background-size: cover; }
  .bg-contain#{$suffix} { background-size: contain; }
}


// Create standard classes
// ------------------------------------------

@include tachyons_bg_size();



// Create MQ extensions
// ------------------------------------------

@each $bp, $mq in $bp-widths-min {
    @media (min-width: #{$mq}) {              
      @include tachyons_bg_size(-#{$bp});
    }
}