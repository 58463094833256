@mixin tachyons_bg_greys_and_white($suffix:'', $hover: true, $active: true) {

  // White, Black & Transparent
  .color-bg-white#{$suffix} { background-color: $color-white }
  .color-bg-black#{$suffix} { background-color: $color-black }
  .color-bg-transparent#{$suffix} { background-color: transparent }

  @if $hover {
    .color-bg-onHover-transparent#{$suffix}:hover {
      &.color-bg-onHover-transparent__target,
      .color-bg-onHover-transparent__target {
        background-color: transparent;
      }
    }
  }

  @if $active {
    .t-active-wrap .color-bg-transparent#{$suffix}--t-active {
      background-color: transparent;
    }
  }


  // Grey
  @each $pos, $color in $color-grey {
    .color-bg-grey-#{$pos}#{$suffix} { background-color: #{$color}; }

    @if $hover {
      .color-bg-onHover-grey-#{$pos}#{$suffix}:hover {
        &.color-bg-onHover-grey-#{$pos}__target,
        .color-bg-onHover-grey-#{$pos}__target {
          background-color: #{$color};
        }
      }
    }

    @if $active {
      .color-bg-grey-#{$pos}--active {
        background-color: #{$color};
      }

      .t-active-wrap .color-bg-grey-#{$pos}#{$suffix}--t-active {
        background-color: #{$color};
      }
    }
  }
}

@mixin tachyons_bg_colors($suffix:'', $hover: true) {

  // Logo (Brand) colors
  @each $label, $color in $color-logo {
    .color-bg-logo-#{$label}#{$suffix} { background-color: #{$color}; }

    @if $hover {
      .color-bg-onHover-logo-#{$label}#{$suffix}:hover {
        &.color-bg-onHover-logo-#{$label}__target,
        .color-bg-onHover-logo-#{$label}__target {
          background-color: #{$color};
        }
      }
    }
  }

  // Hexicon colors
  @each $label, $color in $color-hexicon {
    .color-bg-hexicon-#{$label}#{$suffix} { background-color: #{$color}; }

    @if $hover {
      .color-bg-onHover-hexicon-#{$label}#{$suffix}:hover {
        &.color-bg-onHover-hexicon-#{$label}__target,
        .color-bg-onHover-hexicon-#{$label}__target {
          background-color: #{$color};
        }
      }
    }
  }

  // App (Axiom) colors
  @each $label, $color in $color-app {
    .color-bg-app-#{$label}#{$suffix} { background-color: #{$color}; }
  }

  // UI colors
  @each $label, $color in $color-ui {
    .color-bg-ui-#{$label}#{$suffix} { background-color: #{$color}; }
  }

  // Social colors
  @each $label, $color in $color-social {
    .color-bg-social-#{$label}#{$suffix} { background-color: #{$color}; }
  }

  // Buzz colors
  @each $label, $color in $color-buzz {
    .color-bg-buzz-#{$label}#{$suffix} { background-color: #{$color}; }
  }

  // Product colors
  @each $label, $color in $color-product {
    .color-bg-product-#{$label}#{$suffix} { background-color: #{$color}; }
  }
}

