@mixin tachyons_font_weight($suffix:'') {
  @each $pos, $weight in $font-weight {
    .fw#{$pos}#{$suffix} { font-weight: #{$weight}; }
  }
}


// Create standard classes
// ------------------------------------------

@include tachyons_font_weight();



// Create MQ extensions
// ------------------------------------------

@each $bp, $mq in $bp-widths-min {
    @media (min-width: #{$mq}) {
      @include tachyons_font_weight(-#{$bp});
    }
}

