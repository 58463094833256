.u-legibility-gradient--bottom-left {
  background-image:
    radial-gradient(
      circle at bottom left,
      rgba(0,0,0,0.9),
      rgba(0,0,0,0.5) 34%,
      rgba(0,0,0,0) 70%,

    );
}

.u-legibility-text-shadow-dark {
  text-shadow: 5px 5px 200px rgba(0,0,0,0.5);
}

