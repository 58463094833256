@mixin tachyons_text_greys_and_white($suffix:'') {
  .color-text-white#{$suffix} { color: $color-white }
  @each $pos, $color in $color-grey {
    .color-text-grey-#{$pos}#{$suffix} { color: #{$color}; }
  }
}

@mixin tachyons_text_colors($suffix:'') {
  @each $label, $color in $color-logo {
    .color-text-logo-#{$label}#{$suffix} { color: #{$color}; }
  }
  @each $label, $color in $color-hexicon {
    .color-text-hexicon-#{$label}#{$suffix} { color: #{$color}; }
  }
  @each $label, $color in $color-app {
    .color-text-app-#{$label}#{$suffix} { color: #{$color}; }
  }
  @each $label, $color in $color-ui {
    .color-text-ui-#{$label}#{$suffix} { color: #{$color}; }
  }
  @each $label, $color in $color-social {
    .color-text-social-#{$label}#{$suffix} { color: #{$color}; }
  }
  @each $label, $color in $color-buzz {
    .color-text-buzz-#{$label}#{$suffix} { color: #{$color}; }
  }
  @each $label, $color in $color-product {
    .color-text-product-#{$label}#{$suffix} { color: #{$color}; }
  }
}
