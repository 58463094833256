@import './c-link--mixins.scss'; // When nesting a mixin within a mixin, need to import in the same file Pete/Paolo Jan 2020

// Base element
// ------------------------------------------

@mixin c_copy() {
  font-style: normal;
  font-weight: map-get($font-weight, 400);

  line-height: map-get($line-height, "regular");
  letter-spacing: map-get($letter-spacing, "regular");

  color: map-get($color-grey, 6);

  strong {
    font-weight: map-get($font-weight, 600);
  }

  a {
    @include c_link;
  }
}

@mixin c_copy__bottom_medium() {
  margin-bottom: 1.1em;
}


// Color modes
// ------------------------------------------

@mixin c_copy__dark() {
  color: map-get($color-grey, 4);

  text-shadow: $text-shadow;

  strong {
    color: map-get($color-grey, 1);
  }

  a {
    @include c_link__dark;
  }
}


// Light mode
// ------------------------------------------

@mixin c_copy__light() {
  color: map-get($color-grey, 6);

  text-shadow: none;

  a {
    @include c_link__light;
  }
}

// Light mode
// ------------------------------------------

@mixin c_copy__inherit() {
  a {
    @include c_link__inherit;
  }
}