/*

  components/btns/c-btn-round.scss

  Our round buttons, they have primary color styles as well as secondary + contrast options.
  The base class alone isn't enough, be sure to specify a modifier as well.

  Examples:
    c-btn-round c-btn-round--primary-ui | Default size, primary UI style.
    c-btn-round c-btn-round--primary-ui c-btn-round--1 c-btn-round--3-md | Start small, become bigger at md breakpoint

  Media Query Extensions: Yes

*/

// Base
// ------------------------------------------

.c-btn-round {
  @include touch_optimize();

  display: inline-block;
  vertical-align: middle;

  position: relative;

  font-size: prem(80px);

  border-radius: 50%;

  height: 1em;
  width: 1em;

  user-select: none;
  cursor: pointer;

  padding: 0;


  // Els used to create background grow and pulse effects
  .c-btn__color,
  .c-btn__color-mix {
    transition:
      background-color 0.2s ease,
      border-color 0.2s ease,
      transform 0.4s $easing-bounce,
      color 0.2s ease;

    position: absolute;

    border-radius: 100%;
    border-width: map-get($border-width, 1);
    border-style: solid;

    top: 0;
    left: 0;

    width: 100%;
    height: 100%;
  }

  .c-btn__color {
    z-index: 1;
  }

  &:hover {
    .c-btn__color,
    .c-btn__color-mix {
      transform: scale(1.3);
    }
  }

  &:active {
    .c-btn__color,
    .c-btn__color-mix {
      transform: scale(1);
    }
  }

  .c-btn-round__icon-wrap {
    position: absolute;

    top: 50%;
    left: 50%;

    font-size: .3em;

    margin-left: -.5em;
    margin-top: -.5em;

    z-index: 3;
  }

  &[disabled] {

    // Disable actions on disabled buttons
    pointer-events: none;
  }
}


// Primary UI
// ------------------------------------------

.c-btn-round--primary-ui {
  color: $color-white;
}


// Primary disabled state
// ------------------------------------------

.c-btn-round--primary-ui {
  &[disabled] {
    opacity: 0.5;
  }
}


// Secondary style contrast variants
// ------------------------------------------

.c-btn-round--secondary-light {
  color: map-get($color-grey, 7);

  &:hover .c-btn__color {
    border-color: map-get($color-grey, 7);
  }

  &[disabled] {
    color: map-get($color-grey, 6);
    opacity: .4;
  }
}

.c-btn-round--secondary-dark {
  color: map-get($color-grey, 3);

  &:hover .c-btn__color {
    border-color: map-get($color-grey, 3);
  }

  &[disabled] {
    opacity: .3;
  }
}


// Pulse modifier
// ------------------------------------------
.c-btn-round--pulse {

  // Only run animation on non disabled instances
  &:not(:disabled) .c-btn__color {
    content: "";

    z-index: 1;

    animation-name: pulse;
    animation-duration: 4s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-out;
  }
}


// Animation
// ------------------------------------------

.c-btn-round--animate-left-right {
  &:not(:disabled):hover {
    .c-icon {
      animation-timing-function: $easing-bounce;
      animation-duration: .5s;
      animation-name: in-out-left-right;
    }
  }
}

.c-btn-round--animate-top-bottom {
  &:not(:disabled):hover {
    .c-icon {
      animation-timing-function: $easing-bounce;
      animation-duration: .5s;
      animation-name: in-out-top-bottom;
    }
  }
}


// Size variants
// ------------------------------------------

@mixin c_btn_round_sizes($suffix:'') {

  .c-btn-round--1#{$suffix} {
    font-size: prem(65px);
  }

  .c-btn-round--2#{$suffix} {
    font-size: prem(80px);
  }

  .c-btn-round--3#{$suffix} {
    font-size: prem(105px);
  }
}


// Create standard size
// ------------------------------------------

@include c_btn_round_sizes();


// Create MQ size extensions
// ------------------------------------------

@each $bp, $mq in $bp-widths-min {
  @media (min-width: #{$mq}) { 
    @include c_btn_round_sizes(-#{$bp});             
  }
}