.c-navsite--logo {
  width: 137px;

  @media (min-width: bp-width-min('lg-xl')) {
    width: 142px;
  }

  svg {
    width: 100%;
    height: auto;
  }
}
