
// Create standard classes
// ------------------------------------------

@include tachyons_type_scale();



// Create MQ extensions
// ------------------------------------------

@each $bp, $mq in $bp-widths-min {
  @media (min-width: #{$mq}) {
    @include tachyons_type_scale(-#{$bp});
  }
}

