@mixin tachyons_typography_general($suffix:'') {
  // Measure is limited to ~66 characters
  .measure#{$suffix} {
    max-width: 37em;
  }

  // Measure is limited to ~80 characters
  .measure-wide#{$suffix} {
    max-width: 42em;
  }

  .measure-slim#{$suffix} {
    max-width: 26em;
  }

  // Measure is limited to ~45 characters
  .measure-narrow#{$suffix} {
    max-width: 20em;
  }

  .measure-cramped#{$suffix} {
    max-width: 12em;
  }

  // Book paragraph style - paragraphs are indented with no vertical spacing.
  // .indent#{$suffix} {
  //   text-indent: 1em;
  //   margin-top: 0;
  //   margin-bottom: 0;
  // }

  // Combine this class with a width to truncate text (or just leave as is to truncate at width of containing element.
  .truncate#{$suffix} {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}


// Create standard classes
// ------------------------------------------

@include tachyons_typography_general();



// Create MQ extensions
// ------------------------------------------

@each $bp, $mq in $bp-widths-min {
    @media (min-width: #{$mq}) {
      @include tachyons_typography_general(-#{$bp});
    }
}

