/*

  layout/l-page-gutter.scss

  Media Query Extensions: Yes

*/

// Page gutter
// ------------------------------------------------------

@mixin l_page_gutter($suffix:'') {

  .l-page-gutter-l#{$suffix} {

    padding-left: map-get($layout-scale-rem, 4);

    @media (min-width: bp-width-min(sm)) {
      padding-left: map-get($layout-scale-rem, 7);
    }

    @media (min-width: bp-width-min(lg)) {
      padding-left: (map-get($layout-scale-rem, 7) + map-get($layout-scale-rem, 4));
    }

    @media (min-width: bp-width-min(xl)) {
      padding-left: map-get($layout-scale-rem, 8);
    }
  }

  .l-page-gutter-r#{$suffix} {

    padding-right: map-get($layout-scale-rem, 4);

    @media (min-width: bp-width-min(sm)) {
      padding-right: map-get($layout-scale-rem, 7);
    }

    @media (min-width: bp-width-min(lg)) {
      padding-right: (map-get($layout-scale-rem, 7) + map-get($layout-scale-rem, 4));
    }

    @media (min-width: bp-width-min(xl)) {
      padding-right: map-get($layout-scale-rem, 8);
    }
  }

  .l-page-gutter#{$suffix} {

    padding-left: map-get($layout-scale-rem, 4);
    padding-right: map-get($layout-scale-rem, 4);

    @media (min-width: bp-width-min(sm)) {
      padding-left: map-get($layout-scale-rem, 7);
      padding-right: map-get($layout-scale-rem, 7);
    }

    @media (min-width: bp-width-min(lg)) {
      padding-left: (map-get($layout-scale-rem, 7) + map-get($layout-scale-rem, 4));
      padding-right: (map-get($layout-scale-rem, 7) + map-get($layout-scale-rem, 4));
    }

    @media (min-width: bp-width-min(xl)) {
      padding-left: map-get($layout-scale-rem, 8);
      padding-right: map-get($layout-scale-rem, 8);
    }
  }

  .l-page-gutter-r--disabled#{$suffix} {
    padding-right: 0;
  }

  .l-page-gutter-l--disabled#{$suffix} {
    padding-left: 0;
  }

  .l-page-gutter--disabled#{$suffix} {
    padding-left: 0;
    padding-right: 0;
  }

  .l-page-gutter-article#{$suffix} {

    padding-left: map-get($layout-scale-rem, 4);
    padding-right: map-get($layout-scale-rem, 4);

    @media (min-width: bp-width-min(sm)) {
      padding-left: map-get($layout-scale-rem, 7);
      padding-right: map-get($layout-scale-rem, 7);
    }
  }

  .l-page-gutter-article--disabled#{$suffix} {
    padding-left: 0;
    padding-right: 0;
  }
}

// Create standard (size/whitespace) classes
// ------------------------------------------

@include l_page_gutter();



// Create MQ (size/whitespace) extensions
// ------------------------------------------

@each $bp, $mq in $bp-widths-min {
  @media (min-width: #{$mq}) {
    @include l_page_gutter(-#{$bp});
  }
}

