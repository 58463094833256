
.c-csrg-graphic {}

.c-csrg-graphic__inner {
  position: relative;
  max-width: 77%;
}

.c-csrg-graphic__image-holder {
  position: relative;
  z-index: 2;
}

.c-csrg-graphic__psuedo-page-holder {
  right: -30%;

  width: 80%;

  @extend .absolute--center-y;
  z-index: 0;
}

.c-csrg-graphic__psuedo-page {
  padding-bottom: 124%;

  background-color: #f6f5f6;


  &::after {
    content: '';
    display: block;

    position: absolute;
    top: 0;
    bottom: 0;
    width: 28%;
    right: 11%;

    opacity: 0.1;

    background: linear-gradient(to right, rgba($color-shadow,0.6) 0%,rgba($color-shadow,0) 50%);
  }
}

.c-csrg-graphic__svg-holder {
  position: absolute;

  right: 10%;
  top: 15%;

  width: 50%;
}

