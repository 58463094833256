/*
  dirty.scss

  Keep this file as short as possible! Ideally only use it when
  you’re not sure where to put something and want to discuss that
  with someone else!
*/



// x-sticky-nav-wrapper
// -------------------------------------
// "Unstick" the sticky nav when viewport height is shallow, for UX reasons
// Need to also "unstick" the growl on buzzsumo

#x-sticky-nav-wrapper {
  @media (min-width: bp-width-min('lg')) and (max-height: 700px) {
    position: relative !important;
    top: 0 !important; 
  }
}

#x-sticky-growl-wrapper {
    position: fixed !important;
}
