/*

  components/btns/c-btn.scss

  Our primary and secondary buttons.

  Media Query Extensions: Yes

*/

@include c_btn;


// Create standard size
// ------------------------------------------
@include c_btn_sizes();


// Create MQ size extensions
// ------------------------------------------
@each $bp, $mq in $bp-widths-min {
  @media (min-width: #{$mq}) {
    @include c_btn_sizes(-#{$bp});
  }
}


// Create standard classes
// ------------------------------------------
@include c-btn-border-radius-disable();

// Create MQ extensions
// ------------------------------------------
@each $bp, $mq in $bp-widths-min {
    @media (min-width: #{$mq}) {
      @include c-btn-border-radius-disable(-#{$bp});
    }
}

