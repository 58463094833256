
// Display
// ------------------------------------------

.tff-display-block {
  .t-active-wrap &,
  &.tff-display-block--active {
    display: block !important; // overrides tachyons and inline styles
  }
}


// Opacity
// ------------------------------------------

.tff-opacity-1 {
  transition-property: opacity;
  transition-duration: $t-default-duration;
  .t-active-wrap &,
  &.tff-opacity-1--active {
    opacity: 1 !important; // overrides tachyons and inline styles
  }
}

.tff-opacity-0 {
  transition-property: opacity;
  transition-duration: $t-default-duration;
  .t-active-wrap &,
  &.tff-opacity-0--active {
    opacity: 0 !important; // overrides tachyons and inline styles
  }
}

// Color (currently just to grey-10)
// ------------------------------------------

.tff-color-text-grey-10 {
  transition-property: color;
  transition-duration: $t-default-duration;
  .t-active-wrap &,
  &.tff-color-text-grey-10--active {
    color: map-get($color-grey, 10) !important; // overrides tachyons and inline styles
  }
}


// Scale
// ------------------------------------------

.tff-scale-1 {
  transition: transform;
  transition-duration: $t-scale-default-duration;
  transition-timing-function: $easing-smooth;
  .t-active-wrap &,
  &.tff-scale-1--active {
    transform: scale(1) !important; // overrides tachyons and inline styles
  }
}



// Translate3D
// ------------------------------------------

@mixin tff3d($suffix:'') {
  $t-move-default-duration: 2s;

  .tff-translate3d-000#{$suffix} {
    transition: transform;
    transition-duration: $t-move-default-duration;
    transition-timing-function: $easing-fastInSlowOut;

    .t-active-wrap &,
    &.tff-translate3d-000#{$suffix}--active {
      transform: translate3d(0,0,0) !important; // allows custom setting via style attribute
    }
  }
}

// Create standard classes
@include tff3d();


// Create MQ extensions
@each $bp, $mq in $bp-widths-min {
  @media (min-width: #{$mq}) {
    @include tff3d(-#{$bp});
  }
}

