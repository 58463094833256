@mixin tachyons_overflow($suffix:'') {
  .overflow-visible#{$suffix} { overflow: visible; }
  .overflow-hidden#{$suffix} { overflow: hidden; }
  .overflow-scroll#{$suffix} { overflow: scroll; }
  .overflow-auto#{$suffix} { overflow: auto; }

  .overflow-x-visible#{$suffix} { overflow-x: visible; }
  .overflow-x-hidden#{$suffix} { overflow-x: hidden; }
  .overflow-x-scroll#{$suffix} { overflow-x: scroll; }
  .overflow-x-auto#{$suffix} { overflow-x: auto; }

  // .overflow-y-visible#{$suffix} { overflow-y: visible; }
  // .overflow-y-hidden#{$suffix} { overflow-y: hidden; }
  .overflow-y-scroll#{$suffix} { overflow-y: scroll; }
  // .overflow-y-auto#{$suffix} { overflow-y: auto; }
}


// Create standard classes
// ------------------------------------------

@include tachyons_overflow();



// Create MQ extensions
// ------------------------------------------

@each $bp, $mq in $bp-widths-min {
    @media (min-width: #{$mq}) {
      @include tachyons_overflow(-#{$bp});
    }
}

