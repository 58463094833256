/*

  mixins/common-form-styles.scss

  Shared styles between form elements

*/


// Base styles (inputs, textarea etc)
// ------------------------------------------

@mixin base_form_styles() {
  transition: border-color .2s linear;
  width: 100%;

  background-color: $color-white;

  padding: .6em 1em; // taken from c-btn x-axis (normal size)

  line-height: map-get($line-height, "regular");

  color: map-get($color-grey, 10);

  border-radius: $border-radius-ui;
  border-style: solid;
  border-width: map-get($border-width, 1);
  border-color: map-get($color-grey, 4);

  // when touch device inherit doc font size
  // to prevent device zooming on inputs.
  font-size: map-get($type-scale-rem, 3);
  html.is-touch & {
    font-size: inherit;
  }

  body.rounded-ui & {
    border-radius: $border-radius-ui-charcoal;
  }

  @include style_placeholder {
    color: map-get($color-grey, 5);
  }
}


// Disabled style
// ------------------------------------------

@mixin disabled_input_style() {
  opacity: 0.5;
  color: map-get($color-grey, 4);

  border-color: map-get($color-grey, 3);
  background-color: map-get($color-grey, 2);

  // Don't show pointer when disabled
  cursor: default;

  @include style_placeholder {
    color: map-get($color-grey, 4);
  }
}

// Field focus style
// ------------------------------------------

@mixin field_focus_style() {
  border-color: map-get($color-ui, "primary");
}


// Field error style
// ------------------------------------------

@mixin field_error_style() {
  border-color: map-get($color-ui, "error");
  background-color: lighten(map-get($color-ui, "error"), 34%);
}

// Field error style
// ------------------------------------------

@mixin field_validation_ok_style() {
  border-color: map-get($color-ui, "success"); // possibly should 'page-color' rather ui-success
}

