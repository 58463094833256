/*

  components/form/c-textarea.scss

  A textarea

*/

.c-textarea {
  @include base_form_styles;
  @include touch_optimize;

  // Prevent X growth breaking layout container
  resize: vertical;

  min-height: 10em;

  &[disabled] {
    @include disabled_input_style;
  }

  &:not(.c-textarea--error):focus {
    @include field_focus_style;
  }
}

