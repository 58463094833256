@mixin tachyons_text_transform($suffix:'') {
  .ttc#{$suffix} { text-transform: capitalize; }
  .ttl#{$suffix} { text-transform: lowercase; }
  .ttu#{$suffix} { text-transform: uppercase; }
  .ttn#{$suffix} { text-transform: none; }
}


// Create standard classes
// ------------------------------------------

@include tachyons_text_transform();



// Create MQ extensions
// ------------------------------------------

// @each $bp, $mq in $bp-widths-min {
//     @media (min-width: #{$mq}) {
//       @include tachyons_text_transform(-#{$bp});
//     }
// }

