@mixin object-hover() {
  position: relative;

  transition: transform 1.5s $easing-fastInSlowOut;

  &::before {
    pointer-events: none;
    content: '';
    display: block;
    position: absolute;

    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    opacity: 0;

    transition: opacity 1.5s $easing-fastInSlowOut;

    border-radius: $border-radius-ui;

    // box-shadow: map-get($shadow-depth, 3);
    // box-shadow: 0px 10px 14px 0px rgba(23,43,77,0.2);

    // .shadow-fr#{$suffix} {
      // box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 0px, rgba(0, 0, 0, 0.05) 0px 2px 6px, rgba(0, 0, 0, 0.05) 0px 10px 20px;
    // }

    // .shadow-atlassian#{$suffix} {
      box-shadow: 0px 10px 14px 0px rgba(23,43,77,0.2);
    // }
  }

  &:hover,
  &--active {
    transform: translate3d(0,-.25rem,0);

    &:before {
      opacity: 1;
    }
  }

  &--br-100 {
    &::before {
      border-radius: 100%;
    }
  }

  &--br-ui {
    &::before {
      border-radius: $border-radius-ui;
    }
  }

  &--br-ui-charcoal {
    &::before {
      border-radius: $border-radius-ui-charcoal;
    }
  }
}


@mixin u-object-hovers($suffix:'') {
  .u-object-hover#{$suffix} {
    @include object-hover();
  }

  .u-object-hover-pressable#{$suffix} {
    @include object-hover();

    &:active {
      transform: translate3d(0,0.1rem,0) scale(0.98);
    }
  }
}


// Create standard classes
// ------------------------------------------

@include u-object-hovers();


// Create MQ extensions
// ------------------------------------------

@each $bp, $mq in $bp-widths-min {
  @media (min-width: #{$mq}) {
    @include u-object-hovers(-#{$bp});
  }
}

