// Floating Asset Border Radius
// ------------------------------------------

.c-asset-border-radius {
  border-radius: 3px;
  @media (min-width: bp-width-min('md')) {
    border-radius: 5px;
  }

  .c-asset-border-radius--always-sm & {
    border-radius: 3px;
  }
}

