@mixin tachyons_type_scale($suffix:'', $rem: true) {
  .fs-inherit#{$suffix} { font-size: inherit; }

  .fs-07em#{$suffix}  { font-size: 0.7em; }
  .fs-08em#{$suffix}  { font-size: 0.8em; }
  .fs-083em#{$suffix} { font-size: 0.83em; }
  .fs-09em#{$suffix}  { font-size: 0.9em; }
  .fs-1em#{$suffix}   { font-size: 1em;   }
  .fs-1p1em#{$suffix} { font-size: 1.1em; }

  @if $rem {
    @each $pos, $size in $type-scale-rem {
      .f#{$pos}#{$suffix} { font-size: #{$size}; }
    }
  } @else {
    @each $pos, $size in $type-scale-px {
      .f#{$pos}#{$suffix} { font-size: #{$size}; }
    }
  }

}
