@mixin tachyons_border_style($suffix:'') {
  .b--dotted#{$suffix} { border-style: dotted; }
  .b--dashed#{$suffix} { border-style: dashed; }
  .b--solid#{$suffix} {  border-style: solid; }
  .b--none#{$suffix} {   border-style: none; }
}

// Create standard classes
// ------------------------------------------

@include tachyons_border_style();


// Create MQ extensions
// ------------------------------------------

// @each $bp, $mq in $bp-widths-min {
//   @media (min-width: #{$mq}) {
//     @include tachyons_border_style($suffix: -#{$bp});
//   }
// }





