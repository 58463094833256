.c-blockquote {
  @include c_blockquote();
}

.c-blockquote--dark {
  @include c_blockquote__dark();
}

.c-blockquote--light {
  @include c_blockquote__light();
}


// Size variants
// ------------------------------------------

.c-blockquote--1 {
  @extend .c-copy--1;
}

.c-blockquote--2 {
  @extend .c-copy--2;
}

.c-blockquote--3 {
  @extend .c-copy--3;
}

.c-blockquote--article {
 @extend .c-copy--article;
}
