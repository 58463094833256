/*

  components/form/c-form-subheading.scss

  Subheading for sub groups of form fields

*/

.c-form-subheading {
  @extend .c-lead;
  @extend .f4;

  border-bottom: map-get($border-width, 1) solid map-get($color-grey, 4);

  padding-bottom: .75em;

  margin-bottom: 1em;

  &:not(:first-child) {
    padding-top: 1em;
  }
}