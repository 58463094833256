@mixin tachyons_aspect_ratio($suffix:'') {

  .aspect-ratio--21x9#{$suffix} { padding-bottom: 42.86%; }
  .aspect-ratio--16x10#{$suffix} { padding-bottom: 62.5%; } // Laptop/MBP Screen Ratio
  .aspect-ratio--16x9#{$suffix} { padding-bottom: 56.25%; }
  .aspect-ratio--4x3#{$suffix}  {  padding-bottom: 75%;   }
  .aspect-ratio--6x4#{$suffix}  {  padding-bottom: 66.6%; }
  .aspect-ratio--1x1#{$suffix}  {  padding-bottom: 100%;  }
  .aspect-ratio--disabled#{$suffix} {  padding-bottom: 0; }
  .aspect-ratio--2x1#{$suffix}  {  padding-bottom: 50%;   }

}


// Create standard classes
// ------------------------------------------

@include tachyons_aspect_ratio();


// Create MQ extensions
// ------------------------------------------

@each $bp, $mq in $bp-widths-min {
    @media (min-width: #{$mq}) {
      @include tachyons_aspect_ratio(-#{$bp});
    }
}

