@mixin tachyons_stroke_greys_and_white($suffix:'') {
  .color-stroke-white#{$suffix} { stroke: $color-white }
  @each $pos, $color in $color-grey {
    .color-stroke-grey-#{$pos}#{$suffix} { stroke: #{$color}; }
  }
}

@mixin tachyons_stroke_colors($suffix:'') {
  @each $label, $color in $color-logo {
    .color-stroke-logo-#{$label}#{$suffix} { stroke: #{$color}; }
  }
  @each $label, $color in $color-hexicon {
    .color-stroke-hexicon-#{$label}#{$suffix} { stroke: #{$color}; }
  }
  @each $label, $color in $color-app {
    .color-stroke-app-#{$label}#{$suffix} { stroke: #{$color}; }
  }
  @each $label, $color in $color-ui {
    .color-stroke-ui-#{$label}#{$suffix} { stroke: #{$color}; }
  }
  @each $label, $color in $color-buzz {
    .color-stroke-buzz-#{$label}#{$suffix} { stroke: #{$color}; }
  }
  @each $label, $color in $color-product {
    .color-stroke-product-#{$label}#{$suffix} { stroke: #{$color}; }
  }
}


// Create standard classes
// ------------------------------------------

@include tachyons_stroke_greys_and_white();
@include tachyons_stroke_colors();



// Create MQ extensions
// ------------------------------------------

@each $bp, $mq in $bp-widths-min {
    @media (min-width: #{$mq}) {
      @include tachyons_stroke_greys_and_white(-#{$bp});
    }
}

