
// Create standard classes
// ------------------------------------------

@include tachyons_borders_basic();
@include tachyons_borders_advanced();


// Create MQ extensions
// ------------------------------------------

@each $bp, $mq in $bp-widths-min {
  @media (min-width: #{$mq}) {
    @include tachyons_borders_basic($suffix: -#{$bp});
    @include tachyons_borders_advanced($suffix: -#{$bp});
  }
}

