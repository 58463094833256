.c-shadow-bottom {
  position: relative;

  // Overlay gradient
  &:after {
    content: "";

    position: absolute;

    pointer-events: none;

    width: 100%;

    height: 17rem;

    bottom: 0;
    left: 0;

    background: linear-gradient(to bottom, rgba($color-shadow, 0) 0%, rgba($color-shadow, .04) 100%);

    z-index: 3;
  }
}

