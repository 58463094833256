/*

  components/c-simple-link.scss

  A simple fade link for simple navigation

*/

// Light
.c-simple-link-light {
  transition: color .05s linear;

  color: map-get($color-grey, 5);

  &:hover {
    color: map-get($color-grey, 10);
  }
}

// Variant - Light higher contrast
.c-simple-link-light--high-contrast {
  color: map-get($color-grey, 6);
}

// Dark
.c-simple-link-dark {
  transition: color .05s linear;

  color: map-get($color-grey, 5);

  &:hover {
    color: $color-white;
  }
}

// Variant - Dark higher contrast
.c-simple-link-dark--high-contrast {
  color: map-get($color-grey, 6);
}