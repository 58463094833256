#bw-buzz-active-campaign-form-wrapper {
  // Has to be an ID for specificty unfortunately

  @extend .pa4;
  @extend .pa5-lg;
  @extend .shadow-atlassian;
  @extend .br-ui-charcoal;
  background-color: white;

  form {
    width: 100% !important;
    padding: 0 !important;
    margin: 0 !important;
  }

  input[type="text"] {
    @extend .c-input;

    padding: 0.6em 1em !important;
    border-color: #C6C7CC !important;
    margin-bottom: 1em !important;
  }

  ._row._checkbox-radio > span label {
    margin-left: 20px;
    cursor: pointer;

    &::before {
      color: white;
      cursor: pointer;
      margin-left: -20px;
      margin-right: 6px;

      padding: 0.05em 0.3em;
      content: '✔';
      width: 15px;
      height: 15px;
      border: 2px solid #C6C7CC;
      border-radius: 2px;
    }
  }

  ._row._checkbox-radio input:checked + span label::before {
    background-color: #3b79cc;
  }

  button._submit {
    background-color: #3b79cc !important;
    border-radius: 6px !important;
    padding: .9em 1.75em !important;
    font-weight: 700 !important;
  }
}

