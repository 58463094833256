// Create standard classes
// ------------------------------------------

@include tachyons_bg_greys_and_white();
@include tachyons_bg_colors();



// Create MQ extensions
// ------------------------------------------

@each $bp, $mq in $bp-widths-min {
    @media (min-width: #{$mq}) {
      @include tachyons_bg_greys_and_white(-#{$bp});
    }
}