@mixin c_tertiary {
  text-transform: uppercase;
  letter-spacing: map-get($letter-spacing, "loose");

  line-height: map-get($line-height, "tight");

  font-weight: map-get($font-weight, 600);
  font-style: normal;

  color: map-get($color-grey, 5);
}

@mixin c_tertiary__dark {
  color: map-get($color-grey, 4);

  text-shadow: $text-shadow;
}

