/*

  components/cards/c-contact-card.scss

*/


// c-contact-card
// ------------------------------------------

.c-contact-card {
  position: relative;
  display: flex;

  flex: 1 0 0%;

  width: 100%;

  // IE 11 Hack - Removes flexibility of cards in favor of correct centering (No better solution possible without static widths and performing logic in every template)
  _:-ms-fullscreen &,
  :root & {
    flex: auto;
  }
}

.c-contact-card__hitbox {
  display: flex;
  flex-direction: column;

  transition: transform 1.5s $easing-fastInSlowOut;

  position: relative;

  margin-left: auto;
  margin-right: auto;

  width: 100%;

  @extend .shadow-fr;
}

// Featured image
// ------------------------------------------

.c-contact-card__img {
  position: relative;

  z-index: 2;

  overflow: hidden;

  border-top-left-radius: $border-radius-ui;

  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;

  border-top-left-radius: $border-radius-ui;
  border-top-right-radius: $border-radius-ui;

  @media (min-width: bp-width-min(xs)) and (max-width: bp-width-max(sm)) {
    width: 40%;
    position: absolute;

    left: 0;
    top: 0;

    height: 100%;

    border-top-right-radius: 0;
    border-bottom-left-radius: $border-radius-ui;
  }
}

.c-contact-card__img__reserve {
  transition: opacity 1.5s $easing-fastInSlowOut;

  padding-top: 90%;

  background-position: center;
  background-size: cover;

  @media (min-width: bp-width-min(xs)) and (max-width: bp-width-max(sm)) {
    position: absolute;

    top: 0;
    left: 0;
    height: 100%;
    width: 100%;

    padding: 0;
  }
}


// Details
// ------------------------------------------

.c-contact-card__details {
  @extend .c-copy--2;

  position: relative;

  z-index: 2;

  border-bottom-left-radius: $border-radius-ui;
  border-bottom-right-radius: $border-radius-ui;

  @media (min-width: bp-width-min(xs)) and (max-width: bp-width-max(sm)) {
    margin-left: 40%;
    border-top-right-radius: $border-radius-ui;
  }
}

.c-contact-card__details--standard {
  @extend .pb4;
  @extend .pb5-sm;
}

.c-contact-card__details--tight {
  @extend .pb3;
  @extend .pb3-sm;
}


// Wrapper to assist button sticking
.c-contact-card__details__btn-space {
  @extend .pb3;
}

// Address
.c-contact-card__address {
  @extend .c-copy;
  @extend .c-copy--1;

  @extend .mt3;

  line-height: map-get($line-height, "tight");
  color: map-get($color-grey, 6)
}

// Phone Number
.c-contact-card__phone-number {
  @extend .c-link;
  @extend .c-copy--1;
  @extend .mt2;
}

// Main heading
.c-contact-card__heading {
  @extend .c-heading;
  @extend .f5;
  @extend .f6-xxl;
}

.c-contact-card__btn-pos {
  @extend .pl4;
  @extend .pr4;
  @extend .pb4;

  @extend .pl5-xs;
  @extend .pr5-xs;
  @extend .pb4-xs;

  @extend .pb5-lg;
  @extend .pl5-lg;
  @extend .pr5-lg;

  position: relative;

  z-index: 5;

  @media (min-width: bp-width-min(xs)) and (max-width: bp-width-max(sm)) {
    margin-left: 40%;
  }
}

