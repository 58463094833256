/*

  components/c-asset-svg-wrap.scss

*/

.c-asset-svg-wrap svg {
  display: block;
  margin: 0;
  width: 100%;
  height: auto;
  max-height: inherit;
}

.c-asset-svg-font-size-enable svg {
  height: 1em;
  width: 1em;
}

.c-asset-svg-wrap-inherit-color svg {
  &,
  g,
  path,
  circle {
    fill: inherit !important;
    stroke: inherit !important;
  }
}
