@import './c-copy--mixins.scss';
@import './c-lead--mixins.scss';

// Base element
// ------------------------------------------

@mixin c_blockquote() {

  @include c_lead();

  position: relative;

  padding-left: 2em;

  margin-left: 0;

  &:after {
    content: "";

    position: absolute;
    left: 0;
    top: .4em;

    bottom: .4em;

    width: map-get($border-width, 2);

    background-color: map-get($color-ui, "primary");
  }

  &:not(:last-child) {
    margin-bottom: 2em;
  }
}


// Dark variant
// ------------------------------------------

@mixin c_blockquote__dark() {
  @include c_copy__dark();
}


// Light variant
// ------------------------------------------

@mixin c_blockquote__light() {
  @include c_copy__light();
}

