/*

  base/animations.scss

  Generalised animation keyframes

  Usage:
    Call the named keyframe sets using 'animation-name', apply other props as desired in your context.

*/

@keyframes in-out-left-right {
  0% {
    transform: translate3d(0,0,0);
  }

  49% {
    transform: translate3d(35%,0,0);
    opacity: 0;
  }

  50% {
    transform: translate3d(-35%,0,0);
  }

  100% {
    transform: translate3d(0,0,0);
    opacity: 1;
  }
}

@keyframes in-out-right-left {
  0% {
    transform: translate3d(0,0,0);
  }

  49% {
    transform: translate3d(-35%,0,0);
    opacity: 0;
  }

  50% {
    transform: translate3d(35%,0,0);
  }

  100% {
    transform: translate3d(0,0,0);
    opacity: 1;
  }
}

@keyframes in-out-top-bottom {
  0% {
    transform: translate3d(0,0,0);
  }

  49% {
    transform: translate3d(0,50%,0);
    opacity: 0;
  }

  50% {
    transform: translate3d(0,-50%,0);
  }

  100% {
    transform: translate3d(0,0,0);
    opacity: 1;
  }
}

@keyframes simple-transform-left-in {
  0% {
    transform: translate3d(-100%,0,0);
  }

  100% {
    transform: translate3d(0,0,0);
  }
}

@keyframes simple-transform-right-out {
  0% {
    transform: translate3d(0,0,0);
  }

  100% {
    transform: translate3d(100%,0,0);
  }
}


@keyframes spin-clockwise {
  0%   {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes pulse {
  0% {
    opacity: 0;
  }

  29% {
    opacity: 1;
  }

  30% {
    transform: scale(1);
    opacity: 1;
  }

  100% {
    opacity: 0;
    transform: scale(2.5);
  }
}

@keyframes lava-pulse {
  // 0% {
  //   transform: scale(1);
  // }

  // 50% {
  //   transform: scale(0.8);
  // }

  // 100% {
  //   transform: scale(1);
  // }
  0% {
    transform: translate3d(0,0,0) scale(1);
    // background-color: map-get($color-grey, 2);
  }


  45% {
    // background-color: map-get($color-grey, 2);
  }
  50% {
    transform: translate3d(0,5%,0) scale(1);
    // background-color: map-get($color-hexicon, "green");
  }
  55% {
    // background-color: map-get($color-grey, 2);
  }

  100% {
    transform: translate3d(0,0,0) scale(1);
    // background-color: map-get($color-grey, 2);
  }
}

@keyframes simple-fade-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes simple-fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes left-to-right-shuffle {
  0% {
    transform: translate3d(0,0,0);
  }

  30% {
    transform: translate3d(20%,0,0);
  }

  100% {
    transform: translate3d(0,0,0);
  }
}


// a-* common animations

.a-simple-fade-in {
  animation-duration: 0.5s;
  animation-name: simple-fade-in;
  animation-delay: 0.05s;
  animation-fill-mode: both;
}

.a-lava-pulse {
  animation-duration: 5s;
  animation-name: lava-pulse;
  // animation-delay: 0.05s;
  // animation-fill-mode: both;
  // animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}






@keyframes scroll-line {
  0% {
    transform: translate3d(0,-100%,0);
  }

  95% {
    transform: translate3d(0,100%,0);
  }
  96% {
    opacity: 0;
  }

  98% {
    opacity: 0;
  }
  99% {
    opacity: 1;
  }
  100% {
    transform: translate3d(0,-100%,0);
  }
}

.scroll-line {
  overflow: hidden;
}

.scroll-line__child {
  animation-name: scroll-line;
  animation-iteration-count: infinite;
  animation-duration: 1.5s;

  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
