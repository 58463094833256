//
// c-popup.scss
//
//

.c-popup {
  $show-speed: 0.3s;

  transition:
    opacity $show-speed linear,
    visibility 0s linear $show-speed;

  visibility: hidden;

  &.s-popup-visible {
    visibility: visible;
    transition: opacity $show-speed linear;
    opacity: 1;
  }
}

.c-popup__box-holder {
  width: 100%;
  min-width: 320px;
  max-width: 30rem;
}

.c-popup__box {
  transform-origin: center bottom;
  transform: scale(.9);

  .s-popup-visible & {
    opacity: 1;
    transform: scale(1);
    transition:
      opacity .2s linear, transform .5s ease;
  }
}

// Note - there are some height based MQs here

.c-popup__heading-and-text-holder {
  padding-top: map-get($layout-scale-rem, 4);
  padding-right: map-get($layout-scale-rem, 4);
  padding-bottom: map-get($layout-scale-rem, 4);
  padding-left: map-get($layout-scale-rem, 4);

  @media (min-width: bp-width-min(sm)) {
    padding-right: map-get($layout-scale-rem, 5);
    padding-left: map-get($layout-scale-rem, 5);
  }
  @media only screen and (min-height: 1000px) {
    padding-top: map-get($layout-scale-rem, 5);
    padding-bottom: map-get($layout-scale-rem, 5);
  }
}

.c-popup__heading {
  font-size: map-get($type-scale-rem, 5);

  @media (min-width: bp-width-min(sm)) {
    font-size: map-get($type-scale-rem, 6);
  }
  @media (min-width: bp-width-min(xl)) {
    font-size: map-get($type-scale-rem, 7);
  }
  @media (max-height: 815px) {
    font-size: map-get($type-scale-rem, 6);
  }
}

.c-popup__description {
  margin-top: map-get($layout-scale-rem, 3);
  font-size: map-get($type-scale-rem, 2);

  @media (min-width: bp-width-min(xs)) {
    margin-top: map-get($layout-scale-rem, 3);
  }

  @media only screen and (min-height: 815px) {
    margin-top: map-get($layout-scale-rem, 3);
    font-size: map-get($type-scale-rem, 3);
  }

  @media only screen and (min-height: 1000px) {
    font-size: map-get($type-scale-rem, 4);
  }

  @media (min-width: bp-width-min(lg)) {
    font-size: map-get($type-scale-rem, 4);
  }
}

.c-popup__close-icon-btn {
  padding: map-get($layout-scale-rem, 2);
  top: map-get($layout-scale-rem, 1);
  right: map-get($layout-scale-rem, 1);

  @media only screen and (min-height: 1000px) {
    top: map-get($layout-scale-rem, 2);
    right: map-get($layout-scale-rem, 2);
  }
}

.c-popup__btns-holder {
  padding: map-get($layout-scale-rem, 3);
  @media only screen and (min-height: 815px) {
    padding: map-get($layout-scale-rem, 4);
  }
}

.c-popup__btn {
  font-size: map-get($type-scale-rem, 1);

  @media (min-width: bp-width-min(lg)) {
    font-size: map-get($type-scale-rem, 2);
  }
  @media only screen and (min-height: 815px) {
    font-size: map-get($type-scale-rem, 2);
  }
}

.c-popup__csrg-container {
  padding: map-get($layout-scale-rem, 3) 0;
  @media only screen and (min-height: 815px) {
    padding: map-get($layout-scale-rem, 4) 0;
  }
}

