@mixin tachyons_border_width($suffix:'') {
  @each $size, $value in $border-width {
    .bw#{$size}#{$suffix} {
      border-width: #{$value};
    }
  }

  .bw-chunky { border-width: map-get($layout-scale-rem, 5); }

  .bt-0#{$suffix} { border-top-width: 0; }
  .br-0#{$suffix} { border-right-width: 0; }
  .bb-0#{$suffix} { border-bottom-width: 0; }
  .bl-0#{$suffix} { border-left-width: 0; }
}

// Create standard classes
// ------------------------------------------

@include tachyons_border_width();


// Create MQ extensions
// ------------------------------------------

@each $bp, $mq in $bp-widths-min {
  @media (min-width: #{$mq}) {
    @include tachyons_border_width($suffix: -#{$bp});
  }
}

