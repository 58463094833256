@keyframes blip {
  0% {
    transform: scale(0);
    opacity: 1;
  }

  100% {
    opacity: 0;
    transform: scale(1);
  }
}

.c-blip {
  display: block;
  position: absolute;
  border-radius: 50%;
}

.c-blip__aspect-provider {
  display: block;
  padding-top: 100%;
}

.c-blip__dot {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  border-radius: 50%;

  transform: scale(0.2);
}

.c-blip__animated,
.c-blip__static {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  border-radius: 50%;
}

.c-blip__animated {
  animation-duration: 8s;
  animation-name: blip;
  animation-timing-function: ease;
  animation-iteration-count: infinite;
}

