@mixin tachyons_positions($suffix:'') {
  .static#{$suffix} { position: static; }
  .relative#{$suffix}  { position: relative; }
  .absolute#{$suffix}  { position: absolute; }
  .fixed#{$suffix}  { position: fixed; }
  .sticky#{$suffix}  {
    position: sticky;
    position: -webkit-sticky; // @ben shouldn't auto prefixer have done this?
  }
}


// Create standard classes
// ------------------------------------------

@include tachyons_positions();



// Create MQ extensions
// ------------------------------------------

@each $bp, $mq in $bp-widths-min {
    @media (min-width: #{$mq}) {
      @include tachyons_positions(-#{$bp});
    }
}

