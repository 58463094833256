@mixin tachyons_flexbox($suffix:'') {
  .flex-none#{$suffix} { flex: none; }

  .flex-noshrink#{$suffix} { flex: 1 0 auto; }
  .flex-auto#{$suffix} { flex: 1 1 auto; }
  .flex-1#{$suffix} { flex: 1; }

  .flex-column#{$suffix}  { flex-direction: column; }
  .flex-row#{$suffix}     { flex-direction: row; }
  .flex-wrap#{$suffix}    { flex-wrap: wrap; }
  .flex-nowrap#{$suffix}  { flex-wrap: nowrap; }
  .flex-column-reverse#{$suffix}  { flex-direction: column-reverse; }
  .flex-row-reverse#{$suffix}     { flex-direction: row-reverse; }
  .flex-wrap-reverse#{$suffix}    { flex-wrap: wrap-reverse; }

  .flex-items-start#{$suffix}    { align-items: flex-start; }
  .flex-items-end#{$suffix}      { align-items: flex-end; }
  .flex-items-center#{$suffix}   { align-items: center; }
  .flex-items-baseline#{$suffix} { align-items: baseline; }
  .flex-items-stretch#{$suffix}  { align-items: stretch; }

  .flex-self-start#{$suffix}    { align-self: flex-start; }
  .flex-self-end#{$suffix}      { align-self: flex-end; }
  .flex-self-center#{$suffix}   { align-self: center; }
  // .flex-self-baseline#{$suffix} { align-self: baseline; }
  // .flex-self-stretch#{$suffix}  { align-self: stretch; }

  .flex-justify-start#{$suffix}   { justify-content: flex-start; }
  .flex-justify-end#{$suffix}     { justify-content: flex-end; }
  .flex-justify-center#{$suffix}  { justify-content: center; }
  .flex-justify-between#{$suffix} { justify-content: space-between; }
  // .flex-justify-around#{$suffix}  { justify-content: space-around; }

  .flex-content-start#{$suffix}   { align-content: flex-start; }
  .flex-content-end#{$suffix}     { align-content: flex-end; }
  .flex-content-center#{$suffix}  { align-content: center; }
  // .flex-content-between#{$suffix} { align-content: space-between; }
  // .flex-content-around#{$suffix}  { align-content: space-around; }
  // .flex-content-stretch#{$suffix} { align-content: stretch; }

  .flex-grow-1#{$suffix} { flex-grow: 1; }

  .flex-order-0#{$suffix} { order: 0; }
  .flex-order-1#{$suffix} { order: 1; }
  .flex-order-2#{$suffix} { order: 2; }
  .flex-order-3#{$suffix} { order: 3; }
  // .flex-order-4#{$suffix} { order: 4; }
  // .flex-order-5#{$suffix} { order: 5; }
  // .flex-order-6#{$suffix} { order: 6; }
  // .flex-order-7#{$suffix} { order: 7; }
  // .flex-order-8#{$suffix} { order: 8; }
  // .flex-order-last#{$suffix} { order: 99999; }

  .flex-basis-0#{$suffix} { flex-basis: 0; }

  .center-content#{$suffix} {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .center-content__inner {
    max-width: 100%;
  }
}


// Create standard classes
// ------------------------------------------

@include tachyons_flexbox();



// Create MQ extensions
// ------------------------------------------

@each $bp, $mq in $bp-widths-min {
    @media (min-width: #{$mq}) {
      @include tachyons_flexbox(-#{$bp});
    }
}

