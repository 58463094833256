/*

  animation/transitions.scss

  Transition tachs

*/


$t-default-duration: 200ms;


// Displays
// ------------------------------------------

.t-display-block {
  display: none;

  .t-active-wrap &,
  &.t-display-block--active {
    display: block;
  }
}

.t-display-none {
  display: block;

  .t-active-wrap &,
  &.t-display-none--active {
    display: none;
  }
}


// Heights
// ------------------------------------------

.t-height-auto {
  height: 0;

  .t-active-wrap &,
  &.t-height-auto--active {
    height: auto;
  }
}


// Visibility
// ------------------------------------------

.t-visible {
  visibility: hidden;

  .t-active-wrap &,
  &.t-visible--active {
    visibility: visible;
  }
}


// Fades
// ------------------------------------------

.t-fade-in {
  opacity: 0;
  transition: opacity $t-default-duration linear,
              visibility 0ms linear $t-default-duration;
  visibility: hidden;

  .t-active-wrap &,
  &.t-fade-in--active {
    opacity: 1;
    visibility: visible;
    transition: opacity $t-default-duration linear,
                visibility 0ms linear 0ms;
  }
  .t-active-wrap &.t-fade-in--to-50,
  &.t-fade-in--active.t-fade-in--to-50 {
    opacity: 0.5;
  }
}

.t-fade-out {
  opacity: 1;
  visibility: visible;
  transition: opacity $t-default-duration linear,
              visibility 0ms linear $t-default-duration;

  .t-active-wrap &,
  &.t-fade-out--active {
    opacity: 0;
    visibility: hidden;
  }
}


// Scales
// ------------------------------------------

$t-scale-default-duration: 2s;

@mixin t-scale {
  transition: transform $t-scale-default-duration $easing-smooth;
}
@mixin t-scale-1 {
  transform: scale(1);
}


// Scale from 0.94 > 1 (example: DC videos ON play) (perhaps can be named scale-up-1 etc)
.t-scale-94-100 {
  @include t-scale;
  transform: scale(.94);

  .t-active-wrap &,
  &.t-scale-94-100--active {
    @include t-scale-1;
  }
}

.t-scale-104-100 {
  @include t-scale;
  transform: scale(1.04);

  .t-active-wrap &,
  &.t-scale-104-100--active {
    @include t-scale-1;
  }
}


// Transforms
// ------------------------------------------

.translate3d-default-ease {
  transition: transform $t-scale-default-duration $easing-smooth;
}


// Color (not the best for performance - but negligible in use and simpler to implement)
// ------------------------------------------

.t-bg-color-1 {
  transition: background-color 100ms linear;
}


// Transition duration tachyons
// ------------------------------------------

.t-duration-0   { transition-duration: 0ms;   }
.t-duration-100 { transition-duration: 100ms; }
.t-duration-200 { transition-duration: 200ms; }
.t-duration-300 { transition-duration: 300ms; }
.t-duration-400 { transition-duration: 400ms; }
.t-duration-500 { transition-duration: 500ms; }

