@mixin c_link() {
  transition:
    opacity .2s linear,
    background-color .1s linear;

  border-bottom-width: map-get($border-width, 1);
  border-bottom-style: dashed;
  border-color: map-get($color-grey, 4);

  padding-bottom: .1em;

  color: map-get($color-ui, "primary");

  font-weight: map-get($font-weight, 600);

  opacity: .8;

  &:hover {
    opacity: 1;
    border-bottom-style: solid;
    border-color: map-get($color-grey, 5);
  }

  &:active {
    background-color: map-get($color-grey, 3);
  }
}

@mixin c_link__dark() {
  // Dark variant
  // ------------------------------------------
  border-color: map-get($color-grey, 6);

  &:hover {
    border-color: map-get($color-grey, 5);
  }

  &:active {
    background-color: map-get($color-grey, 9);
  }
}


@mixin c_link__light() {
  // Light variant
  // ------------------------------------------
  border-color: map-get($color-grey, 4);

  &:hover {
    border-color: map-get($color-grey, 5);
  }

  &:active {
    background-color: map-get($color-grey, 3);
  }
}


@mixin c_link__inherit() {
  // Inherit variant
  // ------------------------------------------
  color: inherit;
  border-bottom-style: solid;
}