.c-content {
  @include c_content($fancy-list-styles: true);
}

.c-content--with-border {
  @include c_content__with-border;
}

.c-content--legal {
  @include c_content__legal;
}

.c-content--headings-1  {
  @include c_content__headings_1;
}

.c-content--headings-2  {
  @include c_content__headings_2;
}

.c-content--headings-3  {
  @include c_content__headings_3;
}

.c-content--copy-1  {
  @include c_content__copy_1;
}

.c-content--copy-2  {
  @include c_content__copy_2;
}

.c-content--copy-3  {
  @include c_content__copy_3;
}

.c-content--article  {
  @include c_content__article;
}

.c-content--measure  {
  @include c_content__measure;
}

.c-content--measure-wide  {
  @include c_content__measure_wide;
}

.c-content--dark  {
  @include c_content__dark;
}

.c-content--light  {
  @include c_content__light;
}

@include content_alignment();
@each $bp, $mq in $bp-widths-min {
  @media (min-width: #{$mq}) {
    @include content_alignment(-#{$bp});
  }
}

.c-content--tinymce-patch {
  @include c_content__tinymce-patch
}

