/*

  base/wordpress.scss

  Overrides and changes to core wordpress styles
  This is the lesser of the evils when hacking around what wordpress adds to our front-end

*/
// Admin bar overrides
// This prevents the admin bar from sitting above the site navigation
// It's hacky, the breakpoints are referenced from their admin stylesheet
// Seems like the safest of all the options – Andy
// ------------------------------------------

// Attempt to limit the scope of problems by checking the wordpress classes against the body
// admin-bar and customize-support are also both qualified
// admin-bar – added by wordpress when the user allows the admin bar in their profile
// customize-support – when a page is part of wordpress and is allowed to be edited

// UPDATE: March 2021
// ------------------------------------------
// REMOVED - Why? Was causing an issue on Digcon header when people were logged in,
// and in combination with c-growl, I deemed the "logic" too complicated for a quick fix.
// Have left the code here in case anyone really needs a flawless
// admin bar experience and a dev has the time to do it. - Pete

#wpadminbar {
  background-color: rgba(0,0,0,0.65); // This means you can still see the text etc in the nav
  // and c-growl with the admin bar active
}

// body.admin-bar.customize-support {
//   $admin-bar-mobile-height: 46px;
//   $admin-bar-desktop-height: 32px;

//   // Add margins to body
//   // Always keeps all content visible underneath the admin bar
//   @media screen and (max-width: 781px) {
//     margin-top: $admin-bar-mobile-height;
//   }

//   @media screen and (min-width: 783px) {
//     margin-top: $admin-bar-desktop-height;
//   }

//   // Adjust top position to account for admin-bar
//   .c-admin-bar-top-adjust {
//     @media screen and (min-width: 601px) {
//       top: $admin-bar-mobile-height;
//     }

//     @media screen and (min-width: 783px) {
//       top: $admin-bar-desktop-height;
//     }
//   }

//   // Site nav specific adjustment
//   .c-admin-bar-site-nav-adjust {
//     @media screen and (min-width: 601px) and (max-width: 782px) {
//       top: $admin-bar-mobile-height;
//     }

//     @media screen and (min-width: 783px) and (max-width: bp-width-max(lg)) {
//       top: $admin-bar-desktop-height;
//     }
//   }
// }

