/*

  components/wordpress/c-wp-pagination.scss

  Wordpress has built in pagination functions, but they do not allow fine control over markup.
  These are simply styles that descend from the c-wp-pagination wrapper class attaching to the built in
  wp classes. The specificity is high here, so we could look to write a replacement for the
  inbuilt WP get_pagination() function.

*/


// Scoping container
// ------------------------------------------

.c-wp-pagination {

  $link-color: map-get($color-ui, "primary");

  padding: map-get($layout-scale-rem, 5) 0;

  @media (max-width: bp-width-max('xs')) {
    font-size: map-get($type-scale-rem, 2);
  }
  @media (min-width: bp-width-min('xs')) {
    font-size: map-get($type-scale-rem, 3);
  }
  @media (min-width: bp-width-min('md')) {
    font-size: map-get($type-scale-rem, 4);
  }


  .page-numbers {
    display: inline-block;
    vertical-align: middle;

    color: map-get($color-grey, 5);
    font-weight: map-get($font-weight, 600);

    padding: 1em;

    &.current {
      position: relative;
      z-index: 1;

      color: $color-white;

      &::before {
        content: '';
        display: block;
        z-index: -1;

        border-radius: 100%;

        width: 3em;
        height: 3em;

        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        background-color: $link-color;
      }

      &:hover {
        color: $color-white;
      }
    }

    &.dots {
      pointer-events: none;
      color: map-get($color-grey, 4);
    }

    &.next,
    &.prev {
      padding-left: map-get($layout-scale-rem, 3);
      padding-right: map-get($layout-scale-rem, 3);
    }

    &:hover {
      color: map-get($color-grey, 8);
    }
  }
}

.c-wp-pagination--bordered {
  @extend .bt;
  @extend .color-border-grey-3;
}
