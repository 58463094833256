//
// ^core.scss - compilation of core output styles
//

// Core tooling - Core styles are derived from here
// ------------------------------------------

@import "core-tooling";

// Base styles
// ------------------------------------------

@import "base/fonts";
@import "base/animations";
@import "base/base";
@import "base/wordpress";

// Vendor/library styles
// ------------------------------------------

@import "vendor/lazy/js-lazy-img";
@import "vendor/lightgallery/lightgallery";
@import "vendor/tiny-slider/tiny-slider";
@import "vendor/drift-customization/drift-overrides";
@import "vendor/active-campaign/active-campaign-embedded-form-wrap";

// Mixins
// ------------------------------------------

@import "mixins/common-form-styles";
@import "mixins/hardware-acceleration";
@import "mixins/nubbin";
@import "mixins/c-nav-100vh-calc";

// Components
// ------------------------------------------

// Btns
@import "components/buttons/c-btn--mixins";
@import "components/buttons/c-btn";
@import "components/buttons/c-btn-round";
@import "components/buttons/c-btn-pair";

// Cards
@import "components/cards/c-card";
@import "components/cards/c-contact-card";

// Links
@import "components/links/c-simple-link";
@import "components/links/c-wp-pagination";

// Promo
@import "components/promo/promo";

// Media
@import "components/media/c-icon";
@import "components/media/c-spot-icon";
@import "components/media/c-logo";
@import "components/media/c-company-logo";
@import "components/media/c-twitter-embed";
@import "components/media/c-video-embed-container";
@import "components/media/c-oembed-wrapper";
@import "components/media/c-csrg-graphic";
@import "components/media/c-asset-svg-wrap";
@import "components/media/c-asset-border-radius";

// Other
@import "components/other/c-client-logo";
@import "components/other/c-quote";
@import "components/other/c-hamburger";
@import "components/other/c-tooltip";
@import "components/other/c-blip";
@import "components/other/c-progress-meter";

// Skin
@import "components/skin/c-shadow-edge";
@import "components/skin/c-blocktone";
@import "components/skin/c-shadow-bottom";

// Spacing
@import "components/spacing/c-blockpad";

// Tiles
@import "components/tiles/c-content-tile";

// Typography
@import "components/typography/c-heading--mixins";
@import "components/typography/c-heading";
@import "components/typography/c-lead--mixins.scss";
@import "components/typography/c-lead";
@import "components/typography/c-tertiary--mixins";
@import "components/typography/c-tertiary";
@import "components/typography/c-link--mixins";
@import "components/typography/c-link";
@import "components/typography/c-copy--mixins";
@import "components/typography/c-copy";
@import "components/typography/c-list--mixins";
@import "components/typography/c-list";
@import "components/typography/c-rule--mixins";
@import "components/typography/c-rule";
@import "components/typography/c-blockquote--mixins";
@import "components/typography/c-blockquote";
@import "components/typography/c-table--mixins";
@import "components/typography/c-table";
@import "components/typography/c-content--mixins";
@import "components/typography/c-content";
@import "components/typography/c-dot-divider";
@import "components/typography/c-mode";

// Form
@import "components/form/c-form";
@import "components/form/c-toggle";
@import "components/form/c-textarea";
@import "components/form/c-input";
@import "components/form/c-label";
@import "components/form/c-select";
@import "components/form/c-validation-msg";
@import "components/form/c-form-subheading";
@import "components/form/c-selectable-input";
@import "components/form/c-switch";
@import "components/form/c-form-multistep";

// Headers
@import "components/header/c-minimal-header";
@import "components/header/c-growl";
@import "components/header/c-nav-jumplink-offset";
@import "components/header/mobile-header";

// Navigation
@import "components/navigation/c-nav";

// Popups
@import "components/popups/c-popup";

// Body modifiers
@import "body-modifiers/body-modifiers";

// c-js
@import "components/c-js/c-js-expandable";
@import "components/c-js/js-smart-ellipsis";

// Layout
// ------------------------------------------

@import "layout/l-page-gutter";
@import "layout/l-container";
@import "layout/l-enforce-page-height";
@import "layout/l-grid";

// Other
// ------------------------------------------

@import "other/hide-adroll-cookie-banner";
@import "other/hide-capterra-tracking-gif";

// Utils
// ------------------------------------------

@import "utils/u-on-event-reveal";
@import "utils/u-object-hover";
@import "utils/u-legibility";
@import "utils/u-code";
@import "utils/u-touch-optimize";
@import "utils/u-sibling-diminish-onHover";
@import "utils/u-ov-fader";
@import "utils/u-breakpoint-indicator.scss";

// Animations
// ------------------------------------------

@import "animation/animations";
@import "animation/transitions";
@import "animation/transitions-force-finish";
@import "animation/transitions-page-element";
@import "animation/utils";

// Tachyons
// ------------------------------------------

@import "tachyons/tachyons";

// Modules
// ------------------------------------------

/*
NB: If you wish to include your module code into core to enable @extends,
remove the ^ from your modules entry scss file and include it here.
Make sure that there is no module/dist css file or the styles will be included twice.
*/

// example: @import '../../../<moduleName>/src/css/<moduleCssFile>';

// Dirty
// ------------------------------------------

@import "dirty";
