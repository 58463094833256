@mixin tachyons_text_decoration($suffix: "") {
    .strike#{$suffix} {
        text-decoration: line-through;
    }
    .underline#{$suffix} {
        text-decoration: underline;
    }
    .no-underline#{$suffix} {
        text-decoration: none;
    }
    .underline-hover#{$suffix}:hover,
    .underline-hover#{$suffix}:focus {
        text-decoration: underline;
    }
    .no-underline-hover#{$suffix}:hover {
        text-decoration: none;
    }
}

// Create standard classes
// ------------------------------------------
@include tachyons_text_decoration();
// Create MQ extensions
// ------------------------------------------
// @each $bp, $mq in $bp-widths-min {
//   @media (min-width: #{$mq}) {
//     @include tachyons_text_decoration(-#{$bp});
//   }
// }