/*

  mixins/touch-optimize.scss

  Visual feedback optimizations for buttons and clickable elements

*/

@mixin touch_optimize() {
  // Webkit / Chrome Specific CSS to remove tap highlight color
  -webkit-tap-highlight-color: transparent;

  // Firefox Specific CSS to remove button differences and focus ring
  background-image: none;

  outline: none;

  // Prevent double tap to zoom
  touch-action: manipulation;

  &::-moz-focus-inner {
    border: 0;
  }

  // Override Element Outline in Focus State
  &:focus {
    outline: 0;
  }
}

