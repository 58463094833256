@mixin tachyons_rotations($suffix:'') {
  // .rotate-45 { transform: rotate(45deg); }
  .rotate-90 { transform: rotate(90deg); }
  .rotate-135 { transform: rotate(135deg); }
  // .rotate-180 { transform: rotate(180deg); }
}


// Create standard classes
// ------------------------------------------

@include tachyons_rotations();



// Create MQ extensions
// ------------------------------------------

// @each $bp, $mq in $bp-widths-min {
//   @media (min-width: #{$mq}) {
//     @include tachyons_rotations(-#{$bp});
//   }
// }