// Create standard classes (Padding)
// ------------------------------------------

@include tachyons_spacing($prefix: 'p', $prop: 'padding', $suffix: null);


// Create MQ extensions (Padding)
// ------------------------------------------

@each $bp, $mq in $bp-widths-min {
  @media (min-width: #{$mq}) {
    @include tachyons_spacing($prefix: 'p', $prop: 'padding', $suffix: -#{$bp});
  }
}


// Create standard classes (Margin)
// ------------------------------------------

@include tachyons_spacing($prefix: 'm', $prop: 'margin', $suffix: null);
@include tachyons_margin_x_auto();


// Create MQ extensions (Margin)
// ------------------------------------------

@each $bp, $mq in $bp-widths-min {
  @media (min-width: #{$mq}) {
    @include tachyons_spacing($prefix: 'm', $prop: 'margin', $suffix: -#{$bp});
    @include tachyons_margin_x_auto(-#{$bp});
  }
}







