/*

  components/icons/c-spot-icon.scss

  Coloured spot icon

  Media Query Extensions: N/A

*/

// Spot container
// ------------------------------------------

.c-spot-icon {
  position: relative;

  display: inline-block;
  font-size: 1em;

  width: 2em;
  height: 2em;

  display: block;

  border-radius: 100%;

  transition: background-color 300ms linear;
}

// if a size is passed in
.c-spot-icon.size-small {
  width: 1em;
  height: 1em;
  display: inline-flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-bottom: 0.75rem;
}

@media (max-width: bp-width-max(md)) {
  .c-spot-icon.size-small {
    width: 1.5em;
    height: 1.5em;
  }
}

.c-spot-icon.size-small .c-spot-icon__icon {
  display: block;
  position: relative;
  left: auto;
  top: auto;
  margin-left: 0;
  margin-top: 0;
}

.t-initially-grey-icons .c-spot-icon {
  background-color: map-get($color-grey, 3) !important;
}

// Internal icon
// ------------------------------------------

.c-spot-icon__icon {
  display: block;

  position: absolute;

  left: 50%;
  top: 50%;

  margin-left: -0.5em;
  margin-top: -0.5em;

  // Inner icons should always be white against the background
  color: $color-white;
}
