/*

  mixins/fill-svg-elements.scss

  Convenience mixin for adding fills to common svg elements directly

*/


@mixin fill_svg_elements($fill_color) {

  polygon,
  circle,
  path {
    fill: $fill_color;
  }

}

