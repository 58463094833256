@mixin tachyons_spacing($prefix, $prop, $suffix:'', $rem: true) {

  @if $rem {

    // Rem
    @each $pos, $spacing in $layout-scale-rem {
      .#{$prefix}a#{$pos}#{$suffix} {
        #{$prop}: #{$spacing};
      }
      .#{$prefix}t#{$pos}#{$suffix} {
        #{$prop}-top: #{$spacing};
      }
      .#{$prefix}r#{$pos}#{$suffix} {
        #{$prop}-right: #{$spacing};
      }
      .#{$prefix}b#{$pos}#{$suffix} {
        #{$prop}-bottom: #{$spacing};
      }
      .#{$prefix}l#{$pos}#{$suffix} {
        #{$prop}-left: #{$spacing};
      }
      .#{$prefix}x#{$pos}#{$suffix} {
        #{$prop}-left: #{$spacing};
        #{$prop}-right: #{$spacing};
      }
      .#{$prefix}y#{$pos}#{$suffix} {
        #{$prop}-top: #{$spacing};
        #{$prop}-bottom: #{$spacing};
      }
    }

  } @else {

    // Px
    @each $pos, $spacing in $layout-scale-px {
      .#{$prefix}a#{$pos}#{$suffix} {
        #{$prop}: #{$spacing};
      }
      .#{$prefix}t#{$pos}#{$suffix} {
        #{$prop}-top: #{$spacing};
      }
      .#{$prefix}r#{$pos}#{$suffix} {
        #{$prop}-right: #{$spacing};
      }
      .#{$prefix}b#{$pos}#{$suffix} {
        #{$prop}-bottom: #{$spacing};
      }
      .#{$prefix}l#{$pos}#{$suffix} {
        #{$prop}-left: #{$spacing};
      }
      .#{$prefix}x#{$pos}#{$suffix} {
        #{$prop}-left: #{$spacing};
        #{$prop}-right: #{$spacing};
      }
      .#{$prefix}y#{$pos}#{$suffix} {
        #{$prop}-top: #{$spacing};
        #{$prop}-bottom: #{$spacing};
      }
    }

  }
}

// Margin X auto (center)

@mixin tachyons_margin_x_auto($suffix:'') {
  .mx-auto#{$suffix} {
    margin-left: auto;
    margin-right: auto;
  }

  .mr-auto#{$suffix} {
    margin-right: auto;
  }

  .ml-auto#{$suffix} {
    margin-left: auto;
  }

  .mx-initial#{$suffix} {
    margin-left: initial;
    margin-right: initial;
  }

  .mb0-force {
    margin-bottom: 0 !important;
  }
}

