$tile_transition_duration: 250ms;

.c-content-tile__textual-wrapper {
  transition:
    transform $tile_transition_duration ease-in-out,
    opacity $tile_transition_duration linear;

  position: absolute;
  left: -1px;
  right: -1px;

  top: 100%;

  transform: translate3d(0,-87px,0);

  @media (min-width: bp-width-min('xxl')) {
    transform: translate3d(0,-110px,0);
  }
}

.c-content-tile__heading-wrapper {
  position: relative;
}

.c-content-tile__heading {
  white-space: nowrap;

  position: absolute;
  left: 0;
  top: 0;

  transform: translate3d(0,0,0);
  transition-property: transform;
  transition-duration: 300ms;
  transition-timing-function: linear;

  width: auto;
  overflow: hidden;
}

.c-content-tile__overlay {
  transition: opacity $tile_transition_duration linear;
}

.c-content-tile__img-el {
  position: absolute;
  top: -1px; // "pull" the el outside the wrapper for sub-pixel rounding issues
  right: -1px;
  bottom: -1px;
  left: -1px;

  transition: opacity $tile_transition_duration linear;
}

.c-content-tile__img-el-cover {
  transition: opacity $tile_transition_duration linear;
  opacity: 0;
}

.c-content-tile__paragraph {
  transition: opacity $tile_transition_duration linear;
  opacity: 0;
}


.c-content-tile:hover {
  .c-content-tile__heading {
    transform: translate3d(0,0,0) !important; // overrides inline style set by js-smart-ellipsis

    max-width: none;
    text-overflow: initial;
  }

  .c-content-tile__textual-wrapper {
    transform: translate3d(0,-100%,0);
  }

  .c-content-tile__overlay {
    opacity: 0.8;
  }

  .c-content-tile__img-el {
    opacity: 0.3;
  }

  .c-content-tile__paragraph {
    opacity: 1;
  }

  .c-content-tile__img-el-cover {
    transition: opacity $tile_transition_duration linear;
    opacity: 0.5;
  }
}

