@mixin tachyons_opacity($suffix:'') {
  .o-100#{$suffix} { opacity: 1;    }
  .o-90#{$suffix}  { opacity: .9;   }
  .o-80#{$suffix}  { opacity: .8;   }
  .o-70#{$suffix}  { opacity: .7;   }
  .o-60#{$suffix}  { opacity: .6;   }
  .o-50#{$suffix}  { opacity: .5;   }
  .o-40#{$suffix}  { opacity: .4;   }
  .o-30#{$suffix}  { opacity: .3;   }
  .o-20#{$suffix}  { opacity: .2;   }
  .o-10#{$suffix}  { opacity: .1;   }
  .o-05#{$suffix}  { opacity: .05;  }
  .o-0#{$suffix}   { opacity: 0;    }

  .o-100-onHover#{$suffix}:hover {
    &.o-100-onHover__target,
    .o-100-onHover__target {
      opacity: 1;
    }
  }
  .o-60-onHover#{$suffix}:hover {
    &.o-60-onHover__target,
    .o-60-onHover__target {
      opacity: 0.6;
    }
  }
}

// Create standard classes
// ------------------------------------------

@include tachyons_opacity();


// // Create MQ extensions
// // ------------------------------------------

@each $bp, $mq in $bp-widths-min {
  @media (min-width: #{$mq}) {
    @include tachyons_opacity($suffix: -#{$bp});
  }
}



