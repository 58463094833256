@mixin tachyons_letter_spacing($suffix:'') {
  @each $label, $value in $letter-spacing {
    .ls-#{$label}#{$suffix} { letter-spacing: #{$value}; }
  }
}


// Create standard classes
// ------------------------------------------

@include tachyons_letter_spacing();



// Create MQ extensions
// ------------------------------------------

// @each $bp, $mq in $bp-widths-min {
//     @media (min-width: #{$mq}) {
//       @include tachyons_letter_spacing(-#{$bp});
//     }
// }