@mixin tachyons_whitespace($suffix:'') {
  .ws-normal#{$suffix} { white-space: normal; }
  .nowrap#{$suffix} { white-space: nowrap; }
  .pre#{$suffix} { white-space: pre; }
}


// Create standard classes
// ------------------------------------------

@include tachyons_whitespace();



// Create MQ extensions
// ------------------------------------------

@each $bp, $mq in $bp-widths-min {
    @media (min-width: #{$mq}) {
      @include tachyons_whitespace(-#{$bp});
    }
}

