@mixin tachyons_line_height($suffix:'') {
  @each $label, $value in $line-height {
    .lh-#{$label}#{$suffix} { line-height: #{$value}; }
  }
}


// Create standard classes
// ------------------------------------------

@include tachyons_line_height();



// Create MQ extensions
// ------------------------------------------

@each $bp, $mq in $bp-widths-min {
    @media (min-width: #{$mq}) {
      @include tachyons_line_height(-#{$bp});
    }
}