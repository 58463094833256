// Base element
// ------------------------------------------
@mixin c_content($fancy-list-styles: false) {
    // Add the visual style
    h1,
    h2,
    h3,
    h4,
    h5 {
        @include c_heading();
        @include c_heading__top_far();
        @include c_heading__bottom_medium();
        // Remove first and last margins in a container
        // Override value here due to extend
        &:first-child {
            margin-top: 0;
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
    a:not(.c-btn) {
        @include c_link();
    }
    p {
        @include c_copy();
        &:not(:last-child) {
            @include c_copy__bottom_medium();
        }
    }
    ul,
    ol {
        @include c_list();
    }
    @if $fancy-list-styles {
        ul {
            @include c_list__unordered();
        }
        ol {
            @include c_list__ordered();
        }
    }
    hr {
        @include c_rule();
    }
    blockquote {
        @include c_blockquote();
    }
    table {
        @include c_table();
    }
}

@mixin c_content__legal() {
    ol {
        @include c_list__legal_ordered();
    }
}

@mixin c_content__with-border() {
    h2 {
        color: map-get($color-hexicon, "blue");
    }
}

// Content heading scales
// ------------------------------------------
@mixin c_content__headings_1() {
    h1 {
        @extend .c-heading--3;
    }
    h2 {
        @extend .c-heading--2;
    }
    h3 {
        @extend .c-heading--1;
    }
    h4 {
        @extend .c-copy--2;
    }
    h5 {
        @extend .c-copy--1;
    }
}

@mixin c_content__headings_2() {
    h1 {
        @extend .c-heading--4;
    }
    h2 {
        @extend .c-heading--3;
    }
    h3 {
        @extend .c-heading--2;
    }
    h4 {
        @extend .c-heading--1;
    }
    h5 {
        @extend .c-copy--2;
    }
}

@mixin c_content__headings_3() {
    h1 {
        @extend .c-heading--5;
    }
    h2 {
        @extend .c-heading--4;
    }
    h3 {
        @extend .c-heading--3;
    }
    h4 {
        @extend .c-heading--2;
    }
    h5 {
        @extend .c-heading--1;
    }
}

// Content copy scales
// ------------------------------------------
@mixin c_content__copy-1() {
    ul,
    ol {
        @extend .c-list--1;
    }
    p {
        @extend .c-copy--1;
    }
    blockquote {
        @extend .c-blockquote--1;
    }
    hr {
        @extend .c-rule--1;
    }
    table {
        @extend .c-table--1;
    }
}

@mixin c_content__copy-2() {
    ul,
    ol {
        @extend .c-list--2;
    }
    p {
        @extend .c-copy--2;
    }
    blockquote {
        @extend .c-blockquote--2;
    }
    hr {
        @extend .c-rule--2;
    }
    table {
        @extend .c-table--2;
    }
}

@mixin c_content__copy-3() {
    ul,
    ol {
        @extend .c-list--3;
    }
    p {
        @extend .c-copy--3;
    }
    blockquote {
        @extend .c-blockquote--3;
    }
    hr {
        @extend .c-rule--3;
    }
    table {
        @extend .c-table--3;
    }
}

@mixin c_content__article() {
    ul,
    ol {
        @extend .c-list--article;
    }
    p {
        @extend .c-copy--article;
    }
    blockquote {
        @extend .c-blockquote--article;
    }
    hr {
        @extend .c-rule--article;
    }
    table {
        @extend .c-table--article;
    }
}

// Measured elements modifier
// Relatively limit max width of child elements
// ------------------------------------------
@mixin c_content__measure() {
    h1,
    h2,
    h3,
    h4,
    h5,
    p,
    ul,
    ol,
    blockquote {
        @extend .measure;
    }
}

@mixin c_content__measure_wide() {
    h1,
    h2,
    h3,
    h4,
    h5,
    p,
    ul,
    ol,
    blockquote {
        @extend .measure-wide;
    }
}

// Dark variant
// ------------------------------------------
@mixin c_content__dark() {
    // Add the visual style
    h1,
    h2,
    h3,
    h4,
    h5 {
        @extend .c-heading--dark;
    }
    p {
        @extend .c-copy--dark;
    }
    ul,
    ol {
        @extend .c-list--dark;
    }
    hr {
        @extend .c-rule--dark;
    }
    blockquote {
        @extend .c-blockquote--dark;
    }
}

// Light variant
// ------------------------------------------
@mixin c_content__light() {
    // Add the visual style
    h1,
    h2,
    h3,
    h4,
    h5 {
        @extend .c-heading--light;
    }
    p {
        @extend .c-copy--light;
    }
    ul,
    ol {
        @extend .c-list--light;
    }
    hr {
        @extend .c-rule--light;
    }
    blockquote {
        @extend .c-blockquote--light;
    }
}

// Alignment options
// ------------------------------------------
@mixin content_alignment($suffix: "") {
    .c-content--left#{$suffix} {
        text-align: left;
        // Add the visual style
        h1,
        h2,
        h3,
        h4,
        h5,
        p {
            text-align: left;
        }
        ul,
        ol,
        blockquote {
            display: block;
            text-align: left;
        }
        // Clear measured centering between breakpoints
        h1,
        h2,
        h3,
        h4,
        h5,
        p,
        ul,
        ol,
        blockquote {
            margin-left: 0;
            margin-right: 0;
        }
    }
    .c-content--centered#{$suffix} {
        text-align: center;
        // Add the visual style
        h1,
        h2,
        h3,
        h4,
        h5,
        p {
            text-align: center;
        }
        ul,
        ol,
        blockquote {
            display: inline-block;
            text-align: left;
        }
        // Centering when elements are "Measured"
        h1,
        h2,
        h3,
        h4,
        h5,
        p,
        ul,
        ol,
        blockquote {
            margin-left: auto;
            margin-right: auto;
        }
    }
}

// Patch to overwrite specific database contents
// 'tach-o' when applied to an image, can be used to ignore the below
// ------------------------------------------
@mixin c-content__tinymce_patch() {
    img {
        height: auto;
        width: 100%;
    }
    .entry-content-asset {
        position: relative;
        padding-top: 56.6%;
        iframe {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            height: 100%;
        }
    }
    twitterwidget,
    iframe,
    img:not(.tach-o),
    .entry-content-asset {
        margin: 3.1em auto !important;
    }
    blockquote {
        margin: 3.1em auto;
        p {
            font-weight: 600;
        }
    }
}
