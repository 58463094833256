/*

  base/globals.scss

  Global variables and settings. The values here are directly referenced to build our framework.

  These values shouldn't change for the most part. If you do need to make changes
  then CHECK WITH A COLLEAGUE first.

  ORDER IS IMPORTANT - some mixins depend on the order to generate the correct specificity cascade
  Always order "mobile first" (smallest to largest)

*/

// Breakpoints
// ------------------------------------------
// These are stored as pixel values for em conversion later
// EM's have problems with min and max range mixtures
// We need to perform pixel based calculations before passing to an EM conversion function

$bp-widths: (
  "xs": 480px,
  "sm": 600px,
  "md": 900px,
  "lg": 1200px,
  "lg-xl": 1450px,
  "xl": 1850px,
  "xxl": 2250px,
);

// c-nav (height)
// ------------------------------------------
// Map the height of the c-nav here. Why? Sometimes, we want to make something
// full screen height, minus the height of the header. Hard coding it here means
// we don't have to do it with JS. Any time c-nav is changed and the height alters
// please change these values to reflect.

$c-nav-height: prem(62px);
$c-nav-height-md: prem(69px);
$c-nav-height-xl: prem(80px);

// Document
// ------------------------------------------
// We stop the site being any 'thinner' than 320px

$doc-min-width: prem(320px);

// Global Widths
// ------------------------------------------
// Converted values for use in MQ's

$container-width: prem(1200px);

$bp-widths-min: (
  "xs": pem(map-get($bp-widths, "xs")),
  "sm": pem(map-get($bp-widths, "sm")),
  "md": pem(map-get($bp-widths, "md")),
  "lg": pem(map-get($bp-widths, "lg")),
  "lg-xl": pem(map-get($bp-widths, "lg-xl")),
  "xl": pem(map-get($bp-widths, "xl")),
  "xxl": pem(map-get($bp-widths, "xxl")),
);

$bp-widths-max: (
  "xs": pem(map-get($bp-widths, "xs") - 1px),
  "sm": pem(map-get($bp-widths, "sm") - 1px),
  "md": pem(map-get($bp-widths, "md") - 1px),
  "lg": pem(map-get($bp-widths, "lg") - 1px),
  "lg-xl": pem(map-get($bp-widths, "lg-xl") - 1px),
  "xl": pem(map-get($bp-widths, "xl") - 1px),
  "xxl": pem(map-get($bp-widths, "xxl") - 1px),
);

// Typography
// ------------------------------------------

$line-height: (
  "super-tight": 1,
  "extra-tight": 1.1,
  "tight": 1.25,
  "regular": 1.5,
  "loose": 1.6,
);

$letter-spacing: (
  "tight": -0.03em,
  "regular": 0em,
  "loose": 0.07em,
);

$font-weight: (
  300: 300,
  400: 400,
  600: 600,
);

// Base: 16
// Minor third - 1.2 (rounded/adapted)
$type-scale-px: (
  1: 10px,
  2: 12px,
  3: 14px,
  4: 16px,
  5: 18px,
  6: 21px,
  7: 27px,
  8: 33px,
  9: 40px,
  10: 48px,
  11: 57px,
  12: 69px,
  13: 78px,
  14: 90px,
  15: 118px,
);

$type-scale-rem: (
  1: prem(map-get($type-scale-px, 1)),
  2: prem(map-get($type-scale-px, 2)),
  3: prem(map-get($type-scale-px, 3)),
  4: prem(map-get($type-scale-px, 4)),
  5: prem(map-get($type-scale-px, 5)),
  6: prem(map-get($type-scale-px, 6)),
  7: prem(map-get($type-scale-px, 7)),
  8: prem(map-get($type-scale-px, 8)),
  9: prem(map-get($type-scale-px, 9)),
  10: prem(map-get($type-scale-px, 10)),
  11: prem(map-get($type-scale-px, 11)),
  12: prem(map-get($type-scale-px, 12)),
  13: prem(map-get($type-scale-px, 13)),
  14: prem(map-get($type-scale-px, 14)),
  15: prem(map-get($type-scale-px, 15)),
);

// Fonts
// ------------------------------------------

$font-family: "Roboto";

// Colors
// ------------------------------------------

$color-white: #fff;
$color-black: #000;

// Greys
$color-grey: (
  0: #ffffff,
  1: #fcfcfc,
  2: #f9f9f9,
  3: #eeeeee,
  4: #c6c7cc,
  5: #868690,
  6: #51505a,
  7: #303138,
  8: #2a282f,
  9: #27252c,
  10: #252328,
);

// Socials
$color-social: (
  "youtube": #ff0000,
  "facebook": #3b5998,
  "twitter": #1da1f2,
  "linkedin": #0077b5,
  "slideshare": #0077b5,
  "instagram": #c13584,
  "xing": #026466,
);

$color-hexicon: (
  "blue": #57b7dd,
  "orange": #f99132,
  "purple": #a98bbc,
  "red": #ff6d56,
  "green": #8ac539,
  "yellow": #ffbe0a,
);

$color-buzz: (
  "blue": #3a64c5,
  "blue-current": #3b79cc,
  "orange": #ff9900,
  "green": #57bb99,
);

// Logo Colors - Old - Need Removing - In DB Also
$color-logo: (
  "lightblue": #6cc7eb,
  "green": #8ac539,
);

// Selected app colors from Axiom
// https://brandwatchltd.github.io/axiom/docs/materials/colors - "User Defined Graph Colors" (right at bottom of page)
// Updated 09/01/2019 (Pete)
$color-app: (
  "rose": #f598b7,
  // Brandwatch for Education
  "pink": #e35a7a,
  "purple": #b6195f,
  // Brandwatch for Education
  "lilac": #90a0d6,
  "blue": #3b79cc,
  // BuzzSumo
  "teal": #24adbd,
  "green": #80be4c,
  "chartreuse": #c8c819,
  "amber": #ffbe0a,
  "orange": #f98a39,
  "vizia": #d7ff00,
);

// UI colors
$color-ui: (
  "primary": #53a3e5,
  // updated to 'new-blue' Apr 2019
  "success": #83bf3f,
  "error": #fc4870,
  "warning": #e0b651,
);

$color-product: (
  "vizia": #906BA7,
  "influence": #6063A1,
  "publish": #24ADBD,
  "advertise": #09D5D9,
  "measure": #57B7DD,
  "benchmark": #3B79CC,
  "engage": #E35A7A,
  "audience": #FF6D56,
  "listen": #FFBE0A,
  "iris": #4DEABE,
);

// Spacing
// ------------------------------------------

$layout-scale-px: (
  // added for email module
  0: 0,
  1: 4px,
  2: 8px,
  3: 16px,
  4: 24px,
  5: 32px,
  6: 48px,
  7: 64px,
  8: 128px,
  9: 256px,
  10: 512px,
  11: 768px,
  12: 1024px,
  13: 1280px,
  14: 1536px,
  15: 2000px,
);

$layout-scale-rem: (
  0: 0,
  1: prem(map-get($layout-scale-px, 1)),
  2: prem(map-get($layout-scale-px, 2)),
  3: prem(map-get($layout-scale-px, 3)),
  4: prem(map-get($layout-scale-px, 4)),
  5: prem(map-get($layout-scale-px, 5)),
  6: prem(map-get($layout-scale-px, 6)),
  7: prem(map-get($layout-scale-px, 7)),
  8: prem(map-get($layout-scale-px, 8)),
  9: prem(map-get($layout-scale-px, 9)),
  10: prem(map-get($layout-scale-px, 10)),
  11: prem(map-get($layout-scale-px, 11)),
  12: prem(map-get($layout-scale-px, 12)),
  13: prem(map-get($layout-scale-px, 13)),
  14: prem(map-get($layout-scale-px, 14)),
  15: prem(map-get($layout-scale-px, 15)),
);

// Visuals
// ------------------------------------------

$border-radius: (
  1: 2px,
  2: 6px,
  3: 9px,
);

$border-radius-ui: map-get($border-radius, 1);
$border-radius-ui-charcoal: map-get($border-radius, 2);

$border-width: (
  1: 1px,
  2: 2px,
  3: 3px,
  4: 5px,
);

$color-shadow: map-get($color-grey, 10);

$shadow-depth: (
  1: #{0 0.75px 6px rgba($color-shadow, 0.05),
  0 1px 1px rgba($color-shadow, 0.05)},
  2: #{0 1.5px 15px rgba($color-shadow, 0.075),
  0 1px 2px rgba($color-shadow, 0.05)},
  3: #{0 5px 18px rgba($color-shadow, 0.08),
  0 2px 10px rgba($color-shadow, 0.05)},
  4: #{0 7px 30px rgba($color-shadow, 0.095),
  0 4px 20px rgba($color-shadow, 0.05)},
  5: #{0 10px 40px rgba($color-shadow, 0.09),
  0 7px 25px rgba($color-shadow, 0.05)},
  6: #{0 20px 60px rgba($color-shadow, 0.075),
  0 10px 50px rgba($color-shadow, 0.05)},
);

$shadow-edge-spread: 3rem;

$perspective: 25rem;

$text-shadow: 0 0 1px rgba(0, 0, 0, 0.2);

$shadow-floating-ui-light: 0px 15px 60px rgba(0, 0, 0, 0.3);
$shadow-floating-ui-dark: 0px 15px 60px #00000094;

// Motion
// ------------------------------------------

$easing-fastInSlowOut: cubic-bezier(0.075, 0.82, 0.165, 1);
$easing-bounce: cubic-bezier(0.125, 0.98, 0.565, 1.17);
$easing-elastic: cubic-bezier(0.87, -0.41, 0.19, 1.44);
$easing-heavyBounce: cubic-bezier(0.125, 0.98, 0.565, 1.17);
$easing-smooth: cubic-bezier(0.075, 0.82, 0.165, 1);
$smcalc-curve: cubic-bezier(0.165, 0.84, 0.44, 1);
