.c-heading {
  @include c_heading();
}

.c-heading--secondary {
  @include c_heading__secondary();
}

.c-heading--high {
  @include c_heading__high();
}

@include c_heading__optic_correction();

.c-heading--dark {
  @include c_heading__dark();
}

.c-heading--light {
  @include c_heading__light();
}

.c-heading--bottom-close {
  @include c_heading__bottom_close();
}

.c-heading--bottom-medium {
  @include c_heading__bottom_medium();
}

.c-heading--bottom-far {
  @include c_heading__bottom_far();
}

.c-heading--top-close {
  @include c_heading__top_close();
}

.c-heading--top-medium {
  @include c_heading__top_medium();
}

.c-heading--top-far {
  @include c_heading__top_far();
}

// Responsive sizing
// ------------------------------------------

.c-heading--1 {
  @extend .f4;
  @extend .f5-sm;
  @extend .f6-md;
  @extend .f7-xl;
}

.c-heading--2 {
  @extend .f5;
  @extend .f6-sm;
  @extend .f7-md;
  @extend .f8-xl;
}

.c-heading--3 {
  @extend .f6;
  @extend .f7-sm;
  @extend .f8-md;
  @extend .f9-xl;
}

.c-heading--4 {
  @extend .f7;
  @extend .f8-sm;
  @extend .f9-md;
  @extend .f10-xl;
}

.c-heading--5 {
  @extend .f8;
  @extend .f9-sm;
  @extend .f10-md;
  @extend .f11-xl;
}


// Tighten letter-spacing as headings get bigger for display
// ------------------------------------------



.c-heading {
  &.f10,
  &.f10-lg,
  &.f10-lg-xl,
  &.f10-xl {
    letter-spacing: -0.03em;
    line-height: 1;
  }
}

.c-heading {
  &.f11,
  &.f11-lg,
  &.f11-lg-xl,
  &.f11-xl {
    letter-spacing: -0.035em;
    line-height: 1;
  }
}
.c-heading {
  &.f12,
  &.f12-lg,
  &.f12-lg-xl,
  &.f12-xl {
    letter-spacing: -0.035em;
    line-height: 1;
  }
}
.c-heading {
  &.f13,
  &.f13-lg,
  &.f13-lg-xl,
  &.f13-xl {
    letter-spacing: -0.04em;
    line-height: 0.98;
  }
}
.c-heading {
  &.f14,
  &.f14-lg,
  &.f14-lg-xl,
  &.f14-xl {
    letter-spacing: -0.045em;
  }
}
.c-heading {
  &.f15,
  &.f15-lg,
  &.f15-lg-xl,
  &.f15-xl {
    letter-spacing: -0.05em;
    line-height: 0.95;
  }
}



