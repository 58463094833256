.c-rule {
  @include c_rule();
}

.c-rule--dark {
  @include c_rule__dark();
}

.c-rule--light {
  @include c_rule__light();
}


// Size variants
// Uses copy base sizing to alter relative margins
// ------------------------------------------

.c-rule--1 {
  @extend .c-copy--1;
}

.c-rule--2 {
  @extend .c-copy--2;
}

.c-rule--3 {
  @extend .c-copy--3;
}

.c-rule--article {
  @extend .c-copy--article;
}