@mixin tachyons_heights($suffix:'') {

  // Height Scale
  .h1#{$suffix} { height: map-get($layout-scale-rem, 3); }
  .h2#{$suffix} { height: map-get($layout-scale-rem, 5); }
  .h3#{$suffix} { height: map-get($layout-scale-rem, 7); }
  .h4#{$suffix} { height: map-get($layout-scale-rem, 8); }
  .h5#{$suffix} { height: map-get($layout-scale-rem, 9); }

  .min-h1#{$suffix} { min-height: map-get($layout-scale-rem, 3); }
  .min-h2#{$suffix} { min-height: map-get($layout-scale-rem, 5); }
  .min-h3#{$suffix} { min-height: map-get($layout-scale-rem, 7); }
  .min-h4#{$suffix} { min-height: map-get($layout-scale-rem, 8); }
  .min-h5#{$suffix} { min-height: map-get($layout-scale-rem, 9); }
  .min-h6#{$suffix} { min-height: map-get($layout-scale-rem, 10); }


  // Height Percentages - Based off of height of parent
  .h-25#{$suffix} {  height:  25%; }
  .h-50#{$suffix} {  height:  50%; }
  .h-75#{$suffix} {  height:  75%; }
  .h-100#{$suffix} { height: 100%; }
  .min-h-100#{$suffix} { min-height: 100%; }

  // Screen Height Percentage
  .vh-100#{$suffix} { height: 100vh; }
  .min-vh-100#{$suffix} { min-height: 100vh; }

  // String Properties
  .h-auto#{$suffix}    {  height: auto; }
  .h-auto-force#{$suffix}    {  height: auto; }
  .h-inherit#{$suffix} {  height: inherit; }
}



// Create standard classes
// ------------------------------------------

@include tachyons_heights();



// Create MQ extensions
// ------------------------------------------

@each $bp, $mq in $bp-widths-min {
    @media (min-width: #{$mq}) {
      @include tachyons_heights(-#{$bp});
    }
}

