.lg-outer {
    .lg-video-cont {
        display: inline-block;
        vertical-align: middle;
        max-width: 1140px;
        max-height: 100%;
        width: 100%;
        padding: 0 5px;
    }

    .lg-video {
        width: 100%;
        height: 0;
        padding-bottom: 56.25%;
        position: relative;

        .lg-object {
            display: inline-block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100% !important;
            height: 100% !important;
        }

        .lg-video-play {
            width: 84px;
            height: 59px;
            position: absolute;
            left: 50%;
            top: 50%;
            margin-left: -42px;
            margin-top: -30px;
            z-index: 1080;
            cursor: pointer;
        }
    }

    .lg-has-vimeo{
      .lg-video-play{
        // This is where you can place a custom play icon for Vimeo
      }
    }

    .lg-has-html5{
      .lg-video-play{
        // This is where you can place a custom play icon for html5 video

        height: 64px;
        margin-left: -32px;
        margin-top: -32px;
        width: 64px;
        opacity: 0.8;
      }
    }

    .lg-has-youtube{
      .lg-video-play{
        // This is where you can place a custom play icon for Youtube
      }
    }
    .lg-video-object {
        width: 100% !important;
        height: 100% !important;
        position: absolute;
        top: 0;
        left: 0;
    }

    .lg-has-video {
        .lg-video-object {
            visibility: hidden;
        }

        &.lg-video-playing {
            .lg-object, .lg-video-play {
                display: none;
            }

            .lg-video-object {
                visibility: visible;
            }
        }
    }
}

