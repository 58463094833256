.c-hamburger {
  @extend .f6;

  display: block;
  position: relative;

  width: 1em;
  height: 0.6em;
  min-height: 14px; // for pixel rounding

  transition: transform $easing-smooth 400ms;
  transform-origin: center;
}

.c-hamburger__bar {
  position: absolute;
  left: 0;
  width: 100%;
  height: 0.07em;
  height: 2px; // for pixel rounding

  border-radius: 2px;

  transition:
    opacity linear 200ms,
    transform $easing-smooth 500ms;
}

.c-hamburger__bar--top {
  top: 0;
}

.c-hamburger__bar--middle {
  top: 50%;
  transform: translate(0, -50%);
}

.c-hamburger__bar--bottom {
  bottom: 0;
}

// States

.c-hamburger--open {
  transform: rotate(-90deg);
  transform-origin: center;

  .c-hamburger__bar--top {
    transform: translateY(0.55em);
  }

  .c-hamburger__bar--middle {
    opacity: 0;
  }

  .c-hamburger__bar--bottom {
    transform: translateY(-0.55em);
  }
}



