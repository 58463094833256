@mixin tachyons_coordinates($suffix:'') {
  .top-0#{$suffix}    { top:    0; }
  .right-0#{$suffix}  { right:  0; }
  .bottom-0#{$suffix} { bottom: 0; }
  .left-0#{$suffix}   { left:   0; }

  .top-1#{$suffix}    { top:    map-get($layout-scale-rem, 3); }
  .right-1#{$suffix}  { right:  map-get($layout-scale-rem, 3); }
  .bottom-1#{$suffix} { bottom: map-get($layout-scale-rem, 3); }
  .left-1#{$suffix}   { left:   map-get($layout-scale-rem, 3); }

  .top-2#{$suffix}    { top:    map-get($layout-scale-rem, 5); }
  .right-2#{$suffix}  { right:  map-get($layout-scale-rem, 5); }
  .bottom-2#{$suffix} { bottom: map-get($layout-scale-rem, 5); }
  .left-2#{$suffix}   { left:   map-get($layout-scale-rem, 5); }

  .top-3#{$suffix}    { top:    map-get($layout-scale-rem, 8); }
  .right-3#{$suffix}  { right:  map-get($layout-scale-rem, 8); }
  .bottom-3#{$suffix} { bottom: map-get($layout-scale-rem, 8); }
  .left-3#{$suffix}   { left:   map-get($layout-scale-rem, 8); }

  .top--1#{$suffix}    { top:    -#{map-get($layout-scale-rem, 3)}; }
  .right--1#{$suffix}  { right:  -#{map-get($layout-scale-rem, 3)}; }
  .bottom--1#{$suffix} { bottom: -#{map-get($layout-scale-rem, 3)}; }
  .left--1#{$suffix}   { left:   -#{map-get($layout-scale-rem, 3)}; }

  .top--2#{$suffix}    { top:    -#{map-get($layout-scale-rem, 5)}; }
  .right--2#{$suffix}  { right:  -#{map-get($layout-scale-rem, 5)}; }
  .bottom--2#{$suffix} { bottom: -#{map-get($layout-scale-rem, 5)}; }
  .left--2#{$suffix}   { left:   -#{map-get($layout-scale-rem, 5)}; }

  .top--3#{$suffix}    { top:    -#{map-get($layout-scale-rem, 8)}; }
  .right--3#{$suffix}  { right:  -#{map-get($layout-scale-rem, 8)}; }
  .bottom--3#{$suffix} { bottom: -#{map-get($layout-scale-rem, 8)}; }
  .left--3#{$suffix}   { left:   -#{map-get($layout-scale-rem, 8)}; }

  .top-auto#{$suffix}    { top:    auto; }
  .right-auto#{$suffix}  { right:  auto; }
  .bottom-auto#{$suffix} { bottom: auto; }
  .left-auto#{$suffix}   { left:   auto; }

  .top-50#{$suffix}  { top: 50%; }

  .left--5#{$suffix}  { left: -5%; }
  .left--10#{$suffix} { left: -10%; }
  .left--15#{$suffix} { left: -15%; }
  .left--20#{$suffix} { left: -20%; }
  .left--30#{$suffix} { left: -30%; }

  .right--5#{$suffix}  { right: -5%; }
  .right--10#{$suffix} { right: -10%; }
  .right--15#{$suffix} { right: -15%; }
  .right--20#{$suffix} { right: -20%; }
  .right--30#{$suffix} { right: -30%; }

  .absolute--fill#{$suffix} {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .absolute--em-centered#{$suffix} {
    position: absolute;

    left: 50%;
    top: 50%;

    margin-top: -.5em;
    margin-left: -.5em;
  }

  .absolute--center#{$suffix} {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .absolute--center-y#{$suffix} {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
  }

  .absolute--center-x#{$suffix} {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

}


// Create standard classes
// ------------------------------------------

@include tachyons_coordinates();



// Create MQ extensions
// ------------------------------------------

@each $bp, $mq in $bp-widths-min {
    @media (min-width: #{$mq}) {
      @include tachyons_coordinates(-#{$bp});
    }
}
