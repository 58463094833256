@mixin tachyons_max_widths($suffix:'') {

  // Max Width Viewport
  .mw-78vw#{$suffix}  { max-width: 78vw; }

  // Max Width Percentages
  .mw-100#{$suffix}  { max-width: 100%; }
  .mw-90#{$suffix}   { max-width: 90%; }
  .mw-80#{$suffix}   { max-width: 80%; }
  .mw-50#{$suffix}   { max-width: 50%; }
  .mw-60#{$suffix}   { max-width: 60%; }
  .mw-25#{$suffix}   { max-width: 25%; }

  // Max Width Scale
  .mw1#{$suffix} { max-width: map-get($layout-scale-rem, 3); } // 16px
  .mw2#{$suffix} { max-width: map-get($layout-scale-rem, 5); } // 32px
  .mw3#{$suffix} { max-width: map-get($layout-scale-rem, 7); } // 64px
  .mw4#{$suffix} { max-width: map-get($layout-scale-rem, 8); } // 128px
  .mw5#{$suffix} { max-width: map-get($layout-scale-rem, 9); } // 256px
  .mw6#{$suffix} { max-width: map-get($layout-scale-rem, 10); } // 512px
  .mw7#{$suffix} { max-width: map-get($layout-scale-rem, 11); } // 768px
  .mw8#{$suffix} { max-width: map-get($layout-scale-rem, 12); } // 1024px
  .mw9#{$suffix} { max-width: map-get($layout-scale-rem, 13); } // 1280px
  .mw10#{$suffix} { max-width: map-get($layout-scale-rem, 14); } // 1536px

  // Max Width String Properties */
  .mw-none#{$suffix} { max-width: none; }
}



// Create standard classes
// ------------------------------------------

@include tachyons_max_widths();



// Create MQ extensions
// ------------------------------------------

@each $bp, $mq in $bp-widths-min {
    @media (min-width: #{$mq}) {
      @include tachyons_max_widths(-#{$bp});
    }
}

