
@mixin tachyons_css_filters($suffix:'') {
  .filter-grayscale-100#{$suffix} { filter: grayscale(1); }
}

// Create standard classes
// ------------------------------------------

@include tachyons_css_filters();


// Create MQ extensions
// ------------------------------------------

// @each $bp, $mq in $bp-widths-min {
//   @media (min-width: #{$mq}) {
//     @include tachyons_css_filters($suffix: -#{$bp});
//   }
// }



