/*

  components/testimonial/c-quote.scss

  Common quote

*/


// Arrangement container
// ------------------------------------------

.c-quote {
  position: relative;

  min-width: prem(200px);

  @media (min-width: bp-width-min('xs')) {
    min-width: prem(400px);
  }
}

.c-quote__avatar {
  font-size: prem(80px);
  background-position: center;
  background-size: cover;

  border-radius: 50%;

  width: 1em;
  height: 1em;

  border: prem(5px) solid $color-white;

  box-shadow: map-get($shadow-depth, 1);

  @media (max-width: bp-width-max('sm')) {
    margin: auto;
  }

  @media (min-width: bp-width-min('sm')) {
    position: absolute;

    top: 0;
    left: 0;
  }
}

.c-quote__details {
  @media (max-width: bp-width-max('sm')) {
    margin-top: prem(30px);
  }
  @media (min-width: bp-width-min('sm')) {
    padding-left: prem(120px);
  }
}

.c-quote__author {
  position: relative;

  margin-top: 1rem;

  @media (min-width: bp-width-min('sm')) {
    display: flex;
  }
}

.c-quote__logo {
  max-height: 1.5rem;

  @media (max-width: bp-width-max('sm')) {
    display: block;
    margin: auto;

    margin-bottom: prem(15px);
  }
}