@mixin tachyons_font_style($suffix:'') {
  .fs-italic#{$suffix} { font-style: italic; }
  .fs-normal#{$suffix} { font-style: normal; }
}


// Create standard classes
// ------------------------------------------

@include tachyons_font_style();



// Create MQ extensions
// ------------------------------------------

// @each $bp, $mq in $bp-widths-min {
//     @media (min-width: #{$mq}) {
//       @include tachyons_font_style(-#{$bp});
//     }
// }

