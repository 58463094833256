
// The Tooltip
// ------------------------------------------

.c-tooltip {
  @extend .absolute--center-x;
  @extend .br-ui-charcoal;
  @extend .shadow-atlassian;
  @extend .c-mode-light;

  min-width: 200px;

  padding: map-get($layout-scale-rem, 4);
  display: block;
  background-color: $color-white;


  &::before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    width: 100%;
    height: 20px;
  }

  @include nubbin();
}

.c-tooltip.c-tooltip--dark {
  background-color: map-get($color-grey, 8);
  @extend .shadow-floating-ui-dark;
}

// State Above
.c-tooltip--above {
  bottom: calc(100% + 0.5rem);
  @include nubbin--below();

  &::before {
    bottom: -20px;
  }
}

.c-tooltip--above.c-tooltip--dark {
  @include nubbin--below(map-get($color-grey, 8));
}

// State Below
.c-tooltip--below {
  top: calc(100% + 0.5rem);
  @include nubbin--above();

  &::before {
    top: -20px;
  }
}

.c-tooltip--below.c-tooltip--dark {
  @include nubbin--above(map-get($color-grey, 8));
}


// Type elements provided for basic use-case
// ------------------------------------------

.c-tooltip__heading {
  @extend .c-heading;
  @extend .mb2;
  @extend .f3;
  display: block;
}

.c-tooltip__copy {
  @extend .c-copy;
  @extend .f2;
  display: block;
}

