

// Standard
// ------------------------------------------

@mixin c_heading() {
  font-style: normal;
  font-weight: map-get($font-weight, 600);

  line-height: map-get($line-height, "tight");
  letter-spacing: map-get($letter-spacing, "tight");

  color: map-get($color-grey, 10);
}


// Alternative 'light' headings
// ------------------------------------------

@mixin c_heading__secondary() {
  font-weight: map-get($font-weight, 300);
}


// Alternative 'high' headings
// ------------------------------------------

@mixin c_heading__high() {
  line-height: map-get($line-height, "regular");
}


// Optical correction
// ------------------------------------------

@mixin c_heading__optic_correction() {
  .c-heading .c-heading--light-bold-optic-correction {
    strong {
      font-size: 1.07em;
    }
  }

  .c-heading--display-decreased-letter-spacing {
    letter-spacing: -0.04em;
    strong {
      letter-spacing: -0.03em;
    }
  }

  .c-heading--display-decreased-letter-spacing-extreme {
    letter-spacing: -0.05em;
    strong {
      letter-spacing: -0.04em;
    }
  }
}



// Dark mode variant
// ------------------------------------------

@mixin c_heading__dark() {
  text-shadow: $text-shadow;
  color: map-get($color-grey, 2);

  &.c-heading--secondary {
    color: map-get($color-grey, 3);
  }
}


// Light mode variant
// ------------------------------------------

@mixin c_heading__light() {
  text-shadow: none;
  color: map-get($color-grey, 7);
}


// Relative margins - Below
// ------------------------------------------

@mixin c_heading__bottom_close() {
  margin-bottom: .2em;
}

@mixin c_heading__bottom_medium() {
  margin-bottom: .6em;
}

@mixin c_heading__bottom_far() {
  margin-bottom: 1.5em;
}


// Relative margins - Above
// ------------------------------------------

@mixin c_heading__top_close() {
  margin-top: .2em;
}

@mixin c_heading__top_medium() {
  margin-top: .6em;
}

@mixin c_heading__top_far() {
  margin-top: 1.5em;
}



