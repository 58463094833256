@mixin tachyons_floats($suffix:'') {
  .fl#{$suffix} { float: left;  display: inline; }
  .fr#{$suffix} { float: right;  display: inline; }
  .fn#{$suffix} { float: none; }
}


// Create standard classes
// ------------------------------------------

@include tachyons_floats();


// Create MQ extensions
// ------------------------------------------

@each $bp, $mq in $bp-widths-min {
  @media (min-width: #{$mq}) {
    @include tachyons_floats(-#{$bp});
  }
}

