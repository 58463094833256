.c-js-expandable {

}

.c-js-expandable__inner {
  max-height: 0;
  overflow: hidden;
  transition: max-height linear;
  transition-duration: 250ms;
  -webkit-backface-visibility: hidden;
  -webkit-perspective: 1000;
}

.c-js-expandable__content {
  opacity: 0;
  transition: opacity 200ms linear;
  transition-delay: 450ms;
}

.c-js-expandable__opener {
  cursor: pointer;
}

.c-js-expandable__header-bar {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.c-js-expandable__header-bar--align-left {
  justify-content: flex-start;
}

.c-js-expandable__opener__icon {
  .c-icon {
    display: block;
    transition: transform 400ms ease-in-out;
  }
}

// active

.c-js-expandable.c-js-expandable--active {
  .c-js-expandable__content {
    opacity: 1;
    transition-delay: 0;
  }
}

.c-js-expandable__opener__icon.c-js-expandable__opener__icon--active > .c-icon {
  transform: rotate(45deg);
}


// a variant that only fires above md breakpoint

.c-js-expandable--md {
  @media (min-width: bp-width-min(md)) {
    .c-js-expandable--md__inner {
      max-height: none !important; // here we are overriding js set inline styles - other solutions are welcome
      transition: none !important;
    }

    .c-js-expandable--md__content {
      opacity: 1 !important;
      transition: none !important;
    }
  }
}