@mixin tachyons_bg_repeat($suffix:'') {
  .bg-repeat-none#{$suffix} { background-repeat: no-repeat; }
}


// Create standard classes
// ------------------------------------------

@include tachyons_bg_repeat();



// Create MQ extensions
// ------------------------------------------

// @each $bp, $mq in $bp-widths-min {
//     @media (min-width: #{$mq}) {
//       @include tachyons_bg_repeat(-#{$bp});
//     }
// }