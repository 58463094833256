/*

  sm-calculator/components/c-progress-meter.scss

*/


.c-progress-meter {
 
}

.c-progress-meter--horizontal svg:not(:root) {
  overflow: visible;
}

.c-progress-meter__bg {

}

.c-progress-meter--horizontal__line {
  transition: width 900ms $smcalc-curve 300ms;
  .c-progress-meter--zeroed & {
    @media not print {
      width: 0;
    }
  }
}

.c-progress-meter--horizontal__indicator {
  transition: transform 300ms $smcalc-curve, opacity 200ms linear;
  .c-progress-meter--zeroed & {
    @media not print {
      transform: translate3d(-1000%,0,0);
      opacity: 0;
    }
  }
}


.c-progress-meter--circular svg:not(:root) {
  overflow: visible;

  min-height: prem(300px);
  @media (min-width: bp-width-min(xl)) {
    max-width: prem(350px);
  }

  @media print {
    max-width: prem(200px);
    float: left;
  }
}

.c-progress-meter--circular__segment-marker {
  @media print {
    display: none;
  }
}

.c-progress-meter--circular__indicator {
  transition: transform 900ms $smcalc-curve;

  @media print {
    display: none;
  }

  .c-progress-meter--zeroed & {
    @media not print {
      transform: rotate(0deg) !important; // override inline style
    }
  }
}

.c-progress-meter--circular__line {
  transition: stroke-dasharray 900ms $smcalc-curve;
  .c-progress-meter--zeroed & {
    @media not print {
      stroke-dasharray: 0, 100;
    }
  }
}

.c-progress-meter--circular__central-percentage {
  text-anchor: middle;
  font-size: 0.6em;
  letter-spacing: map-get($letter-spacing, "tight");
}

.c-progress-meter--circular__central-text {
  text-anchor: middle;
  font-size: 0.1em;
  letter-spacing: 0.01em;
}

.c-progress-meter--horizontal__key-label:not(:root) {
  overflow: visible;
}