@mixin tachyons_display($suffix:'') {

  .di#{$suffix} {              display: inline; }
  .db#{$suffix} {              display: block; }
  .df#{$suffix} {              display: flex; }
  .dib#{$suffix} {             display: inline-block; }
  .dif#{$suffix} {             display: inline-flex; }
  // .dit#{$suffix} {             display: inline-table; }
  // .dt#{$suffix} {              display: table; }
  // .dtc#{$suffix} {             display: table-cell; }
  // .dt-row#{$suffix} {          display: table-row; }
  // .dt-row-group#{$suffix} {    display: table-row-group; }
  // .dt-column#{$suffix} {       display: table-column; }
  // .dt-column-group#{$suffix} { display: table-column-group; }
  // .dt--fixed#{$suffix} {
  //   table-layout: fixed;
  //   width: 100%;
  // }
  .dn#{$suffix} { display: none; }
}


// Create standard classes
// ------------------------------------------

@include tachyons_display();


// Create MQ extensions
// ------------------------------------------

@each $bp, $mq in $bp-widths-min {
    @media (min-width: #{$mq}) {
      @include tachyons_display(-#{$bp});
    }
}

