/*

  mixins/hardware-acceleration.scss

  Convenience mixin for forcing hardware acceleration on transitioned/animated items

*/

@mixin hw_acceleration() {
  -webkit-backface-visibility: hidden;
  -webkit-perspective: 1000;
}

@mixin hw_acceleration_3d() {
  transform: translate3d(0,0,0);
  -webkit-backface-visibility: hidden;
  -webkit-perspective: 1000;
}

.hw-backface {
  @include hw_acceleration();
}
.hw-accel {
  @include hw_acceleration_3d();
}

