/*

  components/icons/c-icon.scss

  Standard and consistent sizing behaviour for UI icons.

  Media Query Extensions: N/A

*/

.c-icon {
  display: inline-block;
  font-size: 1em;
}

.c-icon > svg {
  fill: currentColor; // Inherit fill color from parent container

  display: block;
  height: 1em;
  width: 1em;
}

.c-spot-icon.size-small .c-icon > svg {
  height: 0.5em;
  width: 0.5em;
}

@media (max-width: bp-width-max(md)) {
  .c-spot-icon.size-small .c-icon > svg {
    height: 0.8em;
    width: 0.8em;
  }
}
