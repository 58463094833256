// default theme
.lg-actions {

  .lg-next,
  .lg-prev {
    position: absolute;
    display: block;

    color: $color-white;

    font-size: prem(20px);

    cursor: pointer;

    margin-top: -.5em;

    padding: 1em;

    top: 50%;
    z-index: 1080;

    .c-icon {
      position: relative;

      z-index: 2;
      transition: opacity .3s linear;
      opacity: .6;
    }

    &:after {
      transition:
        opacity .3s linear,
        transform .4s $easing-bounce;

      content: "";

      position: absolute;

      width: 100%;
      height: 100%;

      top: 0;
      left: 0;

      background-color: map-get($color-grey, 10);

      border-radius: 50%;

      opacity: .7;

      z-index: 1;
    }

    &.disabled {
      pointer-events: none;
      opacity: .5;
    }

    &:hover {
      .c-icon {
        opacity: 1;
      }

      &:after {
        opacity: 1;

        transform: scale(1.3);
      }
    }

    &:active {
      &:after {
        transform: scale(1);
      }
    }
  }

  .lg-next {
    right: prem(30px);

    &:hover {
      .c-icon {
        animation-timing-function: $easing-bounce;
        animation-duration: .5s;
        animation-name: in-out-left-right;
      }
    }
  }

  .lg-prev {
    left: prem(30px);

    &:hover {
      .c-icon {
        animation-timing-function: $easing-bounce;
        animation-duration: .5s;
        animation-name: in-out-right-left;
      }
    }
  }

}

@keyframes lg-right-end {
  0% {
      left: 0;
  }

  50% {
      left: -30px;
  }

  100% {
      left: 0;
  }
}


@keyframes lg-left-end {
  0% {
      left: 0;
  }

  50% {
      left: 30px;
  }

  100% {
      left: 0;
  }
}


.lg-outer {
  &.lg-right-end {
    .lg-object {
      animation-name: lg-right-end;
      animation-duration: .3s;
      position: relative;
    }
  }

  &.lg-left-end {
    .lg-object {
      animation-name: lg-left-end;
      animation-duration: .3s;
      position: relative;
    }
  }
}

// lg icon
.lg-icon {
  line-height: 1;

  .c-icon {
    display: block;
  }
}

// lg toolbar
.lg-toolbar {
  position: absolute;

  z-index: 1082;
  left: 0;

  top: 0;
  width: 100%;
  background-color: rgba(map-get($color-grey, 10), 0.9);

  padding-left: prem(10px);
  padding-right: prem(10px);

  .lg-icon {
    transition: opacity .2s linear;

    color: $color-white;

    opacity: .4;

    cursor: pointer;
    float: right;
    font-size: prem(16px);
    padding: prem(20px) prem(10px);

    text-decoration: none !important;
    outline: medium none;

    &:hover {
      opacity: 1;
    }
  }
}

// lightGallery title
.lg-sub-html {
  background-color: rgba(0, 0, 0, 0.45);
  bottom: 0;
  color: #EEE;
  font-size: 16px;
  left: 0;
  padding: 10px 40px;
  position: fixed;
  right: 0;
  text-align: center;
  z-index: 1080;

  h4 {
    margin: 0;
    font-size: 13px;
    font-weight: bold;
  }

  p {
    font-size: 12px;
    margin: 5px 0 0;
  }
}

// lg image counter
#lg-counter {
  color: map-get($color-grey, 5);

  line-height: 1;

  font-size: prem(16px);

  padding-top: prem(20px);
  padding-bottom: prem(20px);

  padding-left: prem(10px);
}

// for idle state
.lg-toolbar,
.lg-prev,
.lg-next {
  transition:
    transform .35s cubic-bezier(0, 0, .25, 1) 0s,
    opacity .35s cubic-bezier(0, 0, .25, 1) 0s,
    color .2s linear;

  opacity: 1;
}

.lg-hide-items {
  .lg-prev {
    opacity: 0;
    transform: translate3d(-10px, 0, 0);
  }

  .lg-next {
    opacity: 0;
    transform: translate3d(10px, 0, 0);
  }

  .lg-toolbar {
    opacity: 0;
    transform: translate3d(0, -10px, 0);
  }
}


// Asset loading effect (After spinner)
// ------------------------------------------

body:not(.lg-from-hash){
  .lg-outer {
    &.lg-core-intro {

      // Initial set-up
      .lg-object {
        transition:
            transform 1.5s $easing-fastInSlowOut,
            opacity 1.5s $easing-fastInSlowOut;

        opacity: 0;
        transform: scale(.75);
      }

      .lg-item.lg-complete {
        .lg-object{
          opacity: 1;
          transform: rotateX(0) rotateY(0) scale(1);
        }
      }
    }
  }
}

