@mixin tachyons_max_heights($suffix:'') {

  // Max height Scale
  .mh1#{$suffix} { max-height: map-get($layout-scale-rem, 3); }
  .mh2#{$suffix} { max-height: map-get($layout-scale-rem, 5); }
  .mh3#{$suffix} { max-height: map-get($layout-scale-rem, 6); }
  .mh4#{$suffix} { max-height: map-get($layout-scale-rem, 7); }
  .mh5#{$suffix} { max-height: map-get($layout-scale-rem, 8); }
  .mh6#{$suffix} { max-height: map-get($layout-scale-rem, 9); }

  // Max height String Properties */
  .mh-none#{$suffix} { max-height: none; }

}



// Create standard classes
// ------------------------------------------

@include tachyons_max_heights();



// Create MQ extensions
// ------------------------------------------

@each $bp, $mq in $bp-widths-min {
    @media (min-width: #{$mq}) {              
      @include tachyons_max_heights(-#{$bp});
    }
}