/*

  components/c-form.scss

  Common form layout

*/

$form-max-width: 29rem;

.c-form {
  max-width: $form-max-width;

  margin: auto;
  display: block;

  text-align: left;
  position: relative;
}


// Loader icon
// ------------------------------------------

.c-form__loader {
  @extend .absolute--fill;

  position: absolute;

  visibility: hidden;
  opacity: 0;

  font-size: 2em;

  color: map-get($color-grey, 7);

  transition: opacity 0.3s ease-in 0s, visibility 0.3s linear 0.3s;
}

// Icon
.c-form__loader__icon {
  @extend .absolute--em-centered;

  animation: spin-clockwise .3s linear 0s infinite;
}

.c-form--loading .c-form__loader {
  visibility: visible;
  opacity: 1;

  transition-delay: 0s, 0s;
}


// Content - the fields
// ------------------------------------------

.c-form__content {
  transition: opacity 0.3s ease-in;
}

.c-form--loading .c-form__content {
  opacity: 0;
  pointer-events: none;
}


// Standard form set
// ------------------------------------------

.c-form-set:not(:last-of-type) {
  @extend .mb3;
}

fieldset .c-form-set:last-of-type {
  margin-bottom: 0;
}


// Action button divider
// ------------------------------------------

.c-form-set--action {
  @extend .mt4;
  @extend .mt5-xl;
  // margin-top: map-get($layout-scale-rem, 5);
  padding-top: map-get($layout-scale-rem, 5);

  border-top: map-get($border-width, 1) solid map-get($color-grey, 3);
}


// Hidden form item
// ------------------------------------------

.c-form-set--hidden {
  display: none;
}

.c-form-set--squished {
  height: 0;
  overflow: hidden;
  margin: 0 !important;
  padding: 0!important;
  border: 0;
}
// Visual variants
// ------------------------------------------

.c-form--dark {
  .c-form__loader {
    color: map-get($color-grey, 2);
  }

  .c-label {
    color: map-get($color-grey, 4);
  }

  .c-toggle__label {
    color: map-get($color-grey, 4);
  }

  .c-toggle__native {
    @extend .c-toggle__native--dark;
  }

  .c-form-set--action,
  .c-form-subheading {
    border-color: map-get($color-grey, 6);
  }
}


// Enclosed form
// ------------------------------------------

.c-form-enclosed {
  @extend .pa4;
  @extend .pa5-sm;
  @extend .shadow-atlassian;

  max-width: $form-max-width;

  border-radius: $border-radius-ui;

  overflow: hidden;

  background-color: $color-white;

  margin-left: auto;
  margin-right: auto;

  .c-form-set--action {
    margin-left: -#{map-get($layout-scale-rem, 4)};
    margin-right: -#{map-get($layout-scale-rem, 4)};
    padding-left: #{map-get($layout-scale-rem, 4)};
    padding-right: #{map-get($layout-scale-rem, 4)};

    @media (min-width: bp-width-min('sm')) {
      margin-left: -#{map-get($layout-scale-rem, 5)};
      margin-right: -#{map-get($layout-scale-rem, 5)};
      padding-left: #{map-get($layout-scale-rem, 5)};
      padding-right: #{map-get($layout-scale-rem, 5)};
    }
  }

  // Modify standard form props
  .c-form {
    max-width: none;
    width: 100%;
  }
}

.c-form-enclosed--wide {
  max-width: 35em;
}

.c-form-enclosed--dark {
  background-color: map-get($color-grey, 9);
}
