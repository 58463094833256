/*

  components/c-twitter-embed.scss

  Style overrides for Twitter oembed

*/


// Scoping container
// ------------------------------------------

.c-twitter-embed {
  min-width: prem(300px);
  max-width: prem(425px);

  .twitter-tweet {
    width: 100% !important; // overide Twitter inline style
    min-width: 0 !important; // overide Twitter inline style
    margin: 0 !important; // overide Twitter inline style
    max-width: 100% !important; // overide Twitter inline style

    .js & {
      opacity: 0;
    }
    
    .js &:not(blockquote) {

      @keyframes fadein {
        0% {
          opacity: 0;
          transform: translate3d(0,0.2rem,0);
        }
        100% {
          opacity: 1;
          transform: translate3d(0,0,0);
        }
      }

      animation-name: fadein;
      animation-duration: 1s; 
      animation-iteration-count: 1;
      animation-timing-function: linear;
      animation-fill-mode: forwards;
      animation-delay: 1s;
    }
  }
}
