.c-tertiary {
  @include c_tertiary();
}


// Dark mode variant
// ------------------------------------------

.c-tertiary--dark {
  @include c_tertiary__dark();
}

.c-tertiary--1 {
  @extend .f1;
  @extend .f2-sm;
  @extend .f3-xxl;
}

.c-tertiary--2 {
  @extend .f2;
  @extend .f3-sm;
  @extend .f4-xxl;
}

.c-tertiary--3 {
  @extend .f3;
  @extend .f4-sm;
  @extend .f5-xxl;
}

