/*

  components/form/c-validation-msg.scss

  To manage validation highlighting and message vis

*/


// Message
// ------------------------------------------

.c-validation-msg {
  @extend .f2;
  @extend .f3-xl;

  display: block;

  height: 0;

  overflow: hidden;

  color: map-get($color-ui, "error");
}

.c-validation-msg__text {
  transition:
    opacity .3s linear,
    transform 1s $easing-fastInSlowOut;

  opacity: 0;
  transform: translate3d(0,-100%,0);

  display: block;
}


// Visual state
// ------------------------------------------

.s-validation-error {

  .c-input:not(:disabled) {
    @include field_error_style;
  }

  .c-select .c-select__native:not(:disabled) {
    @include field_error_style;
  }

  .c-textarea:not(:disabled) {
    @include field_error_style;
  }

  .c-toggle__native:not(:disabled):not(:checked) ~ .c-toggle__faux-cover {
    @include field_error_style;
  }

  // Messages
  .c-validation-msg {
    margin-top: map-get($layout-scale-rem, 2);

    height: auto;
  }

  .c-validation-msg__text {
    opacity: 1;
    transform: translate3d(0,0,0);
  }
}

.s-validation-ok {
  .c-input:not(:disabled) {
    @include field_validation_ok_style;
  }
}


