/*

  components/form/c-label.scss

  Form field lael

*/

.c-label {
  @extend .f2;
  @extend .f3-xxl;
  display: block;
  color: map-get($color-grey, 6);
  line-height: 1.3;
  margin-bottom: .4em;
  font-weight: map-get($font-weight, 600);
  cursor: text;
}