/*

  components/cards/c-card.scss

  Content cards

*/


// Arrangement container
// ------------------------------------------

.c-cards {
  @media (max-width: bp-width-max(md)) {
    margin-top: -#{map-get($layout-scale-rem, 2)};
    margin-bottom: -#{map-get($layout-scale-rem, 2)};
  }

  @media (min-width: bp-width-min(md)) {
    margin-left: -#{map-get($layout-scale-rem, 2)};
    margin-right: -#{map-get($layout-scale-rem, 2)};
  }

  @media (min-width: bp-width-min(lg)) {
    margin-left: -#{map-get($layout-scale-rem, 3)};
    margin-right: -#{map-get($layout-scale-rem, 3)};
  }
}


// Row control
// ------------------------------------------

.c-cards__row {
  @media (min-width: bp-width-min(md)) {
    display: flex;

    justify-content: center;
    
    flex-wrap: wrap;

    flex-direction: row;

    width: 100%;
  }
}

.c-cards__row--left {
  @media (min-width: bp-width-min(md)) {
    justify-content: left;
  }
}


// Actual card item
// ------------------------------------------

.c-cards__item {
  position: relative;

  padding-top: map-get($layout-scale-rem, 2);
  padding-bottom: map-get($layout-scale-rem, 2);

  @media (min-width: bp-width-min(md)) {
    display: flex;

    flex: 1 0 0%;

    max-width: prem(400px);

    width: 100%;

    padding-left: map-get($layout-scale-rem, 2);
    padding-right: map-get($layout-scale-rem, 2);

    // IE 11 Hack - Removes flexibility of cards in favour of correct centering (No better solution possible without static widths and performing logic in every template)
    _:-ms-fullscreen &,
    :root & {
      flex: auto;
    }
  }

  @media (min-width: bp-width-min(lg)) {
    padding-top: map-get($layout-scale-rem, 3);
    padding-bottom: map-get($layout-scale-rem, 3);

    padding-left: map-get($layout-scale-rem, 3);
    padding-right: map-get($layout-scale-rem, 3);
  }

  .c-cards & {
    @media (min-width: bp-width-min(md)) {
      max-width: 29%;
    }

    @media (min-width: bp-width-min(lg)) {
      max-width: 28%;
    }

    @media (min-width: bp-width-min(xl)) {
      max-width: 24%;
    }

    @media (min-width: bp-width-min(xxl)) {
      max-width: 25%;
    }
  }
}

.c-cards__item__hitbox {
  display: block;

  transition: transform 1.5s $easing-fastInSlowOut;

  position: relative;

  cursor: pointer;

  margin-left: auto;
  margin-right: auto;

  width: 100%;

  &:after,
  &:before {
    transition: opacity 1.5s $easing-fastInSlowOut;

    content: "";

    position: absolute;

    border-radius: $border-radius-ui;

    width: 100%;
    height: 100%;

    left: 0;
    top: 0;

    z-index: 1;
  }

  &:before {
    @extend .shadow-1;

    opacity: 1;
  }

  &:after {
    @extend .shadow-3;

    opacity: 0;
  }

  &:hover {
    transform: translate3d(0,-.25rem,0);

    &:before {
      opacity: 0;
    }

    &:after {
      opacity: 1;
    }
  }
}

.c-cards__item__bg-color {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  border-radius: $border-radius-ui;
}


// Featured image
// ------------------------------------------

.c-cards__item__img {
  position: relative;

  z-index: 2;

  background-color: map-get($color-grey, 10);

  overflow: hidden;

  border-top-left-radius: $border-radius-ui;

  @media (max-width: bp-width-max(md)) {
    border-bottom-left-radius: $border-radius-ui;
  }

  @media (max-width: bp-width-max(xs)) {
    width: 35%;
  }

  @media (min-width: bp-width-min(xs)) and (max-width: bp-width-max(md)) {
    width: 30%;
  }

  @media (max-width: bp-width-max(md)) {
    position: absolute;

    left: 0;
    top: 0;

    height: 100%;
  }

  @media (min-width: bp-width-min(md))  {
    border-top-left-radius: $border-radius-ui;
    border-top-right-radius: $border-radius-ui;
  }
}

.c-cards__item__img__reserve {
  transition: opacity 1.5s $easing-fastInSlowOut;

  padding-top: 80%;

  background-position: center;
  background-size: cover;

  @media (max-width: bp-width-max(md)) {
    position: absolute;

    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    
    padding: 0;
  }
}


// Details
// ------------------------------------------

.c-cards__item__details {
  @extend .px4;
  @extend .pt4;
  

  @extend .px5-sm;
  @extend .pt5-sm;
  

  @extend .pa6-lg;

  position: relative;

  z-index: 2;

  @media (max-width: bp-width-max(xs)) {
    margin-left: 35%;
  }

  @media (min-width: bp-width-min(xs)) and (max-width: bp-width-max(md)) {
    margin-left: 30%;
  }

  @media (max-width: bp-width-max(md)) {
    border-top-left-radius: $border-radius-ui;
    border-bottom-right-radius: $border-radius-ui;
  }

  @media (max-width: bp-width-max(xs)), (min-width: bp-width-min(md))  {
    border-bottom-left-radius: $border-radius-ui;
    border-bottom-right-radius: $border-radius-ui;
  }
}

.c-cards__item__details--standard {
  @extend .pb4;
  @extend .pb5-sm;
}

.c-cards__item__details--tight {
  @extend .pb3;
  @extend .pb3-sm;
}


// Wrapper to assist button sticking
.c-cards__item__details__btn-space {
  @extend .pb7-md;
}

// Context label
.c-cards__item__label {
  @extend .c-tertiary;
  @extend .c-tertiary--1;

  margin-bottom: .8em;
}

// Main heading
.c-cards__item__heading {
  @extend .c-heading;
  @extend .f5;
  @extend .f6-xxl;
}

.c-cards__item__btn-pos {
  @extend .pl4;
  @extend .pr4;
  @extend .pb4;

  @extend .pl5-sm;
  @extend .pr5-sm;
  @extend .pb5-sm;

  @extend .pt5-md;

  @extend .pa6-lg;

  position: relative;

  z-index: 5;

  @media (min-width: bp-width-min(md)) {
    position: absolute;
    left: 0;
    bottom: 0;
  }

  @media (max-width: bp-width-max(xs)) {
    margin-left: 35%;
  }

  @media (min-width: bp-width-min(xs)) and (max-width: bp-width-max(md)) {
    margin-left: 30%;
  }
}

.c-cards__item__btn {
  @extend .c-btn;
  @extend .c-btn--1;
  @extend .c-btn--2-lg;
}

.c-cards__item__video {
  position: absolute;

  top: 50%;
  left: 50%;

  margin-left: -.5em;
  margin-top: -.5em;
}


// Video item
// ------------------------------------------
.c-cards__item--video {
  .c-cards__item__img__reserve {
    opacity: .25;
  }

  .c-cards__item__hitbox:hover & {
    .c-cards__item__img__reserve {
      opacity: .5;
    }
  }
}

