// TODO re-factor

@mixin init_ov_fader_rl() {
  position: relative;
  &::after {
    content: '';
    display: block;

    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 2;

    width: 20px;
  }
}

@mixin init_ov_fader_tb() {
  position: relative;
  &::after {
    content: '';
    display: block;

    position: absolute;
    left: 0;
    right: 0;
    z-index: 2;

    height: 20px;
  }
}


@mixin u-ov-faders($suffix:'') {
  .u-ov-fader--white-rhs#{$suffix} {
    @include init_ov_fader_rl();
    &::after {
      right: 0;

      background-image:
        linear-gradient(
          to right,
          rgba(255,255,255,0), rgba(255,255,255,1)
        );
    }
  }
  .u-ov-fader--white-rhs--disabled#{$suffix} {
    &::after {
      display: none;
    }
  }


  .u-ov-fader--white-bottom#{$suffix} {
    @include init_ov_fader_tb();
    &::after {
      bottom: 0;

      background-image:
        linear-gradient(
          to bottom,
          rgba(255,255,255,0), rgba(255,255,255,1)
        );
    }
  }
  .u-ov-fader--white-bottom--disabled#{$suffix} {
    &::after {
      display: none;
    }
  }
}


// Create standard classes
// ------------------------------------------

@include u-ov-faders();


// Create MQ extensions
// ------------------------------------------

@each $bp, $mq in $bp-widths-min {
    @media (min-width: #{$mq}) {
      @include u-ov-faders(-#{$bp});
    }
}

