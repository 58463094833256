@mixin tachyons_vertical_align($suffix:'') {
  // .v-base#{$suffix}     { vertical-align: baseline; }
  .v-mid#{$suffix}      { vertical-align: middle; }
  // .v-top#{$suffix}      { vertical-align: top; }
  // .v-btm#{$suffix}      { vertical-align: bottom; }
}


// Create standard classes
// ------------------------------------------

@include tachyons_vertical_align();



// Create MQ extensions
// ------------------------------------------

@each $bp, $mq in $bp-widths-min {
    @media (min-width: #{$mq}) {
      @include tachyons_vertical_align(-#{$bp});
    }
}