/*

  components/form/c-select.scss

  A select input

*/

.c-select {
  position: relative;

  .c-icon {
    position: absolute;

    right: .9rem;
    top: .75rem;

    pointer-events: none;

    svg {
      fill: map-get($color-grey, 10);
    }
  }
}


// Native element
// ------------------------------------------

.c-select__native {
  @include touch_optimize();
  @include base_form_styles();

  cursor: pointer;

  padding-right: 2.3rem;
  padding-left: 1.1em;

  &[disabled] {
    @include disabled_input_style;
  }

  &[disabled] ~ .c-icon {
    opacity: 0.5;

    svg {
      fill: map-get($color-grey, 4);
    }
  }

  .c-select:not(.c-select--error) &:focus {
    @include field_focus_style;
  }

  // Remove arrow in ie11
  &::-ms-expand {
    display: none;
  }
}

