/*

  c-minimal-header.scss

  Minimal header object

*/

.c-minimal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;

  min-width: $doc-min-width;

  padding-top: 2.5em;

  font-size: .7em;
 
  @media (min-width: bp-width-min(xs)) {
    font-size: .8em;
  }

  @media (min-width: bp-width-min(lg)) {
    font-size: .85em;
  }

  @media (min-width: bp-width-min(xl)) {
    font-size: .9em;
  }
}


// Variant - Keep in document flow
// ------------------------------------------

.c-minimal-header--flowed {
  position: relative;
}