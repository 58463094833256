$background-color_1: #8ac539;
$background-color_2: transparent;
$background-color_3: #eeeeee;
$background-color_4: white;
$border-color_1: transparent;

@media only screen and (max-width: 1199px) {
  nav#js-mobile-nav-hamburger-toggle-selector {
    border-color: $border-color_1;
    ul#c-naav__inner {
      overflow-x: hidden;
    }
    li.l-page-gutter {
      text-align: left;
      display: block;
      width: 100%;
      font-size: 16px;
      text-decoration: none;
      line-height: 16.32px;
      padding-bottom: 0px;
      padding-top: 0px;
      cursor: pointer;
      .li-inner {
        text-align: left;
        display: inline-block;
        position: relative;
        width: 100%;
        border-bottom: 1px solid #eeeeee;
        font-size: 16px;
        &.dark {
          border-bottom: 1px solid #51505a;
        }
        .inner-wrapper {
          padding-bottom: 24px;
          padding-top: 24px;
          text-align: left;
          width: 100%;
          display: inline-block;
          font-size: 16px;
          text-decoration: none;
          .u-hover__underline {
            &:hover {
              .u-hover__underline-target {
                text-decoration: none;
              }
            }
          }
        }

        .py3 {
          padding-top: 0 !important;
          padding-bottom: 0 !important;
          padding-left: 0;
          margin-bottom: -1px;
        }
      }
      &:nth-last-child(1) {
        .li-inner {
          border-bottom: none;
        }
      }
      &:nth-last-child(2) {
        .li-inner {
          border-bottom: none;
        }
      }
      .sub-item-container {
        margin-left: -65px;
        margin-right: -65px;
        background: var(--grey-grey-2, #f9f9f9);
        ul.child-dropdown-nav {
          li {
            a {
              span {
                font-size: 16px;
              }
            }
          }
        }
      }
      a.py3 {
        padding: 0 !important;
      }
    }
    li.page-gutter.u-sibling-diminish-onHover__target {
      opacity: 0.55;
    }
    ul {
      li.show-child-menu {
        &::after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          border-bottom: 2px solid var(--grey-grey-2, #f9f9f9);
        }
        background-color: $background-color_3;
        &.dark {
          background-color: #2a282f;
          &::after {
            border-bottom: 2px solid #535563;
          }
        }
        #nav-drop-down--5 {
          border-bottom: 0px solid #eeeeee;
          display: block !important;
          background: #f9f9f9;
          margin-top: 0;
          padding-top: 0;
          padding-bottom: 0;
          margin-bottom: 0;
          .py2 {
            margin-top: 0;
            padding-top: 5px;
            font-size: 16px;
          }
        }
        #nav-drop-down--6 {
          background: #f9f9f9;
          padding-top: 1px;
          padding-bottom: 1px;
        }
        img.chevronrotate {
          transform: rotate(180deg);
        }
        .absolute-lg {
          border-bottom: 0px solid #eeeeee;
          display: block !important;
          background-color: $background-color_4;
        }
      }
      li {
        div {
          ul {
            li {
              margin: 15px 2rem 15px 2rem;
            }
          }
        }
        &:last-child.final-button {
          margin-top: 2rem;
          border-bottom: 0px solid #eeeeee;
          max-width: 247px;
          width: 100%;
          margin: 0 auto;
          a {
            margin-top: 3rem;
          }
        }
        &:last-child.final-button.color-bg-hexicon-green {
          background-color: $background-color_2;
        }
      }
      li.get-info-button {
        margin-top: 2rem;
        border-bottom: none;
        a {
          border-bottom: 1px solid #eeeeee;
          width: 100%;
          margin: 0 auto;
          //background-color: $background-color_1;
          border-radius: 5px;
          padding: 0;
          text-shadow: 0 0 1px rgba(0, 0, 0, 0.2);
        }
      }
      li.show-child-items {
        #nav-drop-down--5 {
          border-bottom: 1px solid #eeeeee;
          display: none;
        }
        .absolute-lg {
          border-bottom: 1px solid #eeeeee;
          display: none;
        }
        img.chevronrotate {
          right: 0px;
          position: absolute;
          width: 24px;
          height: auto;
        }
      }
      li.show-child-items.pb0-lg.show-child-menu {
        .sub-item-container {
          .child-dropdown-nav {
            li {
              &:nth-last-child(2) {
                margin-bottom: 0px;
                border-bottom: 0px solid #eeeeee !important;
              }
            }
          }
          ul {
            li {
              div {
                ul {
                  li {
                    border-bottom: 1px solid #eeeeee !important;
                    margin: 15px 1rem 15px 1rem;
                  }
                }
              }
            }
          }
        }
      }
    }
    li.l-page-gutter.remove-border {
      .li-inner {
        border-bottom: none;
      }
    }
    li.df.dn-lg.color-bg-ui-primary {
      background-color: $background-color_1;
      .mobile-cta-icondn-lg {
        position: relative;
      }
    }
    .mobile-nav-cta {
      text-align: center;
      display: inline-block;
      width: 100%;
      margin-left: -10px;
      padding: 8px 0;
      span {
        position: absolute;
        margin-left: 144px;
        font-size: 36px;
        margin-top: -27px;
      }
      .div {
        text-align: center;
        display: inline-block;
        width: auto;
      }
      .mobile-cta-icon {
        text-align: center;
        display: inline-block;
        width: auto;
      }
      img {
        transform: rotate(0deg);
        margin-top: -1px;
        position: absolute;
        margin-left: 10px;
      }
    }
    li.second-child-dropdown {
      p {
        font-size: 16px;
      }
      span {
        font-size: 16px;
      }
      ul {
        font-size: 16px;
      }
    }
    ul.child-dropdown-nav {
      margin-top: 0;
      li.second-child-dropdown {
        padding-top: 24px;
        padding-bottom: 24px;
        margin-bottom: 0;
        margin-top: 0;
        padding-left: 51px;
        ul {
          margin-top: 0px;
          .li-item-container {
            margin-bottom: 30px;
            position: relative;
            display: block;
          }
          li {
            margin-bottom: 15px;
            border-bottom: 0;
            margin-left: 0;
            a {
              padding-top: 5px;
              font-size: 16px;
            }
          }
        }
        &:not(:last-child) {
          a {
            .li-item-container {
              &::after {
                content: "";
                display: block;
                border-bottom: 1px solid var(--grey-grey-3, #eee);
                width: 96%;
                position: absolute;
                margin-top: 67px;
                right: 1%;
              }
              &.dark {
                &::after {
                  border-bottom: 1px solid #535563;
                }
              }
            }
          }
        }
      }
      // li {
      //   &:nth-child(5).second-child-dropdown {
      //     margin-bottom: -25px;
      //     &::after {
      //       content: "";
      //       border-bottom: 1px solid var(--grey-grey-3, #eee);
      //       width: 96%;
      //       margin-top: 4px;
      //       display: block;
      //       position: absolute;
      //       right: 1%;
      //     }
      //   }
      // }
      // &.dark-theme-dropdown {
      //   background-color: #2a2d38;
      //   li {
      //     &:nth-child(5).second-child-dropdown {
      //       margin-bottom: -25px;
      //       &::after {
      //         content: "";
      //         border-bottom: 1px solid #535563;
      //         width: 96%;
      //         margin-top: 4px;
      //         display: block;
      //         position: absolute;
      //         right: 1%;
      //       }
      //     }
      //   }
      // }
    }
    .child-dropdown-nav {
      li {
        &:nth-last-child(2) {
          margin-bottom: -40px;
          padding-bottom: 0;
          border-bottom: none;
          a {
            .li-item-container {
              &::after {
                content: "";
                display: block;
                border-bottom: 1px solid var(--grey-grey-3, #eee);
                width: 96%;
                position: absolute;
                margin-top: 83px;
                right: 1%;
              }
            }
          }
          ul {
            margin-top: 30px !important;
          }
        }
        &:last-child {
          border-bottom: none;
          padding-bottom: 0;
          ul {
            margin-top: 25px;
            margin-top: 24px !important;
          }
        }
      }
    }
    li {
      .inner-wrapper {
        span {
          font-size: 16px;
        }
      }
    }
  }
  .u-hover__underline-target {
    text-decoration: none;
  }
  .u-hover__underline {
    &:hover {
      text-decoration: none;
    }
  }
  toggle-selector {
    ul {
      li.final-button {
        a {
          background-color: $background-color_1;
        }
      }
    }
  }
  ul#c-nav__inner {
    li.parent-item {
      #nav-drop-down--0 {
        display: block;
      }
    }
  }

  .show-child-menu {
    .toggle {
      transform: rotate(90deg);
      transition: transform 0.3s;
    }
  }
  html:not(.is-touch)
    #js-mobile-nav-hamburger-toggle-selector
    .u-sibling-diminish-onHover:hover
    > .u-sibling-diminish-onHover__target:not(:hover) {
    opacity: 0.55;
  }
}

@media only screen and (max-width: 600px) {
  nav#js-mobile-nav-hamburger-toggle-selector {
    ul {
      li {
        div {
          ul {
            li {
              margin: 15px 82px;
            }
          }
        }
      }
    }
    li.l-page-gutter {
      .sub-item-container {
        margin-left: -95px;
        margin-right: -95px;
      }
    }
    ul.child-dropdown-nav {
      li.second-child-dropdown {
        padding-left: 2rem;
      }
    }
  }
}
@media only screen and (min-width: 1199px) and (max-width: 1449px) {
  #c-nav--sticky .login-cta-button .c-btn {
    padding: 0.69em 1.6em !important;
  }
}
@media only screen and (min-width: 1199px) {
  li.show-child-items {
    &:nth-last-child(2) {
      display: none;
    }
  }
  nav#js-mobile-nav-hamburger-toggle-selector {
    li.l-page-gutter {
      padding: 0 !important;
    }
    ul {
      li.show-child-items {
        img.chevronrotate {
          display: none;
        }
      }
    }
  }
}
