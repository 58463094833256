@use "sass:math";


@mixin tachyons_widths($suffix:'') {
  .w1#{$suffix} { width: map-get($layout-scale-rem, 3); } // 16px
  .w2#{$suffix} { width: map-get($layout-scale-rem, 5); } // 32px
  .w3#{$suffix} { width: map-get($layout-scale-rem, 7); } // 64px
  .w4#{$suffix} { width: map-get($layout-scale-rem, 8); } // 128px
  .w5#{$suffix} { width: map-get($layout-scale-rem, 9); } // 256px

  .w-10#{$suffix} {  width:  10%; }
  .w-15#{$suffix} {  width:  15%; }
  .w-20#{$suffix} {  width:  20%; }
  .w-25#{$suffix} {  width:  25%; }
  .w-30#{$suffix} {  width:  30%; }
  .w-33#{$suffix} {  width:  math.div(100%, 3); }
  .w-35#{$suffix} {  width:  35%; }
  .w-40#{$suffix} {  width:  40%; }
  .w-45#{$suffix} {  width:  45%; }
  .w-50#{$suffix} {  width:  50%; }
  .w-55#{$suffix} {  width:  55%; }
  .w-60#{$suffix} {  width:  60%; }
  .w-65#{$suffix} {  width:  65%; }
  .w-66#{$suffix} {  width:  math.div(100%, 1.5); }
  .w-70#{$suffix} {  width:  70%; }
  .w-75#{$suffix} {  width:  75%; }
  .w-80#{$suffix} {  width:  80%; }
  .w-85#{$suffix} {  width:  85%; }
  .w-90#{$suffix} {  width:  90%; }
  .w-100#{$suffix} { width: 100%; }
  .w-110#{$suffix} { width: 110%; }
  .w-120#{$suffix} { width: 120%; }
  .w-140#{$suffix} { width: 140%; }
  .w-150#{$suffix} { width: 150%; }
  .w-160#{$suffix} { width: 160%; }
  .w-180#{$suffix} { width: 180%; }
  .w-200#{$suffix} { width: 200%; }

  .w-auto#{$suffix} { width: auto; }

  .min-width-100#{$suffix} { min-width: 100%; }
  .min-width-auto#{$suffix} { min-width: auto; }
}



// Create standard classes
// ------------------------------------------

@include tachyons_widths();



// Create MQ extensions
// ------------------------------------------

@each $bp, $mq in $bp-widths-min {
  @media (min-width: #{$mq}) {
    @include tachyons_widths(-#{$bp});
  }
}



// Special one off Tachyon - being used in c-nav
// Easiest way to keep c-nav 100% Tachyonic although a bit nasty
// ------------------------------------------

.w-100-important-lg_max {
  @media (max-width: bp-width-max(lg)) {
    width: 100% !important;
  }
}

