// Jump Link Offset - Used as an anchor target for
// standard links, where data attributes can’t be applied

.c-nav-jumplink-offset {
  position: absolute;
  pointer-events: none;

  top: -($c-nav-height * 1.5);

  @media (min-width: bp-width-min('md')) {
    top: -($c-nav-height-md * 1.5);
  }

  @media (min-width: bp-width-min('xl')) {
    top: -($c-nav-height-xl * 1.5);
  }
}

